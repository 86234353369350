// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../project/styles/widget-styles/content-alert-styles';
import Styles from '../../common/decorators/Styles';
import {
    Div, P, svgSprite, Alert
} from '../../hybrid/wrappers';

const ContentAlert = ({
    styles, showMessage, setShowAlert, message, type, icon, compStyles
}) => {
    if (!showMessage && setShowAlert) return null;

    return (
        <Alert setShowAlert={setShowAlert} type={type} styles={{ ...styles.marginBottom16, ...compStyles?.wrapper || {} }} compStyles={compStyles}>
            <Div styles={styles.textWrapWeb}>
                {
                    typeof message === 'string' ?
                        <P styles={compStyles?.message || {}}>
                            {message}
                            {
                                !!icon
                              && svgSprite(icon, { style: styles.alertErrorIcon })
                            }
                        </P> :
                        <Div styles={compStyles?.message || {}}>
                            {message}
                            {
                                !!icon
                              && svgSprite(icon, { style: styles.alertErrorIcon })
                            }
                        </Div>
                }

            </Div>
        </Alert>
    );
};

ContentAlert.propTypes = {
    styles: PropTypes.object,
    showMessage: PropTypes.bool,
    setShowAlert: PropTypes.func,
    message: PropTypes.node,
    type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
    icon: PropTypes.string
};

export default Styles(ContentAlert, getStyles);