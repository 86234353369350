import React, {
    useState, useCallback, useEffect
} from 'react'
import FormInput from '../form-input/FormInput';

const InputItem = ({
    value,
    setFormData,
    disabled,
    errors,
    attribute,
    option,
    index,
    idx,
    currency,
    setDataCurrency,
    styles
}) => {
    let [dis, setDis] = useState(disabled)
    useEffect(() => {
        if (index === 0 && idx === 0) {
            setDis(true)
        }
    }, [idx, index, setDataCurrency])
    const changeHandler = useCallback((v) => {
        if (!disabled) {
            const arr = value.filter(e => e.currency_id === currency.value)
            const newArr = value.filter(e => e.currency_id !== currency.value)
            const filterArr = arr.map((item, idx) => {
                return {
                    ...item,
                    [option.alias]: index === idx ? v : item[option.alias]
                }
            })
            setFormData(attribute, [...newArr, ...filterArr])
        }
    }, [disabled, value, setFormData, attribute, currency?.value, option?.alias, index]);
    const componentConfig = {
        type: 'text',
        setFormData: (attr, value) => { changeHandler(value) },
        value: disabled ? '' : value.filter(e => e.currency_id === currency.value)[index][option.alias],
        placeholder: '0',
        maxLength: 150,
        disabled: Boolean(dis),
        compStyle: {
            wrapper: styles.formProductPricesInputWrapper,
            error: { wrapper: { 'display': 'none' } }
        },
        errors
    }
    return (
        <FormInput
            {...componentConfig}
        />
    )
}
export default InputItem