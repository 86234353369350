import officeStyles from './office/datepicker-web-styles'
import mainStyles from './main/datepicker-web-styles'
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,
    dpHeader: {
        display: 'flex',
        'align-items': 'center',
        width: '100%'
    },
    dpHeader__date: {
        color: variable.darkColor,
        'font-family': 'Roboto',
        'font-style': 'normal',
        'font-weight': 500,
        ...uikit.text,
        'flex-shrink': 0
    },
    dpHeader__date_click: { cursor: 'pointer' },
    dpHeader__year: {
        display: 'flex',
        'align-items': 'center',
        margin: '0 5px',
        'flex-shrink': 0
    },
    dpHeader__yearSwith: {
        display: 'block',
        border: 0,
        outline: 'none !important',
        'background-color': 'transparent',
        width: 0,
        height: 0,
        'border-left': '4px solid transparent',
        'border-right': '4px solid transparent',
        'border-top': `4px solid ${variable.darkColor}`,
        cursor: 'pointer',
        transition: 'transform 0.5s',
        'margin-left': '5px'
    },
    dpHeader__yearSwith_open: { transform: 'rotateZ(180deg)' },
    dpHeader__yearData__item: {
        'background-color': 'transparent',
        'border-radius': '8px',
        border: 'none !important',
        outline: 'none !important',
        'font-family': 'Roboto',
        'font-style': 'normal',
        'font-weight': '400',
        ...uikit.small,
        padding: '6px 11px',
        cursor: 'pointer',
        transition: 'color 0.5s'
    },
    dpHeader__yearData__item_hover: { color: variable.blueColor },
    dpHeader__yearData__item_seleted: {
        'background-color': variable.blueColor,
        color: `${variable.whiteColor} !important`
    },
    dpHeader__control: {
        'flex-grow': 1,
        display: 'flex',
        'justify-content': 'flex-end',
        'align-items': 'center'
    }
});
export default stylesheets;