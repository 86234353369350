import React, {
    useEffect, useState, useContext
} from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { Div } from '../wrappers';
import ReactDOM from 'react-dom';
import { useWindowDimensions } from '../../common/utils/windowDimensions';
import {
    admin, main, office
} from '../../project/uikit/color-variables';
import { UserProfileContext } from '../../common/utils/getContext';

const TooltipElement = ({
    children,
    text,
    place,
    styles,
    className
}) => {
    const [id, setId] = useState(false);
    useEffect(() => {
        if (!id) {
            setId(`${Math.floor(Math.random() * 10000000)}tooltip`);
        }
    }, [id]);
    const mobile = useWindowDimensions() < 992;
    const { adminPage, officePage } = useContext(UserProfileContext) || {};

    const variables = adminPage ? admin : officePage ? office : main
    if (!id) return null;
    return (
        <Div styles={{
            ...styles?.tooltipWrap ? styles.tooltipWrap : {},
            position: 'relative', 'z-index': 1,
            ...styles?.tooltipWrapCustom ? styles.tooltipWrapCustom : {}
        }}>
            {
                id
                &&
                    <>
                        <Div
                            // data-tip={text}
                            data-for={id}
                            data-tip={id}
                            styles={styles}
                            data-text-color={variables.whiteColor}
                            data-class={`react-tooltip ${className}`}
                            data-place={place}
                            data-delay-hide={className && className.match(/react-tooltip-xl/gi) && mobile ? '100' : '0'}
                            data-multiline
                        >
                            {children}
                        </Div>
                        {
                            text ? className && className.match(/customPosition/gi) ?
                                <ReactTooltip clickable={true} id={id}>{text}</ReactTooltip>
                                :
                                ReactDOM.createPortal(<ReactTooltip clickable={true} id={id}>{text}</ReactTooltip>, document.body)
                                : null
                        }
                    </>

            }
        </Div>
    );
};

TooltipElement.propTypes = {
    text: PropTypes.node,
    place: PropTypes.string,
    styles: PropTypes.object,
    className: PropTypes.string
};

export default TooltipElement;