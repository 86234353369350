// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {
    Div, FieldError, P, svgSprite
} from '../../../../hybrid/wrappers';
import Title from '../Title';

const FormCheckboxList = ({
    attribute,
    options,
    disabled,
    title,
    handlers = {},
    compStyle,
    value,
    setFormData,
    styles,
    errors
}) => {
    const [val, setVal] = useState(value || false);

    const changeHandler = useCallback((i) => {
        if (!disabled) {
            setVal((pre) => {
                let newArr = pre.map((item) => {
                    if (i === item.id){
                        return {
                            ...item,
                            value: !item.value
                        };
                    }
                    return item;
                });
                if (handlers?.onChange) handlers?.onChange(newArr)

                return newArr
            });
        }
    }, [disabled, handlers]);

    useEffect(() => {
        setFormData(attribute, val);
    }, [attribute, setFormData, val]);

    return (
        <Div styles={{ ...styles.formCheckboxWrapper, ...compStyle?.wrapper }}>
            {
                !!title &&
                <Div styles={{ ...styles.formCheckboxlistTitle, ...compStyle?.listTitle }}>
                    <Title Comp={P} body={title.body} links={title.links} />
                </Div>
            }
            {
                val.map((option, i) => {
                    const optionTitle = options.find((item) => item.id === option.id) !== -1 ? options.find((item) => item.id === option.id).title : '';
                    return (
                        <Div key={`optionTitle_${i}`} styles={{ ...compStyle?.listItem }}>
                            <Div
                                data-uitest='583_uitest' onClick={() => { changeHandler(option.id) }}
                                styles={{
                                    ...styles.formCheckboxBlock, ...disabled ? styles.formCheckboxBlockDisabled : {}, ...compStyle?.label
                                }}
                            >
                                <Div
                                    styles={option.value
                                        ? { ...styles.formCheckbox, ...styles.formCheckboxActive }
                                        : { ...styles.formCheckbox }}
                                >
                                    {
                                        option.value
                                        && svgSprite('check', { fill: styles.variable.whiteColor, style: styles.formCheckboxSvg })

                                    }
                                </Div>
                                {
                                    !!optionTitle && <Title Comp={P} body={optionTitle} />
                                }
                            </Div>
                        </Div>
                    )
                })
            }
            {
                errors && errors.length ? errors.map((err, idx) => <FieldError styles={styles} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
        </Div>
    );
};

FormCheckboxList.propTypes = {
    attribute: PropTypes.string,
    options: PropTypes.array,
    disabled: PropTypes.bool,
    title: PropTypes.object,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.array,
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    errors: PropTypes.array
};

export default Styles(FormCheckboxList, getStyles);