// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import {
    svgSprite, Div, Slider, Img
} from '../../../hybrid/wrappers';
import { t } from '../../../common/components/T';
import OfficeConditionBlock from '../../../hybrid/library/OfficeConditionBlock';
import SpeedProgress from '../../../hybrid/library/SpeedProgressAdmin';
import LineProgress from '../../../hybrid/library/LineProgress';
import uikit from '../../../project/uikit/styles';
import { rankColors } from '../../../project/uikit/color-variables';


const ProgressBlock = ({
    styles,
    rankData,
    trans
}) => {
    return (
        <>
            <Div styles={styles.userModalRangBlock}>
                <Div styles={styles.userModalRangItem}>
                    <Div styles={styles.userModalRangIcon}>
                        {rankData.current_rank && rankData.current_rank?.preview?.links?.preview ?
                            <Img
                                styles={styles.userModalRangImg}
                                src={rankData.current_rank && rankData.current_rank?.preview?.links?.preview}
                            />
                            :
                            rankData?.current_rank?.sort ?
                            <Img src={`/src/img/office/rank/rankIcon_${rankData?.current_rank?.sort || 0}.svg`} source={`rankIcon${rankData?.current_rank?.sort || 0}`}/>
                                :
                            <Img src={`/src/img/office/rank/rankIcon_0.svg`} source={`rankIcon0`}/>
                        }
                    </Div>
                    <Div styles={styles.userModalRangValue}>
                        {rankData.current_rank ? rankData.current_rank.name : t(
                            'user_modal_NoQua', 'Без квал.', 'widget', trans, 'userModal'
                        )}
                    </Div>
                </Div>
                {
                    rankData.next_rank?.name !== rankData.current_rank?.name ?
                        <>
                            <Div styles={styles.userModalRangArrow}>
                                {
                                    svgSprite('angle-right', { style: styles.userModalCloseAngleRight })
                                }
                            </Div>
                            <Div styles={styles.userModalRangItem}>
                                <Div styles={styles.userModalRangIcon}>
                                    {rankData.next_rank && rankData.next_rank?.preview?.links?.preview ?
                                        <Img
                                            styles={styles.userModalRangImg}
                                            src={rankData.next_rank && rankData.next_rank?.preview?.links?.preview}
                                        />
                                        :
                                        rankData?.next_rank?.sort ?
                                            <Img src={`/src/img/office/rank/rankIcon_${rankData?.next_rank?.sort || 0}.svg`} source={`rankIcon${rankData?.next_rank?.sort || 0}`}/>
                                            :
                                            <Img src={`/src/img/office/rank/rankIcon_0.svg`} source={`rankIcon0`}/>
                                    }
                                </Div>
                                <Div styles={styles.userModalRangValue}>
                                    {rankData.next_rank ? rankData.next_rank.name : t(
                                        'user_modal_NoQua', 'Без квал.', 'widget', trans, 'userModal'
                                    )}
                                </Div>
                            </Div>
                        </>
                        : null
                }

            </Div>
            <Div styles={styles.wrapSpeedProgressSlider}>
                {
                    rankData?.conditions?.volumes &&
                    <Slider props={{
                        dots: true,
                        arrows: false,
                        slidesToShow: rankData.conditions.volumes.length < 2 ? rankData.conditions.volumes.length : 2,
                        slidesToScroll: 2,
                        responsive: [
                            {
                                breakpoint: 575,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                    }}>
                        {
                            rankData.conditions.volumes.map((quantityPVitem, idx) =>
                                <SpeedProgress
                                    key={`quantityPVitem${idx}`}
                                    target={quantityPVitem.target}
                                    formatted_target={quantityPVitem.formatted_target}
                                    hasPV={quantityPVitem.accumulated}
                                    title={quantityPVitem.symbol}
                                    formatted_accumulated={quantityPVitem.formatted_accumulated}
                                    formatted_left_to_accumulate={quantityPVitem.formatted_left_to_accumulate}
                                    styles={styles}
                                />)
                        }
                    </Slider>

                }
            </Div>
            <Div>
                {
                    !!rankData.conditions?.next_rank_package &&
                        <OfficeConditionBlock
                            data={{
                                status: rankData?.conditions?.next_rank_package?.has_package,
                                text: ` ${t(
                                    'user_modal_PackageAvailability', 'Наличие пакета', 'widget', trans, 'userModal'
                                )} «${rankData?.conditions?.next_rank_package?.package.name}» ${t(
                                    'user_modal_orHigher', 'или выше', 'widget', trans, 'userModal'
                                )}`
                            }}
                        />
                }
                {rankData && rankData.conditions && rankData.conditions.branches_with_rank ?
                    <Div styles={uikit.mt3}>
                        <LineProgress
                            data={{
                                have: rankData.conditions.branches_with_rank.target_rank,
                                partners: {
                                    set: rankData?.conditions?.branches_with_rank?.number,
                                    max: rankData?.conditions?.branches_with_rank?.target_number
                                }
                            }}
                            styles={styles}
                        />
                    </Div>
                    : null}
            </Div>
        </>
    )
};
ProgressBlock.propTypes = {
    styles: PropTypes.object,
    nowStatus: PropTypes.string,
    nextStatus: PropTypes.string,
    partners: PropTypes.object
};
export default ProgressBlock;