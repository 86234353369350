import React from 'react'
import { Div } from '../../hybrid/wrappers'
import { t } from '../../common/components/T'

const CartFilterSearchItem = ({
    styles,
    item,
    addProduct,
    translation
}) => {
    return (
        <Div
            styles={styles.searchItem}
            data-uitest='527_uitest' onClick={() => addProduct(item.id)}
        >
            <Div styles={styles.searchItemTitle}>{item.name}</Div>
            <Div styles={styles.searchBody}>
                <Div styles={styles.searchArticle}>
                    <Div styles={styles.searchArticleTitle}>{t('storeOrder-CartFilterItem-VendorCode', 'Артикул', '/admin/store/order', translation)}:</Div>
                    <Div>{item.article}</Div>
                </Div>
                <Div styles={styles.searchPrices}>
                    <Div styles={styles.price}>{item?.main_price?.price_for_client_formatted}</Div>
                    <Div styles={styles.mark_price}>{item?.main_price?.marketing_price_for_client_formatted}</Div>
                </Div>
            </Div>
        </Div>
    )
}
export default CartFilterSearchItem