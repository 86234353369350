import React from 'react'
import { Div, svgSprite } from '../../../hybrid/wrappers'

const SocialWebButton = ({
    onClick, styles, type
}) => {
    return (
        <Div data-uitest='48_uitest' onClick={onClick} styles={type === 'next' ? { ...styles.buttonArrow, ...styles.buttonArrowNext } : { ...styles.buttonArrow, ...styles.buttonArrowPrev }}>
            <Div styles={{ display: 'flex' }}>
                {svgSprite(type === 'next' ? 'angle-right' : 'angle-left', {
                    width: '8px',
                    height: '14px',
                    fill: styles.variable.greySuperDarkColor
                })}
            </Div>
        </Div>
    )
}
export default SocialWebButton