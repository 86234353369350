import { components } from 'react-select';

const getMultiValueContainer = (multiple, val, selectedLimit) => {
    const MultiValueContainer = (props) => {
        if (multiple && val?.length > selectedLimit && (props.data.id !== val?.[0]?.id && props.data.id && val?.[0]?.id || props.data.value !== val?.[0]?.value && props.data.value && val?.[0]?.value)) {
            return null
        }
        return <components.MultiValueContainer {...props} />;
    };
    return MultiValueContainer
}
export default getMultiValueContainer;