import React, { useMemo } from 'react';
import { P, Div } from '../../../../hybrid/wrappers';

const Page = ({
    metaCurrentPage, pagination, paginationUrlBlank, metaLastPage, styles
}) => {
    const pagesArr = useMemo(() => [...Array(metaLastPage).keys()].map((i) => i + 1), [metaLastPage]); // создаем массив со всеми номерами страниц для пагинатора

    return pagesArr.map((pageData, pageIndex) => {
        if (
            pagesArr.length > 8
                && (
                    metaCurrentPage > 4 && pageIndex === metaCurrentPage - 3 && metaCurrentPage <= pagesArr.length - 4
                    || metaCurrentPage > 4 && metaCurrentPage <= pagesArr.length - 4 && pageIndex === metaCurrentPage + 1
                    || metaCurrentPage <= 4 && pageIndex === 5
                    || metaCurrentPage > pagesArr.length - 4 && pageIndex === pagesArr.length - 5
                    || metaCurrentPage === pagesArr.length - 3 && pageData + 2 === metaCurrentPage
                ) && pageData + 1 !== metaCurrentPage
        ) {
            return (
                <Div
                    key={`${pageIndex}Block`}
                    styles={styles.paginationNumberBlock}
                >
                    <P
                        styles={styles.paginationNumber}
                    >
                      ...
                    </P>
                </Div>
            );
        }

        if (
            pagesArr.length <= 8
                || pageIndex <= 3 && metaCurrentPage >= pagesArr.length - 3
                || pageIndex >= pagesArr.length - 4 && metaCurrentPage >= pagesArr.length - 3
                || pageIndex >= pagesArr.length - 2
                || pageIndex <= 1
                || metaCurrentPage <= 4 && pageIndex <= 4
                || pageIndex >= pagesArr.length - 4 && metaCurrentPage <= 4
                || metaCurrentPage > 4 && metaCurrentPage <= pagesArr.length - 2 && pageIndex >= metaCurrentPage - 2 && pageIndex <= metaCurrentPage
                || pageData + 1 === metaCurrentPage
        ) {
            return (
                <Div
                    key={`${pageIndex}Block`}
                    styles={pageData === metaCurrentPage ? { ...styles.paginationNumberBlock, ...styles.paginationNumberBlockActive } : styles.paginationNumberBlock}
                    effects={{
                        hover: { ...styles.paginationNumberBlockActive, ...styles.formCursorPointer },
                        focus: { ...styles.paginationNumberBlockActive, ...styles.formCursorPointer }
                    }}
                    data-uitest='614_uitest' onClick={() => pageData !== metaCurrentPage && pagination(`${paginationUrlBlank}${pageData}`)}
                >
                    <P
                        styles={pageData === metaCurrentPage ? { ...styles.paginationNumber, ...styles.paginationNumberActive } : styles.paginationNumber}
                        effects={{
                            hover: { ...styles.paginationNumberActive, ...styles.formCursorPointer },
                            focus: { ...styles.paginationNumberActive, ...styles.formCursorPointer }
                        }}
                        key={pageIndex}
                    >
                        {pageData}
                    </P>
                </Div>
            );
        }
    });
};

export default Page;