// @generated: @expo/next-adapter@2.1.5
import React, {
    useContext,
    useEffect,
    useState,
    useMemo
} from 'react';
import { LangContext } from '../decorators/Language';
import {
    Div,
    P,
    Portal,
    svgSprite
} from '../../hybrid/wrappers';
import PropTypes from 'prop-types';
import { useWindowDimensions } from '../utils/windowDimensions';
import { admin } from '../../project/uikit/color-variables';
import FormInput from '../../widgets/form/components/form-input/FormInput';
import uikit from '../../project/uikit/styles';

const icons = require('../../scripts/configs/icons');

export const t = (
    textName, defaultStr, page = 'all', translation, group = 'common'
) => {
    if (!translation) return '';

    if (translation && `${translation[textName]}` === 'undefined') {
        // console.warn(`Не знайдений переклад ${textName} на сторінці ${page}, група - ${group}`);
    }
    return translation && translation[textName] || defaultStr;
};
const T = ({
    textName,
    defaultStr,
    vars = {},
    page = 'all',
    group = 'common',
    customTranslates = false,
    customLoading
}) => {
    const { translation, loading } = useContext(LangContext) || {};
    if (loading || customLoading) return null;
    const text = customTranslates
        ? customTranslates[textName] || defaultStr
        : translation && translation[textName] || defaultStr;
    if (
        customTranslates && `${customTranslates[textName]}` === 'undefined' ||
        !customTranslates && translation && `${translation[textName]}` === 'undefined'
    ) {
        // console.warn(`Не знайдений переклад ${textName} на сторінці ${page}, група - ${group}`);
    }
    return (
        <span key={`${textName}main`}>
            {text.split('${').reduce((arr, part, idx) => {
                if (idx === 0) {
                    arr.push(<span key={`${textName}${idx}`}>{part}</span>);
                    return arr;
                }

                const splitPart = part.split('}');
                const text = splitPart.slice(1).join('}');
                arr.push(<span key={`${textName}var${idx}`}>{vars[splitPart[0]]}</span>);
                arr.push(<span key={`${textName}text${idx}`}>{text}</span>);
                return arr;
            }, [])}
        </span>
    );
};

T.propTypes = {
    textName: PropTypes.string,
    defaultStr: PropTypes.string,
    vars: PropTypes.object,
    page: PropTypes.string,
    group: PropTypes.string,
    customTranslates: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    customLoading: PropTypes.bool
};

export default T;

export const Uploader = () => {
    const [visible, setVisible] = useState(false);
    const [showIcons, setShowIcons] = useState(false);

    const [filterIcons, setFilterIcons] = useState(icons || {});
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        setVisible(true);
    }, []);

    useEffect(() => {
        if (!icons) return;
        const filteredIcons = Object.keys(icons).reduce((acc, curr) => {
            const checkName = (name) => name?.toLowerCase().includes(searchQuery.toLowerCase());
            const filtered = icons[curr].filter((icon) => checkName(icon?.name) || icon?.tags?.filter(checkName)?.length);
            if (filtered.length) {
                acc[curr] = filtered;
            }
            return acc;
        }, {});
        setFilterIcons(filteredIcons);
    }, [searchQuery]);

    const isMobile = useWindowDimensions() < 768;
    const stylesBtn = {
        background: 'white',
        padding: '5px',
        border: '1px solid #d3d3d3',
        'font-size': '0.625rem'
    };

    const stylesIcons = isMobile
        ? {
            width: '100%',
            'max-height': '100vh',
            position: 'relative',
            background: admin.whiteColor,
            'border-radius': '20px'
        }
        : {
            width: '650px',
            height: '680px',
            'max-height': '100vh',
            position: 'relative',
            background: admin.whiteColor,
            'border-radius': '20px',
            padding: '20px',
            'overflow-y': 'scroll'
        };

    const sortedIcons = useMemo(() => {
        return [
            ...Object.keys(filterIcons)?.filter((key) => !filterIcons[key]?.filter((icon) => icon?.size === 'large')?.length),
            ...Object.keys(filterIcons)?.filter((key) => filterIcons[key]?.filter((icon) => icon?.size === 'large')?.length)
        ];
    }, [filterIcons]);
    const countIcons = useMemo(() => {
        return Object.keys(icons)
            ?.map((key) => icons[key])
            .reduce((acc, curr) => [...acc, ...curr], [])?.length;
    }, []);
    if (!visible) return null;
    return (
        <Div
            id='translatesUploaderButtons'
            styles={{
                ...process.env.NODE_ENV !== 'development'
                    ? { display: 'none' }
                    : {},
                position: 'fixed',
                right: 0,
                bottom: 0,
                'z-index': '99999',
                opacity: '.4'
            }}
            effects={{ hover: { opacity: '1' } }}
        >
            {showIcons && filterIcons &&
                <Portal
                    closeHandler={() => {
                        setShowIcons(false);
                        setSearchQuery('');
                    }}
                >
                    <Div styles={stylesIcons}>
                        <FormInput
                            title={{ body: `Всего иконок: ${countIcons}` }}
                            compStyle={{ wrapper: uikit.mb0 }}
                            placeholder='Поиск...'
                            setFormData={(attr, val) => {
                                setSearchQuery(val);
                            }}
                            type='search'
                        />
                        {sortedIcons?.map((group) => {
                            return (
                                <Div key={`iconsgroup_${group}`}>
                                    <P
                                        styles={{
                                            ...uikit.h6,
                                            ...uikit.mb3,
                                            ...uikit.mt4
                                        }}
                                    >
                                        {group}
                                    </P>
                                    <Div
                                        styles={{
                                            display: 'grid',
                                            gap: '16px 6px',
                                            'grid-template-columns': 'repeat(6, 1fr)'
                                        }}
                                    >
                                        {filterIcons[group].map((icon) =>
                                            <Div
                                                key={`icons_${icon?.name}`}
                                                className={`icons_modal_${icon?.size}`}
                                                styles={{
                                                    ...icon?.size === 'large'
                                                        ? { 'grid-column': 'span 2' }
                                                        : {},
                                                    display: 'flex',
                                                    'align-items': 'center',
                                                    'flex-direction': 'column',
                                                    'justify-content': 'flex-start'
                                                }}
                                            >
                                                {svgSprite(icon?.name,
                                                    {
                                                        width: icon?.size === 'large'
                                                            ? '130px'
                                                            : '30px',
                                                        height: icon?.size === 'large'
                                                            ? '130px'
                                                            : '30px',
                                                        fill: '#000'
                                                    },
                                                    'icons',
                                                    ['394', '109'])}
                                                <P
                                                    styles={{
                                                        ...uikit.tiny,
                                                        ...uikit.textCenter,
                                                        ...uikit.mt2
                                                    }}
                                                >
                                                    {icon?.name}
                                                </P>
                                            </Div>)}
                                    </Div>
                                </Div>
                            );
                        })}
                    </Div>
                </Portal>
            }
            <Div
                styles={{
                    display: 'flex',
                    'justify-content': 'flex-end'
                }}
            >
                <P styles={{ ...stylesBtn, cursor: 'pointer' }} onClick={() => setShowIcons(true)}>
                    icons
                </P>
            </Div>
        </Div>
    );
};