const uikit = {

    // margins
    mt0: { 'margin-top': '0' },
    mt1: { 'margin-top': '0.1875rem' },
    mt2: { 'margin-top': '0.5rem' },
    mt3: { 'margin-top': '1rem' },
    mt4: { 'margin-top': '1.5rem' },
    mt5: { 'margin-top': '2rem' },

    ml0: { 'margin-left': '0' },
    ml1: { 'margin-left': '0.1875rem' },
    ml2: { 'margin-left': '0.5rem' },
    ml3: { 'margin-left': '1rem' },
    ml4: { 'margin-left': '1.5rem' },
    ml5: { 'margin-left': '2rem' },

    mr0: { 'margin-right': '0' },
    mr1: { 'margin-right': '0.1875rem' },
    mr2: { 'margin-right': '0.5rem' },
    mr3: { 'margin-right': '1rem' },
    mr4: { 'margin-right': '1.5rem' },
    mr5: { 'margin-right': '2rem' },

    ml1_minus: { 'margin-left': '-0.1875rem' },
    ml2_minus: { 'margin-left': '-0.5rem' },
    ml3_minus: { 'margin-left': '-1rem' },
    ml4_minus: { 'margin-left': '-1.5rem' },
    ml5_minus: { 'margin-left': '-2rem' },

    mr1_minus: { 'margin-right': '-0.1875rem' },
    mr2_minus: { 'margin-right': '-0.5rem' },
    mr3_minus: { 'margin-right': '-1rem' },
    mr4_minus: { 'margin-right': '-1.5rem' },
    mr5_minus: { 'margin-right': '-2rem' },

    mb0: { 'margin-bottom': '0' },
    mb1: { 'margin-bottom': '0.1875rem' },
    mb2: { 'margin-bottom': '0.5rem' },
    mb3: { 'margin-bottom': '1rem' },
    mb4: { 'margin-bottom': '1.5rem' },
    mb5: { 'margin-bottom': '2rem' },

    // paddings
    pt0: { 'padding-top': '0' },
    pt1: { 'padding-top': '0.1875rem' },
    pt2: { 'padding-top': '0.5rem' },
    pt3: { 'padding-top': '1rem' },
    pt4: { 'padding-top': '1.5rem' },
    pt5: { 'padding-top': '2rem' },

    pl0: { 'padding-left': '0' },
    pl1: { 'padding-left': '0.1875rem' },
    pl2: { 'padding-left': '0.5rem' },
    pl3: { 'padding-left': '1rem' },
    pl4: { 'padding-left': '1.5rem' },
    pl5: { 'padding-left': '2rem' },

    pr0: { 'padding-right': '0' },
    pr1: { 'padding-right': '0.1875rem' },
    pr2: { 'padding-right': '0.5rem' },
    pr3: { 'padding-right': '1rem' },
    pr4: { 'padding-right': '1.5rem' },
    pr5: { 'padding-right': '2rem' },

    pb0: { 'padding-bottom': '0' },
    pb1: { 'padding-bottom': '0.1875rem' },
    pb2: { 'padding-bottom': '0.5rem' },
    pb3: { 'padding-bottom': '1rem' },
    pb4: { 'padding-bottom': '1.5rem' },
    pb5: { 'padding-bottom': '2rem' },

    //buttons
    button: {
        'text-align': 'center',
        cursor: 'pointer',
        'border-radius': '0.875rem',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'font-size': '1.125rem',
        'line-height': '1.3334',
        'font-weight': '500',
        'padding': '1.25rem'
    },
    buttonSm: {
        'text-align': 'center',
        cursor: 'pointer',
        'border-radius': '0.875rem',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'font-weight': '500',
        'font-size': '1rem',
        'padding': '0.75rem',
        'line-height': '1.5'
    },
    // styles
    width100: { 'width': '100%' },
    positionAbsolute: { position: 'absolute' },
    positionRelative: { position: 'relative' },
    pointer: { cursor: 'pointer' },

    none: { display: 'none' },
    block: { display: 'block' },
    flexRow: {
        display: 'flex',
        'flex-direction': 'row'
    },
    flexColumnReverse: {
        display: 'flex',
        'flex-direction': 'column-reverse'
    },
    flexRowReverse: {
        display: 'flex',
        'flex-direction': 'row-reverse'
    },

    flexInline: { display: 'inline-flex' },

    flexGrow1: { 'flex-grow': '1' },
    flexColumn: {
        display: 'flex',
        'flex-direction': 'column'
    },
    alignItemsCenter: { 'align-items': 'center' },
    alignItemsStart: { 'align-items': 'flex-start' },
    alignItemsEnd: { 'align-items': 'flex-end' },
    alignItemsUnset: { 'align-items': 'unset' },
    justifyContentStart: { 'justify-content': 'flex-start' },
    justifyContentCenter: { 'justify-content': 'center' },
    justifyContentEnd: { 'justify-content': 'flex-end' },
    justifyContentBetween: { 'justify-content': 'space-between' },
    flexWrap: { 'flex-wrap': 'wrap' },
    flexNoWrap: { 'flex-wrap': 'no-wrap' },
    noWrap: {
        'max-width': '100%',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap'
    },
    hr: {
        opacity: '0.5',
        'border-bottom-width': '1px',
        'border-style': 'solid',
        display: 'flex',
        height: '1px',
        'margin-top': '40px',
        'margin-bottom': '40px'
    },
    textCenter: { 'text-align': 'center' },

    medium: { 'font-weight': '500' },
    regular: { 'font-weight': '400' },

    input: { 'font-size': '1rem' },
    h1: {
        // 82px
        'font-size': '5.125rem',
        'line-height': '1.17',
        'font-weight': '400'
    },
    h2: {
        // 60px
        'font-size': '3.75rem',
        'line-height': '1.16',
        'font-weight': '400'
    },
    h3: {
        // 42px
        'font-size': '2.625rem',
        'line-height': '1.19',
        'font-weight': '500'
    },
    h4: {
        // 36px
        'font-size': '2.25rem',
        'line-height': '1.2',
        'font-weight': '500'
    },
    h5: {
        // 28px
        'font-size': '1.75rem',
        'line-height': '1.28'
    },
    h6: {
        // 24px
        'font-size': '1.5rem',
        'line-height': '1.3',
        'font-weight': '400'
    },
    title: {
        // 20px
        'font-size': '1.25rem',
        'line-height': '1.3'
    },
    subtitle: {
        // 18px
        'font-size': '1.125rem',
        'line-height': '1.44'
    },
    text: {
        // 16px
        'font-size': '1rem',
        'line-height': '1.5',
        'font-weight': '400'
    },
    small: {
        // 14px
        'font-size': '0.875rem',
        'line-height': '1.4'
    },
    tiny: {
        // 12px
        'font-size': '0.75rem',
        'line-height': '1.33'
    }
};
export default uikit;