import { Div } from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/plug-styles';
import Styles from '../../common/decorators/Styles';
import {
    useContext, useEffect, useMemo, useState
} from 'react';

import LazyLoad from 'react-lazy-load';
import { getMemoizedData } from '../../common/utils/memo';
import UserContext, { UserProfileContext } from '../../common/utils/getContext';
import uikit from '../../project/uikit/styles';
import { useRouter } from 'next/router';
import { getData } from '../../common/utils/router';
import RenderElemets from './components/skeleton/RenderElemets';
import { useWindowDimensions } from '../../common/utils/windowDimensions';
import { getBreakpoint } from './components/skeleton/patterns';
import PropTypes from 'prop-types';

const SkeletonComponent = ({
    styles,
    Component,
    componentProps,
    lazy = false,
    url,
    pattern,
    patternSettings,
    elements,
    wrapperStyles,
    handler,
    itemsShiners,
    wait,
    darkMode
}) => {
    const breakpoint = getBreakpoint(useWindowDimensions())
    const elementsData = useMemo(() => pattern ? pattern(styles?.variable, breakpoint, patternSettings) : elements || [], [patternSettings, breakpoint, elements, pattern, styles?.variable])
    const [isVisible, setIsVisible] = useState(!lazy);
    const [data, setData] = useState(!url && !handler);
    const context = useContext(UserProfileContext);
    const loaded = context ? context.loaded || context.mainPage : true;
    const router = useRouter()

    const {
        userToken, lang, localization
    } = useContext(UserContext);
    useEffect(() => {
        (async () => {
            if (!router.isFallback && isVisible && loaded){
                if (handler){
                    const dataRes = await handler()
                    setData(dataRes || {})
                } else if (url) {
                    if (context?.adminPage){
                        const dataRes = await getData(
                            url, userToken, lang, false, localization?.currency
                        )
                        setData(dataRes || {})
                    } else {
                        const dataRes = await getMemoizedData(url, userToken, lang, localization?.currency)
                        setData(dataRes || {})
                    }
                }
            }
        })()
    }, [router.isFallback, isVisible, lang, url, userToken, loaded, context?.adminPage, handler, localization?.currency]);
    if (wait || wait === undefined && !router.isFallback && isVisible && data && loaded){
        return <Component response={data} {...componentProps}/>
    }
    return (
        <Div styles={{
            ...uikit.positionRelative, overflow: 'hidden', ...wrapperStyles
        }}>
            {lazy && !data ? <LazyLoad offset={500} onContentVisible={() => { setIsVisible(true) }}><></></LazyLoad> : null}
            <RenderElemets darkMode={darkMode} styles={styles} itemsShiners={itemsShiners} elements={elementsData}/>
            {itemsShiners ? null : <Div styles={{ ...styles.shiner, ...darkMode ? styles.shiner_dark : {} }} className='shine'/>}
        </Div>
    )
}
export const SkeletonWrapper = (props) => {
    const Component = () => <>{props?.children}</>
    return <SkeletonComponent {...props} Component={Component}/>
}
export const Skeleton = Styles(SkeletonWrapper, getStyles)

SkeletonComponent.propTypes = {
    styles: PropTypes.object,
    Component: PropTypes.element,
    componentProps: PropTypes.object,
    lazy: PropTypes.bool,
    url: PropTypes.string,
    pattern: PropTypes.func,
    patternSettings: PropTypes.object,
    elements: PropTypes.array,
    wrapperStyles: PropTypes.object,
    handler: PropTypes.func,
    itemsShiners: PropTypes.bool,
    wait: PropTypes.bool,
    darkMode: PropTypes.bool
};
export default Styles(SkeletonComponent, getStyles);