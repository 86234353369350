import mongoose from 'mongoose';

const connect = () => {
    try {
        return mongoose.connect(`mongodb://${process.env.NEXT_PUBLIC_DB_FRONT_LOGIN}:${process.env.NEXT_PUBLIC_DB_FRONT_PASSWORD}@${process.env.NEXT_PUBLIC_SERVER_HOST}/${process.env.NEXT_PUBLIC_DATABASE}`);
    } catch (error) {
        console.log(error)
        return {}
    }
};
export default connect;