// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useCallback, useMemo, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {
    Div, TextArea, FieldError, FieldInfo, Label, P
} from '../../../../hybrid/wrappers';
import { initFormHandlers } from '../../formUtils';
import FromInputTitle from '../FormFieldTitle';
import { getInputStyles } from '../form-input/helpers';
import {
    getDefaultVariation, getIsCoupleVariation, getPlaceholderColor
} from '../helpers';
import { UserProfileContext } from '../../../../common/utils/getContext';

const FormInputArea = ({
    attribute,
    hint,
    placeholder,
    required,
    disabled,
    title,
    handlers = {},
    compStyle,
    value,
    type,
    setFormData,
    styles,
    info,
    errors,
    rows,
    maxLength = 500,
    variation
}) => {
    const profileContext = useContext(UserProfileContext);

    const variationStyle = variation || getDefaultVariation(profileContext, title)
    const [focused, setFocused] = useState(false);

    const [val, setVal] = useState(value || '');
    const changeHandler = useCallback((v) => {
        if (!disabled) {
            const valCor = v.length > maxLength ? v.substr(0, maxLength-1) : v;
            setFormData(attribute, valCor);

            setVal(valCor);
        }
    }, [disabled, maxLength, setFormData, attribute]);

    useEffect(() => {
        setVal(value || '');
    }, [setVal, value, attribute]);

    const initHandlers = useMemo(() => initFormHandlers(
        handlers, attribute, val, type, errors, setFocused
    ),
    [handlers, attribute, val, type, errors]);

    const componentConfig = {
        ...initHandlers,
        onChange: (e) => changeHandler(e.target.value),
        required,
        value: val,
        placeholder: '',
        maxLength,
        rows: rows || 3,
        disabled: Boolean(disabled) || false,
        styles: getInputStyles(
            variationStyle, styles, compStyle, errors, focused, disabled
        )
    };
    return (
        <>
            <Div styles={{ ...styles.formGroup, ...compStyle?.wrapper || {} }}>
                {
                    !!title && !getIsCoupleVariation(variationStyle) ?
                        <FromInputTitle
                            id={`input_${attribute}`}
                            styles={styles}
                            errors={errors}
                            title={title}

                            compStyle={compStyle}
                            hint={hint}
                            required={required}
                            variationStyle={variationStyle}
                            focused={focused}
                        /> : null

                }

                <Label
                    HtmlFor={`input_${attribute}`}
                    styles={
                        {
                            ...styles[`formControl__${variationStyle}`],
                            ...compStyle?.input || {},
                            ...errors && errors.length
                                ? {
                                    ...styles[`formControlError__${variationStyle}`],
                                    ...compStyle?.inputError || {}
                                } : {
                                    ...focused
                                        ? {
                                            ...styles[`formControlFocus__${variationStyle}`],
                                            ...compStyle?.inputFocus || {}
                                        } : {},
                                    ...disabled
                                        ? {
                                            ...styles['formControlDisabled'],
                                            ...compStyle?.inputDisabled || {}
                                        } : {}
                                }
                        }
                    }>
                    <Div styles={styles.formControlContent}>
                        <Div
                            styles={styles.formControlInner}
                        >
                            {
                                !!title && getIsCoupleVariation(variationStyle) ?
                                    <FromInputTitle
                                        id={`input_${attribute}`}
                                        styles={styles}
                                        errors={errors}
                                        title={title}

                                        compStyle={compStyle}
                                        hint={hint}
                                        required={required}
                                        isFilled={Boolean(val)}
                                        variationStyle={variationStyle}
                                        focused={focused}
                                    /> : null

                            }
                            {<Div styles={{ ...styles.formControlContentPlaceholder, ...styles.formControlContentPlaceholderArea }}>
                                <TextArea {...componentConfig} >
                                    {val}
                                </TextArea>
                                {!!placeholder && !val && !focused ?
                                    <Div className='hideOnAutofill' styles={{ ...styles.formControlInnerPlaceholder, ...styles.formControlInnerPlaceholderArea }}>
                                        <Div styles={{
                                            ...getPlaceholderColor(
                                                variationStyle, styles, compStyle, errors, focused, disabled
                                            ), ...compStyle?.placeholder || {}
                                        }}>{placeholder}</Div>
                                        { !!required && !title && <P styles={{
                                            ...styles.formControlRequiredMark, ...compStyle?.requiredMark, ...!errors && focused ? { ...styles.formControlRequiredMarkFocus, ...compStyle?.requiredMarkFocus } : {}
                                        }}>*</P>}
                                    </Div>
                                    : null}
                            </Div>}
                        </Div>
                    </Div>

                </Label>

                {
                    !!info
                    && <FieldInfo styles={{ ...styles, ...compStyle?.info ? compStyle?.info : {} }}>{info}</FieldInfo>
                }

                {
                    errors && errors.length ? errors.map((err, idx) => <FieldError
                        styles={{ ...styles, ...compStyle?.error || {} }} key={`${attribute}${idx}`}>{err}</FieldError>) : null
                }
            </Div>
        </>

    );
};

FormInputArea.propTypes = {
    attribute: PropTypes.string,
    hint: PropTypes.node,
    placeholder: PropTypes.node,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.object,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    info: PropTypes.node,
    errors: PropTypes.array,
    rows: PropTypes.number,
    maxLength: PropTypes.number,
    variation: PropTypes.string
};

export default Styles(FormInputArea, getStyles);