// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../hybrid/wrappers';

const SpecialStatus = ({ styles, val }) => {
    return (
        <>
            {val.map((status, idx) =>
                <Div styles={{ ...styles.orderStatus, 'background-color': status.color }} key={`status${idx}`}>{status.name}</Div>)}
        </>
    )
};

SpecialStatus.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    styles: PropTypes.object,
    name: PropTypes.string,
    link: PropTypes.string,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.object)
    ])
};

export default SpecialStatus;