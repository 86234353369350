const styles = (width, height, variable = {}) => ({
    wrapper: { 'margin-bottom': '10px' },
    mainContainer: {
        display: 'flex',
        'margin-top': '10px'
    },
    title: { color: variable.darkColor },
    telegramContainer: {
        background: variable.blueColor,
        'border-color': 'transparent'
    },
    defaultContactContainer: {
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        'align-items': 'center',
        margin: '5px 10px 10px 0',
        // padding: '2px 4px',
        width: '40px',
        height: '40px',
        border: `1px solid ${variable.darkExtraTransparentColor}`,
        'box-sizing': 'border-box',
        'border-radius': '12px',
        background: variable.whiteColor,
        cursor: 'pointer'
    }
});
export default styles;