import { components } from 'react-select';
import FormCheckbox from '../../form-checkbox/FormCheckbox';

const getOption = (multiple, limit, styles) => {
    const Option = (props) => {
        if (multiple && limit !== 1){
            return <components.Option {...props}>
                <FormCheckbox compStyle={{ label: styles.formSelectOptionCheckboxLabel }} value={props?.isSelected ? '1' : '0'} title={{ body: props.children }}/>
            </components.Option>
        }
        return <components.Option {...props} />;
    };
    return Option
}
export default getOption