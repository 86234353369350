import React, {
    useState, useCallback, useMemo, useContext, useEffect
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { Div, svgSprite } from '../../../../hybrid/wrappers';
import SearchWindow from '../../../searchWindow/SearchWindow';
import UserInfoBlock from './UserInfoBlock';
import { API_SERVER_URL, getWidgetTranslates } from '../../../../common/utils/utils';

import UserContext, { UserProfileContext } from '../../../../common/utils/getContext';
import FromInput from '../form-input/FormInput';
import { useWindowDimensions } from '../../../../common/utils/windowDimensions';
import { t } from '../../../../common/components/T';
import { getData } from '../../../../common/utils/router';
import CircleLoader from '../../../plug/CircleLoader';
import uikit from '../../../../project/uikit/styles';
import { LangContext } from '../../../../common/decorators/Language';
import { getDefaultVariation } from '../helpers';

const Wrapper = (props) => {
    if (props?.helpButtons) return <Div {...props}>{props.children}</Div>;
    return <>{props.children}</>;
};

const AdminFormSponsorWidgetMulti = (props) => {
    const {
        attribute,
        handlers = {},
        compStyle,
        value,
        setFormData,
        styles,
        customData = {},
        variation,
        title,
        placeholder
    } = props;

    const {
        userToken, setAlertData, lang
    } = useContext(UserContext);
    const profileContext = useContext(UserProfileContext);

    const variationStyle = variation || getDefaultVariation(profileContext, title);

    const [selectedUsers, setSelectedUsers] = useState(value || []);
    const [showSponsor, setShowSponsor] = useState(false);
    const widthDimensions = useWindowDimensions();
    const screenWidth = useMemo(() => widthDimensions < 768, [widthDimensions]);
    const [transl, setTranslates] = useState(null);
    const getUserData = useCallback(async (data) => {
        setAlertData(false);
        // Перевірте, чи користувач вже існує в списку selectedUsers

        const isUserSelected = selectedUsers.some(user => user.id === data.id);
        if (isUserSelected) {
            setAlertData({ type: 'error', cont: t('getUserDataError', 'Такой пользователь уже ответсвенный за магазин', 'widget', transl) })
            return;
        }
        const updatedUsers = [...selectedUsers, data];
        setSelectedUsers(updatedUsers);
        setFormData(attribute, updatedUsers);
        setShowSponsor(false);
        if (handlers?.onBlur) {
            handlers.onBlur(attribute, updatedUsers);
        }
    },
    [selectedUsers, setAlertData, setFormData, attribute, handlers, transl]);


    useEffect(() => {
        getWidgetTranslates(lang, 'form', null, setTranslates);
    }, [lang]);

    const removeUser = (index) => {
        const updatedUsers = [...selectedUsers];
        updatedUsers.splice(index, 1);
        setSelectedUsers(updatedUsers);
        setFormData(attribute, updatedUsers);
    };

    return (
        <>
            <Div
                styles={{
                    ...styles.formGroup,
                    ...styles[`formSelectDataWrap__${variationStyle}`],
                    ...compStyle.wrapper
                }}
            >
                <Wrapper helpButtons={customData?.helpButtons} styles={styles.formSelectDataButtonsInputWrap}>
                    <FromInput
                        {...props}
                        placeholder={customData?.loading ? <CircleLoader /> : placeholder}
                        handlers={{
                            ...props.handlers || {},
                            onClick: () => setShowSponsor(true)
                        }}
                        noWrapper={true}
                        buttons={{
                            icon: { icon: customData?.icon || 'user' },
                            ...props.buttons || {}
                        }}
                        value={
                            selectedUsers.length > 0
                                ? selectedUsers.map((user) => user[customData.attrToInputName] || user.username).join(', ')
                                : ''
                        }
                        readonly={true}
                        compStyle={{
                            ...compStyle,
                            input: { ...compStyle?.input, ...customData?.helpButtons ? styles.formSelectDataInput : {} }
                        }}
                    />
                    {customData?.helpButtons &&
                        <Div styles={styles.formSelectDataButtonsWrap}>
                            {selectedUsers.map((user, index) =>
                                <Div key={index} styles={{ ...styles.formSelectDataHelpBtn, ...compStyle?.helpBtn || {} }}>
                                    {svgSprite('trash', {
                                        width: '24px',
                                        height: '24px',
                                        style: { ...styles.formSelectDataHelpBtnSvg, ...styles.formSelectDataHelpBtnSvgDanger },
                                        onClick: () => removeUser(index)
                                    })}
                                </Div>)}
                        </Div>
                    }
                </Wrapper>
                {selectedUsers.length > 0 && <Div styles={styles.multiSelectDataWidgetTable}>
                    <Div styles={styles.multiSelectDataWidgetTableTop}>
                        <Div>ID</Div>
                        <Div>{ t('Form-ChooseSponsor-fioTable', 'ФИО', 'widget', transl)}</Div>
                        <Div></Div>
                    </Div>
                    {selectedUsers.map((user, index) => {
                        return <Div styles={styles.multiSelectDataWidgetTableInner} key='index'>
                            <Div>{user.username}</Div>
                            <Div>{`${user?.first_name ? user?.first_name : ''} ${user?.last_name ? user?.last_name : ''}${!user?.first_name && !user?.last_name && user?.email ? user?.email : ''}`}</Div>
                            <Div styles={{ cursor: 'pointer', 'text-align': 'right' }} effects={{ hover: { opacity: '0.6' } }} onClick={() => removeUser(index)}>
                                { t('multiUsersTable-remove', 'Удалить', 'widget', transl)}
                            </Div>
                        </Div>
                    })}
                </Div> }
            </Div>

            {showSponsor &&
                <SearchWindow
                    input={customData?.modalInput || {}}
                    transformSearchData={customData?.transformSearchData || null}
                    thead={customData?.thead || null}
                    portalClass={!screenWidth ? 'modal' : 'modalBottom'}
                    closeModal={setShowSponsor}
                    sendData={{ url: customData?.url || `${API_SERVER_URL}/api/v1/admin/user-management/sponsors` }}
                    title={
                        customData?.modalTitle ||
                        t(
                            'Form-ChooseSponsor-v1', 'Выбрать ответственного за магазин', 'widget', transl, 'form'
                        )
                    }
                    gridConfig={{ td: { styles: { ...uikit.pt0, ...uikit.pb0 } } }}
                    customData={{ ...customData, buttonNotShow: true }}
                    gridHandlers={{
                        tr: {
                            onClick: (rowData) => {
                                getUserData(rowData);
                            }
                        }
                    }}
                    fixedWidth={!screenWidth ? { width: '1184px' } : {}}
                    customStyles={compStyle}
                />
            }
        </>
    );
};

AdminFormSponsorWidgetMulti.propTypes = {
    attribute: PropTypes.string,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.array,
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    customData: PropTypes.object,
    variation: PropTypes.string,
    title: PropTypes.object,
    placeholder: PropTypes.node
};

export default Styles(AdminFormSponsorWidgetMulti, getStyles);