import { media } from '../../../common/utils/utils';
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    //General
    flexRow: {
        'display': 'flex',
        'align-items': 'center'
    },
    cartGrid: media(width, height, 'w', {
        1366: {},
        0: {
            width: '100%',
            'overflow-x': 'auto'
        }
    }),
    cartWrapper: media(width, height, 'w', {
        1700: {
            width: '100%'
            // 'justify-content': 'space-between'
        },
        768: {
            // display: 'flex',
            // 'align-items': 'center',
            width: '100%'
            // 'justify-content': 'space-between'
        },
        0: { width: '100%' }
    }),
    cartWrapperHeader: media(width, height, 'w', {
        1366: {},
        768: { width: 'fit-content' },
        576: {},
        0: { width: 'fit-content' }
    }),
    cartWrapperItem: media(width, height, 'w', {
        1700: {
            display: 'flex',
            'align-items': 'center',
            width: '100%',
            'justify-content': 'space-between',
            padding: '12px',
            'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`
        },
        768: {
            display: 'flex',
            'align-items': 'center',
            width: '100%',
            'justify-content': 'space-between',
            padding: '12px',
            'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            width: '100%',
            //'justify-content': 'space-between',
            padding: '12px 0',
            'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`,
            'flex-wrap': 'no-wrap'
        }
    }),
    cartWrapperLeft: {
        display: 'flex',
        'align-items': 'center'
    },
    cartWrapperRight: media(width, height, 'w', {
        600: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-between',
            //...uikit.flexWrap,
            width: '100%'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            'flex-wrap': 'wrap'
        }
    }),
    cartWrapperRightHeader: media(width, height, 'w', {
        768: {},
        0: {
            display: 'flex',
            'align-items': 'center',
            'flex-wrap': 'no-wrap'
        }
    }),
    cartItemUnavailable: {
        background: variable.redLightTransparentColor,
        'border-radius': '6px',
        padding: '2px 8px',
        display: 'flex',
        'align-items': 'center',
        width: 'fit-content',
        ...uikit.flexRow,
        ...uikit.flexWrap,
        ...uikit.alignItems,
        color: variable.redColor,
        'margin-bottom': '8px',
        'font-size': '10px',
        'text-transform': 'uppercase'
    },
    cartItemArticle: {
        background: variable.greyExtraDarkColorTransparent,
        'border-radius': '6px',
        padding: '4px 10px',
        display: 'flex',
        'align-items': 'center',
        width: 'fit-content',
        'margin-top': '8px',
        ...uikit.flexRow,
        ...uikit.flexWrap,
        ...uikit.alignItems
    },
    cartItemPreOrder: {
        width: 'fit-content',
        display: 'flex',
        'justify-content': 'center',
        padding: '4px',
        'border-radius': '4px',
        background: 'rgba(107, 145, 123, 1)',
        color: '#ffffff',
        'margin-top': '6px'
    },
    cartItemArticleTitle: {
        ...uikit.tiny,
        'padding-right': '6px',
        color: variable.greySuperDarkColor,
        'white-space': 'nowrap'
    },
    cartItemArticleTitleOffice: {
        ...uikit.tiny,
        'padding-right': '6px',
        color: variable.greySuperDarkColor
    },
    cartItemImage: {
        width: '52px',
        height: '52px',
        'border-radius': '8px',
        overflow: 'hidden',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'margin-right': '24px'
    },
    cartItemName: media(width, height, 'w', {
        768: {},
        0: {
            width: '100%',
            'margin-bottom': '6px'
            // order: 1
        }
    }),
    cartItemNameHeader: media(width, height, 'w', {
        768: {},
        0: {
            'max-width': '250px',
            'min-width': '250px'
        }
    }),
    cartItemDeleteIcon: {
        width: '16px',
        height: '16px',
        cursor: 'pointer'
    },
    cartItemDeleteIconWrap: media(width, height, 'w', {
        600: {
            fill: variable.greyExtraDarkColor,
            'margin': '0 4px 0 16px'//'0 4px auto 16px',
        },
        0: {
            fill: variable.greyExtraDarkColor,
            'margin': '0 4px 0 auto'
        }
    }),
    cartItemDeleteIconHover: { fill: variable.blueColor },
    cartItemCounter: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            width: '100px',
            'min-width': '100px',
            // margin: '0 40px',
            order: 2
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            order: 3
        }
    }),
    cartItemCounterGrid: media(width, height, 'w', {
        768: {},
        0: {
            width: '100px',
            'min-width': '100px',
            display: 'flex',
            'justify-content': 'center'
        }
    }),
    cartItemCounterValue: {
        width: '25px',
        height: '25px',
        border: '2px solid transparent',
        'border-radius': '4px',
        'text-align': 'center'
    },
    cartItemCounterValueFocus: { 'border-color': variable.blueColor },
    cartItemPrice: media(width, height, 'w', {
        992: {
            'min-width': '150px',
            order: 3,
            'padding-left': '20px'
        },
        768: {
            // width: '100px',
            'min-width': '120px',
            order: 3,
            'padding-left': '20px'
            // 'text-align': 'center',
        },
        0: {
            // 'margin-left': '40px',
            'margin-right': '24px',
            width: '80px',
            order: 2
        }
    }),
    cartItemPriceGrid: media(width, height, 'w', {
        768: {},
        0: {
            width: '100px',
            'min-width': '100px',
            'margin-right': 0,
            'text-align': 'center',
            // 'margin-left': '16px',
            'padding-left': '20px',
            order: 3
        }
    }),
    cartItemNumber: {
        color: variable.blueColor,
        'margin': '0 10px',
        'font-weight': 500
    },
    cartItemCounterCircle: {
        width: '24px',
        height: '24px',
        'border-radius': '50%',
        'background-color': variable.greyExtraDarkColorTransparent,
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        cursor: 'pointer'
    },
    cartItemCounterCircleHover: { 'background-color': variable.greyExtraDarkColorTransparent },
    imageGridItem: { height: '100%' },
    totalPrice: {
        'font-weight': 500,
        ...uikit.text,
        ...uikit.noWrap,
        'text-align': 'left'
    },
    totalPriceNowrap: { ...uikit.noWrap },
    totalPriceModern: {
        ...uikit.text,
        'white-space': 'pre',
        width: '100px',
        'min-width': '100px',
        'text-align': 'center',
        order: 2
        // margin: '0 40px'
    },
    totalPriceWidgetHover: media(width, height, 'w', {
        768: {
            'font-weight': 500,
            ...uikit.title,
            'margin-right': '8px',
            'white-space': 'pre'
        },
        0: {
            'font-weight': 500,
            ...uikit.title,
            'margin-right': '8px'
        }
    }),
    totalPriceMedium: media(width, height, 'w', {
        768: {
            ...uikit.noWrap,
            ...uikit.h6,
            'font-weight': 500,
            'margin-right': '8px',
            'white-space': 'pre'
        },
        0: {
            ...uikit.noWrap,
            ...uikit.h6,
            'font-weight': 500,
            'margin-right': '8px'
        }
    }),
    totalMarketingPrice: {
        'font-weight': 700,
        ...uikit.text,
        color: variable.blueColor,
        'background-color': variable.greyTransparentHalfColor,
        padding: '4px 6px',
        'border-radius': '4px',
        'white-space': 'nowrap',
        width: 'fit-content'
    },
    totalMarketingPriceWidget: {
        'font-weight': 700,
        ...uikit.small,
        color: variable.blueColor,
        'background-color': variable.greyTransparentHalfColor,
        padding: '4px 6px',
        'white-space': 'pre',
        margin: '0 8px'
    },
    cartItemSubtitle: {
        ...uikit.small,
        color: variable.greySuperDarkColor
    },
    cartItemSubtitleWidget: {
        ...uikit.text,
        color: variable.greySuperDarkColor,
        'margin-right': '6px',
        'white-space': 'pre'
    },
    cartItemSubtitleWidgetHover: {
        ...uikit.tiny,
        'font-size': 500,
        color: variable.greySuperDarkColor,
        'margin-right': '6px'
    },
    cartBodyWidgetHover: {
        'max-height': '60vh',
        'overflow-y': 'auto'
    },
    cartFooter: media(width, height, 'w', {
        768: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            padding: '16px 0'
        },
        0: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            padding: '16px 0',
            'flex-wrap': 'wrap'
        }
    }),
    cartFooterMobile: media(width, height, 'w', {
        768: { display: 'flex' },
        0: {
            display: 'flex',
            'flex-wrap': 'wrap',
            width: '100%'
        }
    }),
    buttonPrimary: media(width, height, 'w', {
        768: {
            ...uikit.button,
            'background-color': variable.orangeColor,
            'font-weight': 500,
            color: variable.whiteColor,
            'min-width': '205px',
            'margin-left': '24px',
            transition: variable.transition
        },
        0: {
            ...uikit.button,
            'background-color': variable.orangeColor,
            'font-weight': 500,
            color: variable.whiteColor,
            width: '100%',
            margin: '14px 0',
            transition: variable.transition

        }
    }),
    buttonPrimarySmall: media(width, height, 'w', {
        768: {
            ...uikit.button,
            ...uikit.text,
            'background-color': variable.orangeColor,
            'font-weight': 500,
            color: variable.whiteColor,
            'min-width': '174px',
            height: '48px',
            'line-height': '48px',
            'margin-left': '30px',
            transition: variable.transition,
            padding: '0 8px',
            'white-space': 'pre'
        },
        0: {
            ...uikit.button,
            ...uikit.text,
            'background-color': variable.orangeColor,
            'font-weight': 500,
            color: variable.whiteColor,
            width: '100%',
            margin: '14px 0',
            transition: variable.transition

        }
    }),
    formButtonPrimaryHover: { 'background-color': variable.orangeDarkColor },
    buttonSecondary: {
        display: 'flex',
        width: 'fit-content',
        'align-items': 'center',
        'justify-content': 'center',
        padding: '12px 24px',
        border: `2px solid ${variable.darkExtraTransparentColor}`,
        'border-radius': '14px',
        margin: '18px 0',
        cursor: 'pointer',
        'font-weight': 500,
        'background-color': variable.whiteColor
    },
    buttonSecondaryHover: { 'background-color': variable.greyExtraDarkColorTransparent },
    //Widget
    cartWrapperWidget: {
        // 'display': 'flex',
        // // 'align-items': 'center',
        // 'justify-content': 'space-between',
        // 'flex-grow': 1,
        padding: '24px',
        'background-color': variable.whiteColor,
        'border-radius': '24px'
    },
    cartWrapperWidgetInner: {
        'display': 'flex',
        // 'align-items': 'center',
        'justify-content': 'space-between',
        'flex-grow': 1,
        padding: '0 24px 0 0'
    },
    cartItemWidget: media(width, height, 'w', {
        1366: {
            'display': 'flex',
            'flex-wrap': 'wrap',
            // 'max-width': '50%',
            'overflow-x': 'auto'
        },
        0: { display: 'none' }
    }),
    cartItemWidgetImage: {
        width: '52px',
        height: '52px',
        'max-width': '52px',
        overflow: 'hidden',
        display: 'flex',
        'border-radius': '8px',
        'align-items': 'center',
        'justify-content': 'center',
        'margin-right': '24px'
    },
    cartItemWidgetImageInner: { height: '100%' },
    cartTitleWidget: {
        ...uikit.subtitle,
        'font-weight': 500
    },
    //HOVER WIDGETS
    cartTitleWidgetHover: {
        ...uikit.text,
        'font-weight': 500
    },
    cartFooterWidget: {
        display: 'flex',
        'justify-content': 'flex-end',
        'align-items': 'center',
        'padding-top': '16px'
    },
    cartHeaderWidgetHover: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center'
    },
    cartDelete: {
        color: variable.greySuperDarkColor,
        ...uikit.small,
        cursor: 'pointer'
    },
    cartItemTitle: {
        cursor: 'pointer',
        'word-break': 'break-word'
    },
    cartItemTitleOffice: {
        cursor: 'default',
        'word-break': 'break-word'
    },
    cartItemTitleHover: { color: variable.blueColor },
    cartWrapperHead: media(width, height, 'w', {
        768: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            padding: '20px 24px',
            background: variable.greyExtraDarkColorTransparent,
            'border-radius': '14px 14px 0px 0px',
            'margin-top': '16px'
        },
        0: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            padding: '12px',
            background: variable.greyExtraDarkColorTransparent,
            'border-radius': '14px 14px 0px 0px',
            'margin-top': '12px',
            width: 'fit-content'
        }
    }),
    cartWrapperHeadBlock: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'center',
            'padding-right': '28px'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            'padding-right': '28px'
        }
    }),
    cartWrapperHeadItem: {
        'text-transform': 'uppercase',
        'font-weight': 700,
        ...uikit.small,
        width: '100px',
        // margin: '0 40px',
        'text-align': 'center'
    },
    cartWrapperHeadItemFirst: media(width, height, 'w', {
        768: {},
        0: {
            'min-width': 'calc(250px + 76px - 12px)',
            'text-align': 'left'
        }
    }),
    cartWrapperHeadItemPrices: {
        width: '150px',
        'text-align': 'left',
        'padding-left': '20px'
    },
    packBg: media(width, height, 'w', {
        768: {
            position: 'relative',
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center',
            padding: '8px 10px',
            'border-radius': '14px',
            overflow: 'hidden',
            color: '#ffffff',
            'text-align': 'center',
            // 'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'background-position': 'top left, center'
        },
        0: {
            position: 'relative',
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center',
            padding: '4px 8px',
            'border-radius': '14px',
            overflow: 'hidden',
            color: '#ffffff',
            'text-align': 'center',
            // 'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'background-position': 'top left, center'
        }
    }),
    packNameLabel: {
        color: 'rgba(255, 255, 255, 0.75)',
        'font-size': '14px',
        'font-weight': 400,
        'line-height': '1'
    },
    packPriceLabel: media(width, height, 'w', {
        1280: {
            'font-size': '16px',
            'font-weight': 500,
            'line-height': '1.15'
        },
        768: {
            'font-size': '14px',
            'font-weight': 500,
            'line-height': '1.15'
        },
        0: { display: 'none' }
    }),
    progressBar: media(width, height, 'w', {
        0: {
            'flex-grow': 1,
            'min-width': '8px',
            height: '3px',
            'background-color': 'rgba(244, 243, 244, 1)',
            overflow: 'hidden'
        }
    }),
    packCartWrap: media(width, height, 'w', { 0: { display: 'flex' } }),
    packBlockWrap: media(width, height, 'w', {
        0: {
            padding: '0 24px 0 0',
            'background-color': variable.whiteColor
        }
    }),
    packProgressWrap: media(width, height, 'w', {
        768: { display: 'flex', 'margin-bottom': '24px' },
        0: { display: 'flex', 'margin-bottom': '16px' }
    }),
    package_1: { 'background': 'linear-gradient(90deg, #4C3C36 0%, #A9856F 100%)' },
    package_2: { 'background': 'linear-gradient(90deg, #353A43 0%, #8796B0 100%)' },
    package_3: { 'background': 'linear-gradient(90deg, #2D4439 0%, #5BA184 100%)' },
    package_4: { 'background': 'linear-gradient(90deg, #7A5931 0%, #E1A345 100%)' },
    packNameWrap: media(width, height, 'w', {
        1040: {
            position: 'relative',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            padding: '4px 10px',
            margin: '0 0 0 6px',
            'min-width': '126px',
            'text-align': 'center',
            'border-radius': '16px',
            color: variable.whiteColor,
            'font-size': '0.875rem',
            'line-height': '1.14',
            'font-weight': 'bold',
            'z-index': 2,
            'box-shadow': 'none'
        },
        0: {
            position: 'relative',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            padding: '4px 10px',
            margin: '0',
            'min-width': '126px',
            'text-align': 'center',
            'border-radius': '16px',
            color: variable.whiteColor,
            'font-size': '0.875rem',
            'line-height': '1.14',
            'font-weight': 'bold',
            'z-index': 2,
            'box-shadow': 'none'
        }
    }),
    packIconWrap: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'margin-right': '6px'
    },
    packWrapperBlock: {
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        'z-index': 5,
        'border-radius': '14px'
    },
    packageWrapperLow: {
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        'z-index': 5,
        'border-radius': '20px',
        background: 'rgba(255, 255, 255, 0.30)'
    },
    packageWrapperBlockIcon: media(width, height, 'w', {
        0: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'margin-bottom': '24px',
            width: '20px',
            height: '20px',
            padding: '2px',
            background: 'rgba(0, 0, 0, 0.30)',
            'border-radius': '50%'
        } ,
        767: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'margin-bottom': '24px',
            width: '56px',
            height: '56px',
            padding: '9px',
            background: 'rgba(0, 0, 0, 0.30)',
            'border-radius': '50%'
        }
    }),
    packageWrapperBlockIconSvg: media(width, height, 'w', {
        0: {
            width: '38px',
            height: '38px'
        } ,
        767: {
            width: '38px',
            height: '38px'
        }
    }),
    packWrap: {
        position: 'relative',
        display: 'flex',
        'align-items': 'center',
        'max-width': '100%'
    },
    packagePriceLabelOld: {
        position: 'absolute',
        top: '22px',
        right: '10px',
        ...uikit.tiny,
        'line-height': '1',
        color: variable.whiteColor,
        'text-decoration': 'line-through',
        'margin-left': '8px'
    },
    statPackSection: media(width, height, 'w', { 0: { position: 'relative' } }),
    statPackWrap: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-wrap': 'wrap',
            'row-gap': '8px',
            margin: '0 0 24px 0'
        },
        0: { margin: '0' }
    }),
    statPackItem: media(width, height, 'w', {
        1040: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            'margin-right': '16px'
        },
        768: {
            display: 'flex',
            'flex-direction': 'column',
            // 'align-items': 'center',
            'margin-right': '16px'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            'margin-bottom': '4px'
        }
    }),
    statTitle: media(width, height, 'w', {
        1040: {
            'font-size': '16px',
            'font-weight': 400,
            'line-height': '1.15',
            color: '#7B7979'
        },
        768: {
            'font-size': '16px',
            'font-weight': 400,
            'line-height': '1.15',
            color: '#7B7979',
            'margin-bottom': '8px'
        },
        0: {
            'font-size': '14px',
            'font-weight': 400,
            'line-height': '1.15',
            color: '#7B7979'
        }
    }),
    leftAmount: {
        display: 'flex',
        padding: '4px 8px',
        'margin-left': '6px',
        'justify-content': 'center',
        'align-items': 'center',
        width: 'fit-content',
        'border-radius': '8px',
        'font-size': '16px',
        'font-weight': 600,
        'line-height': '1'
    },
    packNameTitle: media(width, height, 'w', {
        768: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'flex-end',
            'margin-bottom': '6px'
        },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'flex-end'
        }
    }),
    statAccordion: media(width, height, 'w', {
        0: {
            position: 'absolute',
            top: '-60px',
            right: '-24px'
        }
    }),
    packIcon: media(width, height, 'w', {
        768: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'margin-right': '6px'
        },
        0: { display: 'none' }
    }),
    packNameMob: { 'margin-left': '4px' },
    widthStoreWidget: media(width, height, 'w', {
        1440: { width: '250px' },
        768: { width: '135px' },
        375: { width: '74px' },
        0: { width: '68px' }
    }),
    widthCartHover: media(width, height, 'w', {
        768: { width: '135px' },
        0: { width: '74px' }
    }),
    widthCartWidget: media(width, height, 'w', {
        1440: { width: '192px' },
        768: { width: '135px' },
        375: { width: '74px' },
        0: { width: '68px' }
    })
});
export default stylesheets;