import React, {
    useState, useEffect, useContext
} from 'react'
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { Div } from '../../../../hybrid/wrappers';
import {
    CirclePicker, HuePicker, SketchPicker
} from 'react-color'
import HuePointer from './components/HuePointer';
import FormButtons from '../../form-control-buttons/FormButtons';
import UserContext from '../../../../common/utils/getContext';
import { t } from '../../../../common/components/T'
import { LangContext } from '../../../../common/decorators/Language';
import FormInput from '../form-input/FormInput';
import { getWidgetTranslates } from '../../../../common/utils/utils';
import PropTypes from 'prop-types';

const FormColorPicker = ({
    value,
    styles,
    setFormData,
    attribute
}) => {
    const [val, setVal] = useState(styles.variable.blueDeepColor)
    const [showAnotherColor, setShowAnotherColor] = useState(false)
    const [anotherColor, setAnotherColor] = useState(['#58FF73'])
    const [showPicker, setShowPicker] = useState(false)
    const [newColor, setNewColor] = useState('#d0a4ff')
    const { lang } = useContext(UserContext);
    const [transl, setTranslates] = useState(null)
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);

    const openAnotherColor = () => {
        setShowAnotherColor(true)
    }

    const openPicker = () => {
        setShowPicker(!showPicker)
    }
    useEffect(() => {
        value && setVal(value)
    }, [value])
    return (
        <Div

            styles={styles.formColorPicker}

        >
            <Div
                data-uitest='588_uitest' onClick={() => openPicker()}
                styles={styles.formColorPickerButton}
                effects={{ hover: { ...styles.formColorPickerButtonHover } }}
            >{t(
                    'FormColorPicker-ChooseColors', 'Выбрать цвет', 'widget', transl, 'form'
                )}</Div>
            <Div
                styles={styles.formColorPickerCircle}
                effects={{ hover: { ...styles.formColorPickerCircleHover } }}
            >
                <Div
                    data-uitest='589_uitest' onClick={() => openPicker()}
                    styles={{ ...styles.formColorPickerCircleItem, 'background-color': val }}></Div>
                {showPicker && <Div
                    styles={styles.formColorPickerWrapper}
                    onMouseLeave={() => setShowPicker(false)}
                >
                    <Div styles={styles.formColorPickerTitle}>{t(
                        'FormColorPicker-BasicColors', 'Базовые цвета', 'widget', transl, 'form'
                    )}</Div>
                    <Div styles={styles.formColorPickerContainer}>
                        <CirclePicker
                            onChange={(color) => {
                                setVal(color.hex)
                                setFormData(attribute, color.hex)
                                setShowPicker(false)
                            }}
                            circleSpacing={8}
                            circleSize={26}

                        />
                    </Div>
                    <Div styles={styles.formColorPickerTitle}>{t(
                        'FormColorPicker-ElseColors', 'Другие цвета', 'widget', transl, 'form'
                    )}</Div>
                    <Div styles={styles.formColorPickerContainer}>
                        <CirclePicker
                            colors={anotherColor}
                            onChange={(color) => {
                                setVal(color.hex)
                                setFormData(attribute, color.hex)
                                setShowPicker(false)
                            }}
                            circleSpacing={8}
                            circleSize={26}
                            width='fit-content'
                        />
                        <Div>
                            <Div
                                styles={styles.formColorPickerAddButton}
                                data-uitest='590_uitest' onClick={() => openAnotherColor()}
                            >+</Div>
                        </Div>
                    </Div>
                    {showAnotherColor || true && <Div>
                        <Div styles={styles.formColorPickerContainer}>
                            <Div styles={styles.formColorPickerHeader}>
                                <Div>
                                    <Div styles={{ ...styles.formColorPickerCircleFooter, 'background-color': newColor }}></Div>
                                </Div>
                                <Div styles={styles.formColorPickerTitle}>{t(
                                    'FormColorPicker-16Code', '16-ричный код', 'widget', transl, 'form'
                                )}</Div>
                                <Div>
                                    <FormInput
                                        compStyle={{
                                            input: styles.formColorPickerInput,
                                            inputFocus: styles.formColorPickerInputFocus,
                                            inputError: styles.formColorPickerInputFocus,
                                            wrapper: { 'margin-bottom': '0' }
                                        }}
                                        value={newColor}
                                        handlers={{ onChange: (attribute, value) => setNewColor(value) }}
                                    />
                                </Div>
                            </Div>
                        </Div>
                        <Div styles={styles.formColorPickerContainer}>
                            <SketchPicker
                                color={newColor}
                                onChange={(color) => setNewColor(color.hex)}
                                disableAlpha={false}
                                width='100%'
                            />
                        </Div>
                        <Div styles={styles.formColorPickerTitle}>{t(
                            'FormColorPicker-Shade', 'Оттенок', 'widget', transl, 'form'
                        )}</Div>
                        <Div styles={styles.formColorPickerContainer}>
                            <HuePicker
                                color={newColor}
                                width='100%'
                                onChange={(color) => setNewColor(color.hex)}
                                pointer={() => <HuePointer
                                    color={newColor}
                                    styles={styles}
                                />}
                            />
                        </Div>
                        <FormButtons
                            sendFormData={() => {
                                const newAnotherColor = anotherColor
                                newAnotherColor.push(newColor)
                                setAnotherColor(newAnotherColor)
                                setShowAnotherColor(false)
                            }}
                            buttons={[
                                {
                                    type: 'cancel',
                                    buttonText: t('cancel', 'Отмена', 'all', translation),
                                    clickFunc: () => setShowAnotherColor(false)
                                },
                                {
                                    type: 'ok',
                                    buttonText: 'OK'
                                }
                            ]}
                            customFormButton={{
                                ok: {
                                    singleButton: {
                                        'max-width': '82px',
                                        width: '82px'
                                    }
                                },
                                cancel: {
                                    singleButton: {
                                        width: '118px',
                                        'max-width': '118px'
                                    }
                                }
                            }}
                        />
                    </Div>}
                </Div>}
            </Div>
        </Div>
    )
}
FormColorPicker.propTypes = {
    value: PropTypes.string,
    styles: PropTypes.object,
    setFormData: PropTypes.func,
    attribute: PropTypes.string
};
export default Styles(FormColorPicker, getStyles)