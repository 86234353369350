import {
    useState, useEffect, useContext
} from 'react';

import UserContext from '../../common/utils/getContext';
import {
    Div, Button, svgSprite, zendoStorage
} from '../../hybrid/wrappers';
import { getDataCookies } from '../../common/utils/memo';
import { useWindowDimensions } from '../../common/utils/windowDimensions';
import Styles from '../../common/decorators/Styles';
import getStyles from '../../project/styles/widget-styles/cookie-modal-styles';
import parse from 'html-react-parser';


const CookieModal = ({ styles }) => {
    const { lang } = useContext(UserContext);
    const widthDimensions = useWindowDimensions();
    const [mobile, setMobile] = useState('');
    const [cookieModalDOM, setCookieModalDOM] = useState('visible');
    const [modalWrapperMode, setModalWrapperMode] = useState('visible');
    const [cookieData, setCookieData] = useState(null)

    const position = cookieData?.position;

    const getCookieData = async() => {
        const cookieData = await getDataCookies();
        setCookieData(cookieData)
    }

    const activeModalStyle = () => {
        if (modalWrapperMode !== 'visible') {
            if (position === 'top') {
                return styles.cookieModalWrapperToTop;
            } else {
                return styles.cookieModalWrapperToBottom;
            }
        } else {
            return {};
        }
    }

    const closeModalHandler = async () => {
        if (modalWrapperMode === 'visible') {
            setModalWrapperMode('hidden');
            await setTimeout(() => {
                setCookieModalDOM('hidden');
            }, 200)
        }
    }

    const handleSendData = async (cookieAgreement) => {
        closeModalHandler();
        getDataCookies.cache.clear();
        zendoStorage.set('cookieAgreement', cookieAgreement)
        getDataCookies.cache.clear();
    }

    useEffect(() => {
        getCookieData();
    }, [])

    useEffect(() => {
        setCookieModalDOM('visible');
        setModalWrapperMode('visible');
    }, [cookieData])

    useEffect(() => {
        widthDimensions < 768
            ? setMobile(true)
            : setMobile(false);
    }, [widthDimensions]);

    if (
        !cookieData ||
        cookieModalDOM !== 'visible'
    ) {
        return null;
    }

    return <Div styles={
        {
            ...mobile ? styles.cookieModalWrapperMob : styles.cookieModalWrapperDesktop,
            ...position === 'top' ? styles.cookieModalWrapperTop : styles.cookieModalWrapperBottom,
            ...activeModalStyle()
        }}>
        {mobile ?
            <Div styles={styles.cookieModalContentWrapperMob}>
                <Div styles={styles.cookieModalMobileContent}>
                    <Div styles={styles.cookieModalImage}>
                        {svgSprite('cookies-image', { width: 40, height: 40 })}
                    </Div>
                    <Div className='CookieModalText'>
                        <Div styles={styles.cookieModalTextContent}>
                            {parse(cookieData?.text ? cookieData?.text[lang] || cookieData?.text.default : '')}
                        </Div>
                    </Div>
                </Div>
                <Div styles={styles?.cookieModalBtnWrap}>
                    <Button
                        styles={styles?.cookieModalApplyBtn}
                        data-uitest='541_uitest' onClick={() => handleSendData(true)}
                    >
                        {cookieData?.applyText ? cookieData?.applyText[lang] || cookieData?.applyText.default : ''}
                    </Button>
                </Div>
            </Div>
            :
            <Div styles={styles.cookieModalContentWrapper}>
                <Div styles={styles.cookieModalImage}>
                    {svgSprite('cookies-image', { width: 40, height: 40 })}
                </Div>
                <Div className='CookieModalText'>
                    <Div styles={styles.cookieModalTextContent}>
                        {parse(cookieData?.text ? cookieData?.text[lang] || cookieData?.text.default : '')}
                    </Div>
                </Div>
                <Div styles={styles?.cookieModalBtnWrap}>
                    <Button
                        styles={styles?.cookieModalApplyBtn}
                        data-uitest='542_uitest' onClick={() => handleSendData(true)}
                    >
                        {cookieData?.applyText ? cookieData?.applyText[lang] || cookieData?.applyText.default : ''}
                    </Button>
                </Div>
            </Div>
        }
    </Div>
}

export default Styles(CookieModal, getStyles);