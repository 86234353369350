// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import DatePicker from './DatePicker'

const FormDateCalendar = (props) => {
    return (
        <DatePicker
            {...props}
        />
    );
};

export default FormDateCalendar;