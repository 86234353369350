// @generated: @expo/next-adapter@2.1.5
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Div, P, svgSprite
} from '../../../hybrid/wrappers';

const DropDownText = ({
    styles,
    val,
    parentID,
    handlers = {},
    rowIndex,
    parent_id,
    hasChildren,
    drop,
    countShow,
    depth
}) => {
    // Состояние открытия и закрытия кнопки дропа
    // const [drop, setDrop] = useState(false)
    //Количество подкатегорий
    // const [countShow, setCountShow] = useState(0)
    const compStyles = useMemo(() => ({ ...styles }), [styles]);
    return (
        <Div styles={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: parent_id ? `${depth * 30}px` : '0'
        }}>
            {hasChildren ?
                <Div
                    styles={{ ...styles.svgDropPM }}
                    data-uitest='612_uitest' onClick={() => {
                        return handlers?.name(parentID, rowIndex, null, countShow)
                    }}
                >
                    {svgSprite(drop ? 'minus' : 'plus', { style: compStyles.searchMobileButtonSvg })}
                </Div>
                : <></>}

            <P>{val}</P>
        </Div>
    )
};

DropDownText.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    styles: PropTypes.object,
    name: PropTypes.string,
    link: PropTypes.string,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ])
};

export default DropDownText;