// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import {
    P, Div, svgSprite
} from '../../../hybrid/wrappers';
import T, { t } from '../../../common/components/T';
import uikit from '../../../project/uikit/styles';

const Buyer = ({
    getHandlers, gridStyles, styles, name, val, translation
}) => {
    return (
        <Div
            {...getHandlers(false)}
            styles={
                {
                    ...getHandlers(false)['onClick'] ? styles.cursorPointer : {},
                    ...gridStyles[name]?.wrapper?.styles || styles.flexColumn
                }
            }
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            <Div
                styles={gridStyles[name]?.p?.styles || styles.buyerName}
                effects={gridStyles[name]?.p?.effects || {}}
            >
                {
                    val?.name_formatted ?
                        <Div styles={{
                            ...gridStyles[name]?.p || {},
                            ...uikit.text, ...uikit.mt1, color: styles.variable.darkColor
                        } }>
                            <P styles={{
                                color: styles.variable.darkColor, ...gridStyles[name]?.text, 'white-space': 'nowrap'
                            }}>
                                {val?.name_formatted}
                            </P>
                            {
                                `${val.username}` !== 'undefined' && `${val.username}` !== 'null' &&
                                <Div styles={uikit.flexRow}>
                                    <Div styles={{
                                        ...uikit.flexRow, ...uikit.alignItemsCenter, ...styles.patronymicBlock
                                    }}>
                                        {svgSprite('user-circle-fill', {
                                            fill: styles.variable.blueColor,
                                            width: '18px',
                                            height: '18px',
                                            style: { 'margin-right': '8px' }
                                        })}
                                        <P styles={{
                                            color: styles.variable.darkColor, ...gridStyles[name]?.p?.login, 'white-space': 'nowrap'
                                        }}>
                                            {`${val.username}` !== 'undefined' && `${val.username}` !== 'null' && `${val.username.innerText}`}
                                        </P>
                                    </Div>
                                </Div>
                            }

                        </Div>
                        :
                        val && (`${val.username}` !== 'undefined' && `${val.username}` !== 'null' ||
                    `${val.patronymic}` !== 'undefined' && `${val.patronymic}` !== 'null' ||
                    `${val.first_name}` !== 'undefined' && `${val.first_name}` !== 'null' ||
                    `${val.full_name}` !== 'undefined' && `${val.full_name}` !== 'null' ||
                    `${val.last_name}` !== 'undefined' && `${val.last_name}` !== 'null') ?
                            <Div styles={{
                                ...gridStyles[name]?.p || {},
                                ...uikit.text, ...uikit.mt1, color: styles.variable.darkColor
                            } }>
                                <P styles={{
                                    color: styles.variable.darkColor, ...gridStyles[name]?.text, 'white-space': 'nowrap'
                                }}>
                                    {`${val.last_name}` !== 'undefined' && `${val.last_name}` !== 'null' && `${val.last_name}`}
                                    {' '}
                                    {`${val.first_name}` !== 'undefined' && `${val.first_name}` !== 'null' && `${val.first_name}`}
                                    {' '}
                                    {`${val.patronymic}` !== 'undefined' && `${val.patronymic}` !== 'null' && `${val.patronymic}`}
                                    {' '}
                                    {`${val.full_name}` !== 'undefined' && `${val.full_name}` !== 'null' && `${val.full_name}`}

                                </P>
                                {
                                    `${val.username}` !== 'undefined' && `${val.username}` !== 'null' &&
                                    <Div styles={uikit.flexRow}>
                                        <Div styles={{
                                            ...uikit.flexRow, ...uikit.alignItemsCenter, ...styles.patronymicBlock, ...gridStyles[name]?.patronymicBlock
                                        }}>
                                            {svgSprite('user-circle-fill', {
                                                fill: styles.variable.blueColor,
                                                width: '18px',
                                                height: '18px',
                                                style: { 'margin-right': '8px' }
                                            })}
                                            <P styles={{
                                                color: styles.variable.darkColor, ...gridStyles[name]?.p?.login, 'white-space': 'nowrap'
                                            }}>
                                                {`${val.username}` !== 'undefined' && `${val.username}` !== 'null' && `${val.username}`}
                                            </P>
                                        </Div>
                                    </Div>
                                }

                            </Div> : <P>-</P>
                }
                {
                    val && val.deleted ?
                        <P styles={styles.deleteUserLabel}>
                            {t(
                                'gridDeletedUser', 'Удален', 'widget', translation, 'grid'
                            )}
                        </P>
                        : null
                }
                {val?.has_filled_documents === false &&
                    <P styles={val?.last_name ? styles.documentUserLabel : { ...styles.documentUserLabel, 'margin-top': '4px' }}>
                        <T textName='gridDocumentUser' defaultStr='Нет документов' page='all' />

                    </P>
                }

            </Div>
        </Div>
    )
};

Buyer.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    name: PropTypes.string,
    val: PropTypes.object
};

export default Buyer;