// @generated: @expo/next-adapter@2.1.5
import React, {
    useCallback, useState, useMemo, useContext
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import FormModal from './FormModal';
import T from '../../../../common/components/T';
import FormInput from '../form-input/FormInput';
import UserContext from '../../../../common/utils/getContext';

const FormChangeDataInModal = (props) => {
    const {
        attribute,
        hint,
        placeholder,
        required,
        title,
        compStyle,
        value,
        type,
        styles,
        customData
    } = props;
    const { userToken, setUserToken } = useContext(UserContext);

    const { modalSendUrl } = customData;
    const [open, setOpenModal] = useState(false);

    const formStyles = useMemo(() =>
        customData?.formModal?.formStyles
            ? customData.formModal.formStyles(styles, compStyle)
            : {}
    , [customData?.formModal, styles, compStyle]);

    const formOptions = useMemo(() =>
        customData?.formModal?.options
            ? customData.formModal.options(
                attribute, placeholder, required, hint, value
            )
            : []
    , [customData?.formModal, attribute, placeholder, required, hint, value]);
    //const [eventList, setEventList] = useState(null);

    const setOpen = useCallback((value) => {
        setOpenModal(value);
    }, []);
    // useEffect(() => {
    //     (async () => {
    //         const resEventList = await getData('/api/v1/user/security/event-list', userToken, lang);
    //
    //         const toarrEvent = resEventList?.data?.map(item => ({
    //             id: item.id, name: item.name
    //         }));
    //         setEventList(toarrEvent)
    //     })()
    // }, [userToken, lang]);
    const formModalConfig = useMemo(() => ({
        attribute,
        options: formOptions,
        modalStyles: formStyles,
        modalSendUrl,
        modalTitle: title,
        titlePopup: '',
        message: customData?.message,
        setOpen: setOpen,
        userToken,
        buttonText: type === 'password-change-widget' ? <T textName='change' defaultStr='Изменить' page='all' /> : <T textName='send' defaultStr='Отправить' page='all' />,
        setUserToken,
        cancelFuncs: { web: { onClick: () => setOpen(false) } },
        ...customData?.formModal?.addedData
            ? customData?.formModal?.addedData
            : {}

    }), [attribute, formOptions, formStyles, modalSendUrl, title, customData?.message, customData?.formModal?.addedData, setOpen, userToken, type, setUserToken]);
    return (
        <>
            <FormInput
                {...props}
                disabled={true}
                buttons={{
                    modal: {
                        icon: 'pen-field',
                        handler: () => setOpen(!open)
                    }
                }}
            />
            {
                !!open
                    && <FormModal {...formModalConfig} />
            }
        </>
    );
};

FormChangeDataInModal.propTypes = {
    attribute: PropTypes.string,
    hint: PropTypes.node,
    placeholder: PropTypes.node,
    required: PropTypes.bool,
    title: PropTypes.node,
    compStyle: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
    styles: PropTypes.object,
    customData: PropTypes.object
};

export default Styles(FormChangeDataInModal, getStyles);