// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import {
    svgSprite, Div, P
} from '../../../hybrid/wrappers';
import { t } from '../../../common/components/T';

const UserStats = ({
    styles, data, trans
}) => {
    const statsUser = [
        {
            title: t(
                'user_modal_NewUser', 'Новых', 'widget', trans, 'userModal'
            ),
            background: styles.variable.greenTransparentColor,
            color: styles.variable.greenColor,
            svg: 'user-plus',
            value: data.new_partners_number || 0
        },
        {
            title: t(
                'user_modal_ActiveUser', 'Активных', 'widget', trans, 'userModal'
            ),
            background: styles.variable.blueColorTransparent,
            color: styles.variable.blueColor,
            svg: 'user-check',
            value: data.active_partners_number || 0
        },
        {
            title: t(
                'user_modal_SelfInvited', 'Лично пригл.', 'widget', trans, 'userModal'
            ),
            background: styles.variable.orangeDeepTransparentColorLight,
            color: styles.variable.orangeDeepColorLight,
            svg: 'user-arrow',
            value: data.children_number || data.children_count || 0
        },
        {
            title: t(
                'user_modal_All', 'Всего', 'widget', trans, 'userModal'
            ),
            background: styles.variable.purpleDeepTransparentColor,
            color: styles.variable.purpleDeepColor,
            svg: 'users',
            value: data.partners_number || data.descendants_count || 0
        },
        ...data.binary_partners?.left ? [
            {
                title: t(
                    'user_modal_LeftBranch', 'Левая ветка', 'widget', trans, 'userModal'
                ),
                background: styles.variable.greyExtraDarkColorTransparent,
                color: styles.variable.greyExtraDarkColor,
                svg: 'structure-1-2',
                value: data.binary_partners?.left || 0
            }
        ] : [],
        ...data.binary_partners?.right ? [
            {
                title: t(
                    'user_modal_RightBranch', 'Правая ветка', 'widget', trans, 'userModal'
                ),
                background: styles.variable.greyExtraDarkColorTransparent,
                color: styles.variable.greyExtraDarkColor,
                svg: 'structure-2-2',
                value: data.binary_partners?.right || 0
            }
        ] : []
    ]
    return (
        <Div styles={styles.userModalChildrenBlock}>
            {statsUser && statsUser.map((stats, ind) =>
                <Div key={`statsUser_${ind}`} styles={styles.userModalChildrenItem}>
                    <Div styles={{
                        ...styles.userModalChildrenNew, ...styles.userModalChildrenItemBlock, ...{ background: stats.background }
                    }}>
                        <Div styles={styles.userModalChildrenIcon}>
                            {svgSprite(stats.svg,
                                {
                                    width: '28',
                                    height: '28',
                                    fill: stats.color
                                })}
                        </Div>
                        <Div>
                            <P styles={styles.userModalChildrenTitle}>
                                {stats.value === 0 ? '0' : stats.value}
                            </P>
                            <P styles={styles.userModalChildrenText}>
                                {stats.title}
                            </P>
                        </Div>
                    </Div>
                </Div>)}
        </Div>
    )
}
UserStats.propTypes = {
    styles: PropTypes.object,
    newUser: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    activeUser: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    allUser: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};
export default UserStats;