// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useMemo, useContext, useEffect
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { Div } from '../../../../hybrid/wrappers';
import UserContext from '../../../../common/utils/getContext';
import NoReferal from './NoReferal';
import InputSponsor from '../form-input/FormInput';
import { getWidgetTranslates } from '../../../../common/utils/utils';
import T, { t } from '../../../../common/components/T'
import { LangContext } from '../../../../common/decorators/Language';
import ContentAlert from '../../../../widgets/contentAlert/ContentAlert'

const FormSponsorWidget = (props) => {
    const {
        attribute,
        switchHint,
        disabled,
        title,
        value,
        setFormData,
        styles,
        variation
    } = props;

    const [inp, setInp] = useState({ val: value || null, showInput: true });
    const { refer, lang } = useContext(UserContext);
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    const [transl, setTranslates] = useState(null)
    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);

    const refData = useMemo(() => {
        if (refer && refer !== 'false') return refer;
        return false;
    }, [refer]);

    useEffect(() => {
        if (refData) setFormData(attribute, refData);
    }, [attribute, refData, setFormData]);
    return (
        <Div >

            <Div>
                {!!inp.showInput &&
                    <InputSponsor
                        {...props}
                        value={value || refData}
                        disabled={Boolean(disabled) || !!refData}
                        title={title || variation !== 'no_title' ? {
                            body: t(
                                'FormSponsorWidget-placeholder', 'Логин спонсора', 'widget', transl, 'form'
                            )
                        } : false}
                    />
                }
            </Div>
        </Div>
    );
};

FormSponsorWidget.propTypes = {
    attribute: PropTypes.string,
    switchHint: PropTypes.node,
    disabled: PropTypes.bool,
    title: PropTypes.object,
    value: PropTypes.string,
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    variation: PropTypes.string
};

export default Styles(FormSponsorWidget, getStyles);