// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useMemo, useCallback, useEffect, useContext
} from 'react';
import HeaderYear from './HeaderYear';
import Styles from '../../../../../common/decorators/Styles';
import getStyles from '../../../../../project/styles/widget-styles/datepicker-web-styles';
import {
    Button, Div, P
} from '../../../../../hybrid/wrappers';
import UserContext from '../../../../../common/utils/getContext';
import { t } from '../../../../../common/components/T';
import moment from 'moment';
import { getWidgetTranslates } from '../../../../../common/utils/utils';
import { LangContext } from '../../../../../common/decorators/Language';

const CustomHeader = ({
    styles,
    changeYear,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    date
}) => {
    const [activeYear, setAvtiveYear] = useState(new Date(date).getFullYear());
    const [activeMonth, setAvtiveMonth] = useState(new Date(date).getMonth());
    const [disabledPrev, setDisabledPrev] = useState(false);
    const [disabledNext, setDisabledNext] = useState(false);
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    const { lang, time } = useContext(UserContext);
    const [trans, setTranslates] = useState(null);
    useEffect(() => {
        getWidgetTranslates(lang, 'month', translation, setTranslates);
    }, [lang, translation])

    // const years = useMemo(() => Array.from({ length: 100 }, (_, i) => Number(moment(time.date).year()) - 16 - i), []);
    const years = useMemo(() => Array.from({ length: 100 }, (_, i) => Number(moment(time.date).year()) - i), [time]);
    const months = useMemo(() => [
        t(
            'months1', 'Январь', 'widget', trans, 'month'
        ),
        t(
            'months2', 'Февраль', 'widget', trans, 'month'
        ),
        t(
            'months3', 'Март', 'widget', trans, 'month'
        ),
        t(
            'months4', 'Апрель', 'widget', trans, 'month'
        ),
        t(
            'months5', 'Май', 'widget', trans, 'month'
        ),
        t(
            'months6', 'Июнь', 'widget', trans, 'month'
        ),
        t(
            'months7', 'Июль', 'widget', trans, 'month'
        ),
        t(
            'months8', 'Август', 'widget', trans, 'month'
        ),
        t(
            'months9', 'Сентябрь', 'widget', trans, 'month'
        ),
        t(
            'months10', 'Октябрь', 'widget', trans, 'month'
        ),
        t(
            'months11', 'Ноябрь', 'widget', trans, 'month'
        ),
        t(
            'months12', 'Декабрь', 'widget', trans, 'month'
        )
    ], [trans]);

    const yearSetter = useCallback((value) => {
        changeYear(value);
        setAvtiveYear(value);
    }, [changeYear, setAvtiveYear]);

    useEffect(() => {
        const year = new Date(date).getFullYear();
        const month = new Date(date).getMonth();

        setAvtiveYear(year);
        setAvtiveMonth(month);

        if (year === years[0] && month === 0) {
            if (!disabledPrev) setDisabledPrev(true);
        } else if (disabledPrev) setDisabledPrev(false);

        if (year === years[years.length - 1] && month === 11) {
            if (!disabledNext) setDisabledNext(true);
        } else if (disabledNext) setDisabledNext(false);
    }, [date, disabledNext, disabledPrev, years]);

    return (
        <Div styles={styles.dpHeader} className='dpHeader'>
            <P styles={styles.dpHeader__date}>{months[activeMonth]}</P>
            <HeaderYear
                styles={styles}
                years={years}
                changeYear={changeYear}
                activeYear={activeYear}
                yearSetter={yearSetter}
            />
            <Div styles={styles.dpHeader__control}>
                <Button
                    type='button'
                    data-uitest='23_uitest' onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled || disabledPrev}
                    className='dpHeader__arrow'
                />
                <Button
                    type='button'
                    data-uitest='24_uitest' onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled || disabledNext}
                    className='dpHeader__arrow dpHeader__arrow_right'
                />
            </Div>
        </Div>
    );
};

export default Styles(CustomHeader, getStyles);