const mongoose = require('mongoose');

const Langs = new mongoose.Schema({
    alias: { type: String, index: true },
    name: { type: String },
    active: { type: Boolean },
    show: { type: Boolean },
    isDefault: { type: Boolean },
    flag: { type: Array }
});
module.exports = mongoose.models?.languages || mongoose.model('languages', Langs);