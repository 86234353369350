import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Div, P, svgSprite
} from '../wrappers';
import getStyles from '../../project/styles/widget-styles/grid-styles';
import Styles from '../../common/decorators/Styles';
import ReactDOM from 'react-dom';
import ModalDropDown from './ModalDropDown';

const OfficeTableDropdown = ({
    children,
    alias,
    activeTr,
    setActiveTr,
    styles,
    refTable,
    gridName,
    refTableChild
}) => {
    const modalDropdown = useRef(null);
    const modal = useRef(null);
    const showDropdown = useMemo(() => alias === activeTr, [alias, activeTr]);
    const dataArr = Object.keys(children).filter(k => children[k]);
    const firstVal = dataArr.shift();

    const isDropdown = useMemo(() => {
        const res = [];
        dataArr.map((item) => {
            if (children[item] !== null) {
                res.push(children[item]);
            }
        });
        return res.length > 0;
    }, [dataArr, children]);

    const ContactImg = (val) => {
        switch (val) {
        case 'phone':
            return 'phone-colored';
        case 'facebook':
            return 'facebook-colored';
        case 'instagram':
            return 'instagram-colored';
        case 'ok':
            return 'odnoklassniki-colored';
        case 'telegram':
            return 'telegram-colored';
        case 'twitter':
            return 'twitter-colored';
        case 'viber':
            return 'viber-colored';
        case 'vk':
            return 'vkontakte-colored';
        case 'whatsapp':
            return 'whatsapp-colored';
        default:
            return 'phone';
        }
    };

    return (
        <Div styles={styles.dropdownWrapper} childrenRef={modalDropdown}>
            <Div key={alias} styles={showDropdown && !!isDropdown
                ? { ...styles.dropdownBlockRow, ...styles.dropdownBlockRowActiveWeb }
                : { ...styles.dropdownBlockRow }}
            data-uitest='33_uitest' onClick={(e) => {
                e.stopPropagation();
                setActiveTr(alias !== activeTr ? alias : false);

                if (modal?.current) {
                    modal.current.style.top = `${modalDropdown?.current?.getBoundingClientRect()?.top - refTable?.current.getBoundingClientRect()?.top + modalDropdown?.current?.getBoundingClientRect()?.height}px`;
                    modal.current.style.left = `${modalDropdown?.current?.getBoundingClientRect()?.left - refTable?.current.getBoundingClientRect()?.left}px`;
                    modal.current.style.minWidth = `${modalDropdown?.current?.getBoundingClientRect()?.width}px`
                }
            }}
            >
                {svgSprite('email-colored', { style: styles.contactIcon })}
                <P styles={styles.dropdownItem}>
                    {`${children[firstVal]}`}
                </P>
                {
                    !!isDropdown
                    &&
                        <P styles={{ ...styles.dropdownArrow, ...styles.cursorPointer }}>
                            {
                                showDropdown
                                    ? svgSprite('angle-up', { style: styles.dropdownArrowSvg })
                                    : svgSprite('angle-down', { style: styles.dropdownArrowSvg })
                            }
                        </P>

                }

            </Div>
            {
                !!isDropdown && Object.keys(styles).length &&
              document.getElementById(`${gridName}portalDropdown`) &&
              ReactDOM.createPortal(<ModalDropDown
                  showDropdown={showDropdown}
                  dataArr={dataArr}
                  modal={modal}
                  styles={styles}
                  ContactImg={ContactImg}
                  refTable={refTable}
                  modalDropdown={modalDropdown}
                  refTableChild={refTableChild}
              >{children}</ModalDropDown>, document?.getElementById(`${gridName}portalDropdown`))
            }
        </Div>
    );
};

OfficeTableDropdown.propTypes = {
    alias: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    activeTr: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number
    ]),
    setActiveTr: PropTypes.func,
    styles: PropTypes.object,
    refTable: PropTypes.object,
    gridName: PropTypes.string,
    refTableChild: PropTypes.object
};

export default Styles(OfficeTableDropdown, getStyles);