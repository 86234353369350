// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P } from '../../../hybrid/wrappers';

const Color = ({
    val, getHandlers, gridStyles, name
}) => {
    if (Boolean(val)) {
        return (
            <P
                {...getHandlers(false)}
                styles={gridStyles[name]?.wrapper?.styles ? { ...gridStyles[name].wrapper.styles, 'background-color': val } : {}}
            >
            </P>
        );
    }
};

Color.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    name: PropTypes.string
};

export default Color;