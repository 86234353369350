// @generated: @expo/next-adapter@2.1.5
import React from 'react';
// import PropTypes from 'prop-types';
import { Div, P } from '../../../hybrid/wrappers';
const Notifications = ({
    getHandlers, gridStyles, name, val, styles
}) => {
    if (!val?.value) return null;
    let type = 'SMS';

    if (val?.value.startsWith('@') === true) {
        type = 'telegram'
    }
    if (!val?.value.startsWith('@') && val?.value.includes('@')){
        type = 'E-mail'
    }
    if (val?.senderType){
        if (val?.senderType==='broadcast' || val?.senderType==='firebase'){
            type = 'Push'
        }
    }
    return (
        <Div
            {...getHandlers(false)}
            styles={gridStyles[name]?.wrapper?.styles || {}}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            <P>{val?.value}</P>

            <Div styles={{
                'margin-top': '5px',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                'width': '100px',
                height: '24px',
                'background-color': styles.variable.greyExtraDarkColorTransparent,
                padding: '5px 8px',
                color: styles.variable.greySuperDarkColor,
                'border-radius': '6px'
            }}>
                <P>{type}</P>
            </Div>
        </Div>
    );
};

// Notifications.propTypes = {
//     getHandlers: PropTypes.func,
//     gridStyles: PropTypes.object,
//     styles: PropTypes.object,
//     name: PropTypes.string,
//     val: PropTypes.oneOfType([
//         PropTypes.object,
//         PropTypes.number,
//         PropTypes.string,
//     ]),
// };

export default Notifications;