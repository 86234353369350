// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {Div} from '../../../../hybrid/wrappers';
import T, { t } from '../../../../common/components/T'

const HtmlBlock = (props) => {
    return (
        <Div styles={{...props?.compStyle?.wrapper}}>
            {props?.data}
        </Div>
    );
};


HtmlBlock.propTypes = {
    attribute: PropTypes.string,
    hint: PropTypes.node,
    placeholder: PropTypes.node,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.object,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
    setFormData: PropTypes.func,
    errors: PropTypes.array,
    message: PropTypes.object,
    addon: PropTypes.node,
    variation: PropTypes.string,
    customData: PropTypes.object,
    data: PropTypes.node,
};

export default Styles(HtmlBlock, getStyles);