// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P, svgSprite } from '../../../hybrid/wrappers';

const Check = ({
    val, getHandlers, gridStyles, name, styles
}) => {
    if (val === '-') {
        return val
    }
    if (name === 'deleted' ? !Boolean(val) : Boolean(val)) {
        return (
            <P
                {...getHandlers(false)}
                styles={gridStyles[name]?.wrapper?.styles || {}}
                effects={gridStyles[name]?.wrapper?.effects || {}}
            >
                {
                    svgSprite(gridStyles[name]?.ico?.checkIcon || 'check',
                        gridStyles[name]?.ico?.styles || {
                            fill: styles.variable.greenDeepColor,
                            width: '15px',
                            height: '11px'
                        })
                }
            </P>
        );
    }
    return (
        <P
            {...getHandlers(false)}
            styles={gridStyles[name]?.wrapper?.styles || {}}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            {
                svgSprite(gridStyles[name]?.ico?.noCheckIcon || 'cross',
                    gridStyles[name]?.ico?.styles || {
                        fill: styles.variable.redDarkColor,
                        width: '13px',
                        height: '13px'
                    })
            }
        </P>
    );
};

Check.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    name: PropTypes.string
};

export default Check;