import React from 'react'
import { Div } from '../../../../../hybrid/wrappers'

const HuePointer = ({
    styles,
    color
}) => {
    return (
        <Div styles={{ ...styles.formColorPickerHue }}>
            <Div styles={{ ...styles.formColorPickerItem, 'background-color': color }}></Div>
        </Div>
    )
}
export default HuePointer