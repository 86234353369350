import { media } from '../../../common/utils/utils';
import officeStyles from './office/datepicker-range-styles'
import mainStyles from './main/datepicker-range-styles'
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,
    horizontal: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-direction': 'row'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column'
        }
    }),
    vertical: {
        display: 'flex',
        'flex-direction': 'column'
    },
    topContainer: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-direction': 'row',
            margin: '16px 16px 0 16px',
            'align-items': 'center'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            margin: '8px 12px 0',
            'flex-direction': 'row',
            'justify-content': 'center'
        }
    }),
    mobileCustomDatePickerOwn: {
        ...uikit.text,
        'margin-bottom': '6px',
        padding: '0 12px'
    },
    bottomContainer: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'flex-end',
            'grid-template-columns': 'auto auto',
            padding: '8px 16px',
            'border-top': `1px solid ${variable.blackExtraTransparentColor}`,
            flex: 1,
            'flex-direction': 'row'
        },
        475: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            padding: '16px',
            flex: 1,
            'flex-direction': 'row'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            padding: '16px',
            flex: 1,
            'flex-direction': 'row'
        }
    }),
    mainContainer: media(width, height, 'w', {
        768: {
            'background-color': variable.whiteColor,
            width: '700px',
            'border-radius': '10px',
            flex: 1
        },
        0: {
            'background-color': variable.whiteColor,
            width: 'auto',
            'border-radius': '20px 20px 0 0',
            flex: 1
        }
    }),
    containerInput: media(width, height, 'w', {
        1024: {},
        0: {
            width: 'calc(50% - 10px)',
            'max-width': '180px',
            margin: '0 2px'
        }
    }),
    simpleButton: media(width, height, 'w', {
        768: {
            'border-radius': '14px',
            padding: '12px 16px',
            display: 'flex',
            'align-items': 'center',
            'background-color': 'transparent',
            outline: 'transparent',
            position: 'relative',
            'flex-direction': 'row',
            ...uikit.small,
            'font-weight': '500'
        },
        0: {
            background: 'transparent',
            'border-radius': '14px',
            ...uikit.small,
            'font-weight': 500,
            width: '100%',
            padding: 0
        }
    }),
    menuButton: media(width, height, 'w', {
        768: {
            background: 'transparent',
            ...uikit.small,
            padding: 0,
            'margin-top': '16px'
        },
        0: {
            background: 'transparent',
            ...uikit.text,
            'padding-bottom': '16px',
            'margin-top': '16px',
            'border-bottom': `1px solid ${variable.calcColor('dark', '0.12')}`
        }
    }),
    menuButtonNative: {
        background: 'transparent',
        padding: 0,
        'margin-top': '16px'
    },
    menuButtonNativeText: { ...uikit.small },
    button: media(width, height, 'w', {
        768: {
            height: '32px',
            'font-weight': '500',
            ...uikit.small,
            'text-align': 'center',
            cursor: 'pointer',
            'border-radius': '8px',
            margin: '0 4px',
            padding: '8px 16px'
        },
        480: {
            height: '32px',
            'font-weight': '500',
            ...uikit.small,
            'text-align': 'center',
            'border-radius': '8px',
            margin: '0 4px',
            padding: '0 24px'
        },
        0: {
            height: '32px',
            'font-weight': '500',
            ...uikit.small,
            'text-align': 'center',
            'border-radius': '8px',
            margin: '0 4px',
            padding: '0 20px'
        }
    }),
    buttonCustom: {
        height: '64px',
        width: '100%',
        'border-radius': '12px',
        ...uikit.flexRow,
        ...uikit.justifyContentCenter,
        ...uikit.alignItemsCenter,
        ...uikit.subtitle,
        ...uikit.medium
    },
    buttonCancelCustom: {
        'margin-right': '8px',
        'background-color': variable.greyExtraTransparentColor,
        color: variable.darkColor
    },
    buttonOkCustom: {
        'margin-right': '8px',
        'background-color': variable.orangeColor,
        color: variable.whiteColor
    },
    buttonOk: {
        color: variable.whiteColor,
        background: variable.blueColor
    },
    buttonCancel: {
        'background-color': variable.greyExtraLightColor,
        color: variable.darkColor
    },
    containerButton: media(width, height, 'w', {
        768: {
            padding: '16px 24px',
            'min-width': '194px'
        },
        0: {
            padding: '16px 12px',
            'min-width': '194px'
        }
    }),
    containerCalendar: media(width, height, 'w', {
        768: { 'min-width': '498px' },
        0: { 'min-width': '320px' }
    }),
    mobileCustomContainerCalendar: {
        display: 'flex',
        'flex-direction': 'column-reverse'
    },
    textBetweenInput: media(width, height, 'w', {
        375: { margin: '0 8px' },
        0: { display: 'none' }
    }),
    customDatePickerBackdrop: media(width, height, 'w', {
        768: {
            position: 'fixed',
            'z-index': 9999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        },
        0: {
            position: 'fixed',
            'z-index': 9999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: variable.blackHalfTransparentColor
        }
    }),
    customDatePicker: media(width, height, 'w', {
        768: {
            'max-height': '100vh',
            'overflow': 'auto',
            position: 'absolute',
            'z-index': 9999,
            filter: `drop-shadow(0px 10px 100px ${variable.blackTransparentColor})`
        },
        0: {
            position: 'fixed',
            'z-index': 9999,
            filter: `drop-shadow(0px 10px 100px ${variable.blackTransparentColor})`,
            top: '50% !important',
            left: '50% !important',
            transform: 'translate(-50%, -50%)'
        }
    }),
    customDatePickerRange: media(width, height, 'w', {
        768: {
            'max-height': '100vh',
            'overflow': 'auto',
            position: 'absolute',
            'z-index': 9999,
            filter: `drop-shadow(0px 10px 100px ${variable.blackTransparentColor})`
        },
        0: { position: 'relative' }
    }),
    containerDatePicker: media(width, height, 'w', {
        768: {},
        0: {
            position: 'fixed',
            'background': variable.whiteColor,
            bottom: '0',
            'z-index': 9999,
            left: '0',
            width: '100%',
            'border-radius': '20px 20px 0 0',
            'max-height': '100vh',
            overflow: 'auto'
        }
    }),
    mainContainerDatePicker: media(width, height, 'w', {
        768: { backgroundColor: variable.blackTransparentGraceColor },
        0: {
            backgroundColor: variable.blackTransparentGraceColor,
            flex: 1
        }
    }),
    containerDatePickerDecor: media(width, height, 'w', {
        768: { display: 'none' },
        0: {
            background: variable.greyExtraLightColor,
            'border-radius': '100px',
            'margin-bottom': '20px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            width: '32px',
            height: '4px'
        }
    }),
    mobileInputText: { width: '31vw' },
    mobileIconWrap: {
        position: 'absolute',
        top: '24%',
        right: '6%'
    },
    mobileIcon: {
        width: '24px',
        height: '24px',
        fill: variable.greyExtraDarkColor
    },
    title: { 'margin': '34px auto 0' },
    titleText: {
        'font-size': '18px',
        'line-height': '21px',
        'text-align': 'center'
    },
    closeModal: {
        width: '32px',
        height: '4px',
        position: 'absolute',
        top: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        'background-color': variable.greyLightColor
    },
    customPickerTitle: {
        'font-size': '18px',
        'line-height': '21px',
        'text-align': 'center',
        'font-weight': '500',
        'margin-bottom': '20px'
    },
    buttonMobile: {
        height: '60px',
        'border-radius': '12px',
        width: '172px',
        'font-size': '16px',
        ...uikit.medium
    },
    buttonMobileOk: {
        'background-color': variable.orangeColor,
        color: variable.whiteColor
    },
    buttonMobileCancel: {
        'background-color': variable.greyExtraLightColor,
        color: variable.darkColor,
        'margin-right': '0.5rem'
    }
});
export default stylesheets;