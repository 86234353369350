import { components } from 'react-select';
import T from '../../../../../common/components/T';

const getMultiValueLabel = (multiple, val, selectedLimit) => {
    const MultiValueLabel = (props) => {
        if (multiple && val?.length > selectedLimit){
            return (
                <components.MultiValueLabel {...props} >
                    <T textName='selected' defaultStr='Выбрано' page='all'/>: {val.length}
                </components.MultiValueLabel>
            )
        }
        return (
            <components.MultiValueLabel {...props} />
        )
    };
    return MultiValueLabel
}
export default getMultiValueLabel