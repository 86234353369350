import { useState, useContext } from 'react'
import { svgSprite } from './../../../../hybrid/wrappers';
import { getData } from './../../../../common/utils/router';
import UserContext from './../../../../common/utils/getContext'
const GridTheadSort = ({
    styles,
    setGridData,
    convertData,
    data,
    thead
}) => {
    const { userToken, lang } = useContext(UserContext)
    const [sortDown, setSortDown] = useState(true)
    const [hover, setHover] = useState(false)

    const handleSort = async () => {
        setSortDown(!sortDown)
        const resData = await getData(
            `${data.sort.url}?sort=${sortDown ? '' : '-'}${data.name}`, userToken, lang, null, null, null, data?.sort?.storeAlias
        )
        if (resData?.data){
            setGridData({ thead: { data: thead }, tbody: convertData(resData) })
        }
    }
    return (
        svgSprite('sort-up', {
            onClick: handleSort,
            onMouseEnter: () => setHover(true),
            onMouseLeave: () => setHover(false),
            style: sortDown ? {
                ...styles.sortIconSvg,
                ...hover ? styles.sortIconSvgHover : {}
            } : {
                ...styles.sortIconSvg,
                ...styles.sortIconSvgUp,
                ...hover ? styles.sortIconSvgHover : {}
            }
        })
    )
}
export default GridTheadSort