// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P, Div } from '../../../hybrid/wrappers';

const Date = ({
    getHandlers, gridStyles, name, val, styles
}) =>
    <Div
        {...getHandlers(false)}
        styles={gridStyles[name]?.wrapper?.styles || {}}
        effects={gridStyles[name]?.wrapper?.effects || {}}
    >
        <P
            styles={gridStyles[name]?.p?.styles || { color: styles.variable.darkColor }}
        >
            {
                Object.keys(val).map((item, idx) =>
                    <React.Fragment key={`date${idx}`}>
                        {val[item]}
                        {idx === 0}
                    </React.Fragment>)
            }
        </P>
    </Div>;
Date.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    name: PropTypes.string,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ])
};

export default Date;