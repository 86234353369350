import { media } from '../../../common/utils/utils';
import officeStyles from './office/plug-styles'
import mainStyles from './main/plug-styles'
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,

    mainBlock: media(width, height, 'w', {
        768: {
            width: '100%',
            height: '500px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center'
        },
        0: {
            width: '100%',
            height: '300px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center'
        }
    }),
    mainBlockPanel: {
        'border-radius': '20px',
        'background-color': variable.whiteColor,
        'padding': '24px'
    },
    index: {
        display: 'flex',
        position: 'relative',
        'align-items': 'center',
        'justify-content': 'center',
        filter: 'url("#goo")'
    },
    chartPlugWrap: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'flex-direction': 'column',
        'min-height': '274px',
        height: '384px',
        width: '100%'
    },
    chartPlugTitle: {
        'font-weight': '500',
        ...uikit.title,
        color: variable.darkColor,
        'margin-top': '8px'
    },
    gridPlugWrap: {
        background: variable.greyTransparentHalfColor,
        'border-radius': '8px'
    },
    gridPlugText: {
        color: variable.darkColor,
        padding: '16px',
        ...uikit.text
    },
    skeleton_image: {
        height: '15rem',
        'background': variable.greySkeletonLoader,
        ...uikit.mb2
    },
    skeleton_text: {
        'background': variable.greySkeletonLoader,
        height: '20px',
        'border-radius': '4px',
        ...uikit.mb2
    },
    skeleton_small: {
        'background': variable.greySkeletonLoader,
        height: '16px',
        'border-radius': '4px',
        ...uikit.mb2
    },
    skeleton_circle: {
        'background': variable.greySkeletonLoader,
        height: '2rem',
        width: '2rem',
        'border-radius': '50%',
        ...uikit.mb2
    },
    skeleton_blockXL: {
        'background': variable.greySkeletonLoader,
        height: '96px',
        'border-radius': '4px',
        ...uikit.mb3
    },
    skeleton_blockLG: {
        'background': variable.greySkeletonLoader,
        height: '68px',
        'border-radius': '8px',
        ...uikit.mb2
    },
    skeleton_blockMD: {
        'background': variable.greySkeletonLoader,
        height: '54px',
        'border-radius': '8px',
        ...uikit.mb2
    },
    skeleton_blockSM: {
        'background': variable.greySkeletonLoader,
        height: '40px',
        'border-radius': '8px',
        ...uikit.mb2
    },
    skeleton_title: {
        'background': variable.greySkeletonButton,
        height: '32px',
        'border-radius': '4px',
        ...uikit.mb3
    },
    skeleton_subtitle: {
        'background': variable.greySkeletonButton,
        height: '24px',
        'border-radius': '4px',
        ...uikit.mb1
    },
    skeleton_empty: { height: '20px' },
    skeleton_dark_bg: { 'background': variable.greyExtraDarkMHalfTransparentColor },
    skeleton_line: {
        'background': variable.greySkeletonLoader,
        height: '1px',
        width: '100%',
        'border-radius': '4px',
        ...uikit.mb2,
        ...uikit.mt2
    },
    skeleton_icon: media(width, height, 'w', {
        768: {
            'background': variable.greySkeletonLoader,
            'width': '34px',
            height: '34px',
            'border-radius': '8px',
            ...uikit.mb2
        },
        0: {
            'background': variable.greySkeletonLoader,
            'width': '18px',
            height: '18px',
            'border-radius': '4px',
            ...uikit.mb2
        }
    }),
    skeleton_button: {
        'background': variable.greySkeletonButton,
        'max-width': '187px',
        height: '48px',
        'border-radius': '12px',
        ...uikit.mt4,
        'margin-left': 'auto',
        'margin-right': 'auto'
    },
    skeleton_table_wrapper: {
        'overflow': 'auto',
        width: 'calc(100% + 1rem)'
    },
    skeleton_table_header: {
        'background': variable.greySkeletonLoader,
        height: '56px',
        'border-radius': '14px',
        ...uikit.mb3,
        width: 'calc(100% - 1rem)'
    },
    skeleton_mode_light: { 'background': variable.greySkeletonLoader },
    skeleton_mode_dark: { 'background': variable.greySkeletonButton },
    shiner: { 'background-image': `linear-gradient(90deg, transparent 0px, ${variable.calcColor('white', '.4')} 40px, transparent 80px)` },
    shiner_dark: { 'background-image': `linear-gradient(90deg, transparent 0px, ${variable.calcColor('greyExtraDark', '.4')} 40px, transparent 80px)` }
});
export default stylesheets;