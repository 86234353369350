import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Div, svgSprite } from '../wrappers';
import uikit from '../../project/uikit/styles';
import { UserProfileContext } from '../../common/utils/getContext';
import {
    admin, main, office
} from '../../project/uikit/color-variables';

const AlertElement = ({
    children,
    type,
    styles,
    setShowAlert,
    compStyles
}) => {
    const { adminPage, officePage } = useContext(UserProfileContext) || {};

    const variables = adminPage ? admin : officePage ? office : main;

    let img = null;
    let innerStyles = {
        alertWrapper: {
            'background-color': variables.whiteColor,
            'border-radius': '8px',
            padding: '16px 22px',
            display: 'flex',
            'flex-direction': 'row'
        },
        alertImgBlock: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'margin-right': '18px',
            ...compStyles?.alertImgBlock
        },
        alertText: {
            ...uikit.text,
            color: variables.darkColor,
            'padding-right': '24px',
            flex: '1'
        }
    };

    switch (type) {
    case 'info':
        img = 'info-circle-fill';
        innerStyles = {
            ...innerStyles,
            alertWrapper: {
                ...innerStyles.alertWrapper,
                'border-left': `4px solid ${variables.blueColor}`,
                fill: variables.blueColor
            }
        };
        break;
    case 'success':
        img = 'check-circle';
        innerStyles = {
            ...innerStyles,
            alertWrapper: {
                ...innerStyles.alertWrapper,
                'border-left': `4px solid ${variables.greenDeepColor}`,
                fill: variables.greenDeepColor
            }
        };
        break;
    case 'warning':
        img = 'warning-triangle-fill';
        innerStyles = {
            ...innerStyles,
            alertWrapper: {
                ...innerStyles.alertWrapper,
                'border-left': `4px solid ${variables.orangeDeepColor}`,
                fill: variables.orangeColor
            }
        };
        break;
    case 'error':
        img = 'warning-circle-fill';
        innerStyles = {
            ...innerStyles,
            alertWrapper: {
                ...innerStyles.alertWrapper,
                'border-left': `4px solid ${variables.redColor}`,
                fill: variables.redColor
            }
        };
        break;
    }

    return (
        <Div styles={{ ...innerStyles.alertWrapper, ...styles }}>
            <Div styles={innerStyles.alertImgBlock}>
                {svgSprite(img, {
                    width: '20px',
                    height: '20px',
                    ...compStyles?.alertImgBlockSvg
                })}
            </Div>
            <Div styles={innerStyles.alertText}>
                {children}
            </Div>
            {
                setShowAlert ?
                    <Div
                        data-uitest='3_uitest' onClick={() => setShowAlert(false)}
                        styles={{ 'cursor': 'pointer' }}
                        effects={{ hover: { 'opacity': '.8' } }}
                    >
                        {svgSprite('cross', {
                            width: '9px',
                            height: '9px',
                            fill: variables.greyExtraDarkColor
                        })}
                    </Div> : null
            }

        </Div>
    );
};

AlertElement.propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
    styles: PropTypes.object,
    setShowAlert: PropTypes.func
};

export default AlertElement;