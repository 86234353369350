import { media } from '../../../common/utils/utils';
import officeStyles from './office/qr-styles'
import mainStyles from './main/qr-styles'
import uikit from '../../uikit/styles';


const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,
    modalQrWrapper: media(width, height, 'w', {
        768: {
            width: '480px',
            'max-width': '100%',
            background: variable.whiteColor,
            'border-radius': '20px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            position: 'relative',
            display: 'flex',
            'flex-direction': 'column'
        },
        0: {
            width: '480px',
            'max-width': '100%',
            background: variable.whiteColor,
            'border-radius': '20px 20px 0 0',
            'margin-left': 'auto',
            'margin-right': 'auto',
            position: 'relative',
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'flex-end',
            'margin-top': 'auto'
        }
    }),
    modalQrHeader: {
        'padding-top': '32px',
        'padding-left': '52px',
        'padding-right': '52px'
    },
    modalQrTitle: {
        color: variable.darkColor,
        ...uikit.title,
        'text-align': 'center',
        'font-weight': '500'
    },
    modalQrSub: {
        'margin-bottom': '16px',
        'text-align': 'center'
    },
    modalQrClose: {
        'cursor': 'pointer',
        position: 'absolute',
        top: '24px',
        right: '30px'
    },
    modalQrCloseSvg: {
        fill: variable.greyExtraDarkColor,
        width: '12px',
        height: '12px'
    },
    modalQrBody: {
        'padding-top': '24px',
        'padding-bottom': '32px',
        'padding-left': '52px',
        'padding-right': '52px'
    },
    qrCod: {
        background: variable.greyExtraDarkColorTransparent,
        width: '290px',
        height: '290px',
        'border-radius': '14px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        padding: '16px'
    },
    qrCodDecor: media(width, height, 'w', {
        768: { display: 'none' },
        0: {
            background: variable.greyExtraLightColor,
            'border-radius': '100px',
            'margin-bottom': '20px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            width: '32px',
            height: '4px'
        }
    })
});
export default stylesheets;