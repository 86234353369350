import React, {
    createContext, useCallback, useState
} from 'react';
import { getLocalStorageItem } from '../utils/utils';
import { zendoStorage } from '../../hybrid/wrappers';


export const TooltipsContext = createContext();

const Tooltips = ({ children }) => {
    const [tooltipFlags, setTooltipFlags] = useState({ finPassTooltip: getLocalStorageItem('finPassTooltip', true, true, true) })

    const handleSetTooltipFlag = useCallback((key, value, needToAddToLs) => {
        if (needToAddToLs) {
            zendoStorage.set(key, value)
        }
        setTooltipFlags(prev => ({ ...prev, [key]: value }))
    }, [setTooltipFlags])

    return (
        <TooltipsContext.Provider value={{ tooltipFlags, handleSetTooltipFlag }}>
            {children}
        </TooltipsContext.Provider>
    );
};

export default Tooltips;