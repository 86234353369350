// @generated: @expo/next-adapter@2.1.5
import React, {
    useMemo, useRef, useEffect, useCallback
} from 'react';
import { Table, Div } from '../../../hybrid/wrappers';
import GridThead from './GridThead';
import GridTbody from './GridTbody';
import { useWindowScrollDimensions } from '../../../common/utils/windowDimensions';
import { StickyTable, Row } from 'react-sticky-table';


const GridTable = ({
    styles,
    gridName,
    setGridData,
    cellThAddButton,
    viewThead = { data: [] },
    viewData,
    cellWidthArray,
    tableData = { data: [] },
    openSwitcher,
    handlers = {},
    variant,
    gridStyles,
    showThead,
    convertData,
    sticky,
    fullScreen,
    refTable,
    refTableChild,
    translation,
    checkAdmin,
    changePosition,
    draggable
}) => {
    const thead = useMemo(() => viewThead?.data, [viewThead]);

    const widthArr = useMemo(() => {
        if (cellWidthArray) {
            return thead.map((data, idx) => {
                if (!cellWidthArray[idx]){ return {} }
                return {
                    width: `${cellWidthArray[idx]}px`,
                    'max-width': `${cellWidthArray[idx]}px`
                };
            });
        }
        return [cellWidthArray];
    }, [cellWidthArray, thead]);
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || typeof safari !== 'undefined');

    let Firefox = /^((?!chrome|android).)*Firefox/i.test(navigator.userAgent);

    const rowRef = useRef();
    const tableRef = useRef();
    const func = useCallback(() => {
        if (!!tableRef.current) {
            let scrollOffsetTop = tableRef.current.getBoundingClientRect().top
            if (isSafari && rowRef.current.childNodes){
                if (scrollOffsetTop < 0) {
                    [].slice.call(rowRef.current.childNodes).forEach((el) => el.style.transform = `translateY(${scrollOffsetTop * -1}px)`)
                } else {
                    [].slice.call(rowRef.current.childNodes).forEach((el) => el.style.transform = 'translateY(0px)')
                }
            } else {
                if (scrollOffsetTop < 0) {
                    rowRef.current.style.transform = `translateY(${scrollOffsetTop * -1}px)`
                    rowRef.current.style.position = 'relative';
                    rowRef.current.style.zIndex = 11
                } else {
                    rowRef.current.style.transform = 'translateY(0px)'
                    rowRef.current.style.position = 'relative'
                    rowRef.current.style.zIndex = 11
                }
            }
        }
    }, [isSafari]);
    useEffect(() => {
        if (sticky && !sticky.stickyHeaderCount){
            useWindowScrollDimensions(func)
            return () => {
                useWindowScrollDimensions(func, true)
            }
        }
    }, [func, sticky]);
    const theadProps = {
        styles, gridName, cellThAddButton, thead, gridStyles, setGridData, convertData, rowRef, sticky, widthArr
    }
    const tbodyProps = {
        styles, thead, gridStyles, setGridData, tableData, widthArr, variant, openSwitcher, handlers, viewData, fullScreen, sticky, refTable, gridName, refTableChild, translation, checkAdmin, changePosition, draggable
    }
    if (sticky) {
        return (
            <Div className={sticky?.leftStickyColumnCount
                ? Firefox ? 'sticky-table-column-count Firefox' : 'sticky-table-column-count '
                : Firefox ? 'sticky-table-no-column-count Firefox' : 'sticky-table-no-column-count'
            }>
                <Row ref={tableRef}/>
                <StickyTable
                    leftStickyColumnCount={sticky?.leftStickyColumnCount || 0}
                    borderWidth={sticky?.borderWidth}
                    stickyHeaderCount={sticky?.stickyHeaderCount || 1}
                    headerZ={1}
                >
                    <GridThead
                        {...theadProps}
                    />

                    <GridTbody
                        {...tbodyProps}
                    />
                </StickyTable>
            </Div>
        )
    }
    return (
        <Table styles={gridStyles?.tableWrapper
            ? { ...styles.grid, ...gridStyles?.tableWrapper.table }
            : styles.grid}
        >
            {showThead &&
                <GridThead
                    {...theadProps}
                />
            }

            <GridTbody
                {...tbodyProps}
            />
        </Table>
    );
};

export default GridTable;