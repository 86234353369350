// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import {
    Div, P, svgSprite
} from '../../../hybrid/wrappers';
import T from '../../../common/components/T';

import Styles from '../../../common/decorators/Styles';
import getStyles from '../../../project/styles/widget-styles/form-styles';

const SingleButton = (props) => {
    const {
        clickFunc, buttonText, type, customButton, icon, arrow, customStyles
    } = props;

    const styles = { ...customStyles, ...props.styles };

    return (
        <Div styles={{
            ...styles.formGroupButtonItem,
            ...customButton?.singleButton ? customButton.singleButton : {}
        }}
        >
            <Div
                styles={{
                    ...type === 'ok'
                        ? { ...styles.formButtonPrimary }
                        : { ...styles.buttonGray },
                    ...customButton?.butWrapper
                }}
                effects={{
                    hover: {
                        ...type === 'ok' ? styles.formButtonPrimaryHover : styles.buttonGrayHover,
                        ...customButton && customButton.butHover
                            ? customButton.butHover : {}

                    },
                    focus: {
                        ...type === 'ok' ? styles.formButtonPrimaryFocus : styles.buttonGrayFocus,
                        ...customButton && customButton.butFocus
                            ? customButton.butFocus : {}

                    },
                    active: {
                        ...type === 'ok' ? styles.formButtonPrimaryFocus : styles.buttonGrayFocus,
                        ...customButton && customButton.butActive
                            ? customButton.butActive : {}

                    }
                }}
                data-uitest={type + '_610_uitest'} onClick={clickFunc}
            >
                {
                    icon &&
                        <Div styles={{ height: '18px', ...customButton?.spriteWrapper }}>
                            {
                                svgSprite(icon, {
                                    style: {
                                        width: '18px',
                                        height: '18px',
                                        fill: styles.variable.whiteColor,
                                        ...customButton?.sprite
                                    }
                                })
                            }
                        </Div>

                }

                <P styles={customButton && customButton.butText ? customButton.butText : {}}>
                    {buttonText || <T textName='cancel' defaultStr='Отмена' page='all'/>}
                </P>
                { arrow &&
                    <Div styles={{
                        'margin-left': '20px',
                        'border-left': `1px solid ${styles.variable.whiteLessTransparentColor}`,
                        'padding': '0 17px',
                        height: '60px',
                        ...customButton?.arrowWrapper

                    }}>
                        {
                            svgSprite('angle-down', { style: customButton?.buttonArrow })}
                    </Div>
                }
            </Div>
        </Div>
    );
};
SingleButton.propTypes = {
    styles: PropTypes.object,
    clickFunc: PropTypes.func,
    buttonText: PropTypes.node,
    type: PropTypes.string,
    customButton: PropTypes.object,
    icon: PropTypes.string,
    arrow: PropTypes.bool,
    customStyles: PropTypes.object
};
export default Styles(SingleButton, getStyles);