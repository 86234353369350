// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useMemo, useContext, useEffect
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { Div } from '../../../../hybrid/wrappers';
import UserContext from '../../../../common/utils/getContext';
import Switcher from './Switcher';
import InputLogin from '../form-input/FormInput';
import { getWidgetTranslates } from '../../../../common/utils/utils';
import { LangContext } from '../../../../common/decorators/Language';

const FormLoginWidget = (props) => {
    const {
        attribute,
        switchHint,
        disabled,
        title,
        value,
        setFormData,
        styles
    } = props;

    const [inp, setInp] = useState({ val: value || null, showInputPhone: true });
    const { refer, lang } = useContext(UserContext);
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    const [transl, setTranslates] = useState(null)
    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);

    const refData = useMemo(() => {
        if (refer && refer !== 'false') return refer;
        return false;
    }, [refer]);

    useEffect(() => {
        if (refData) setFormData(attribute, refData);
    }, [attribute, refData, setFormData]);

    return (
        <Div>
            {/*!refData &&*/}
            <Switcher
                switchHint={switchHint}
                inp={inp}
                setInp={setInp}
                styles={styles}
                title={title}
                disabled={disabled}
                setFormData={setFormData}
                attribute={attribute}
                translation={transl}
            />


            <Div>
                {!!inp.showInputPhone
                    ? <InputLogin
                        {...props}
                        value={value || refData}
                        title={title || false}
                        type={'phone-widget'}
                    />
                    : <InputLogin
                        {...props}
                        value={value || refData}
                        title={title || false}
                        type={'input'}
                    />
                }
            </Div>
        </Div>
    );
};

FormLoginWidget.propTypes = {
    attribute: PropTypes.string,
    switchHint: PropTypes.node,
    disabled: PropTypes.bool,
    title: PropTypes.object,
    value: PropTypes.string,
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    variation: PropTypes.string
};

export default Styles(FormLoginWidget, getStyles);