import { media } from '../../../common/utils/utils';
import { main } from '../../uikit/color-variables';
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variables = {}) => {
    const variable = Object.keys(variables)?.length === 0 ? main : variables
    return {
        alertWrapper: media(width, height, 'w', {
            768: {
                'border-radius': '8px',
                'box-shadow': `0px 14px 20px ${variable.blackTransparentColor}`,
                position: 'fixed',
                'z-index': 99999,
                top: '16px',
                right: '16px',
                display: 'flex',
                padding: '16px',
                'align-items': 'flex-start',
                // width: 'min-content',
                'min-width': '270px',
                'max-width': '500px'
            },
            0: {
                'border-radius': '8px',
                'box-shadow': `0px 14px 20px ${variable.blackTransparentColor}`,
                position: 'fixed',
                'z-index': 99999,
                top: '8px',
                right: '8px',
                left: '8px',
                display: 'flex',
                padding: '16px',
                'align-items': 'flex-start'
            }
        }),
        alertWrapperNative: {
            'border-radius': '8px',
            'z-index': 9999,
            display: 'flex',
            'flex-direction': 'row',
            padding: '24px',
            'align-items': 'center',
            marginTop: 26,
            position: 'absolute',
            width: '100%',
            'max-width': 'calc(100% - 48px)'

        },
        bg_success: { background: variable.greenDeepColor },
        bg_info: { background: variable.blueColor },
        bg_error: { background: variable.redColor },
        bg_warning: { background: variable.orangeColor },
        alertIconBlock: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'margin-right': '14px',
            width: '24px',
            height: '24px',
            'flex-shrink': 0
        },
        alertIcon: media(width, height, 'w', {
            768: {
                width: '23.3px',
                height: '23.3px',
                fill: variable.whiteColor
            },
            0: {
                width: '20px',
                height: '20px',
                fill: variable.whiteColor
            }
        }),

        alertTextWrapper: {
            'display': 'flex',
            'flex-direction': 'column'
        },

        alertTitle: {
            ...uikit.text,
            'line-height': '1.25',
            color: variable.whiteColor,
            'margin-bottom': '8px',
            'font-weight': '500'
        },
        alertText: {
            color: variable.whiteColor,
            'font-weight': '500'
        },

        alertModalWrapper: {
            position: 'relative',
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center',
            'border-radius': '20px',
            background: variable.whiteColor,
            padding: '32px',
            width: '576px'
        },

        alertModalIconBlock: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'margin-bottom': '32px',
            width: '80px',
            height: '80px',
            'flex-shrink': 0
        },

        alertModalIcon: {
            width: '80px',
            height: '80px'
        },

        alertModalIcon_info: { fill: variable.blueColor },
        alertModalIcon_warning: { fill: variable.orangeColor },
        alertModalIcon_error: { fill: variable.redColor },
        alertModalIcon_success: { fill: variable.greenColor },

        alertModalTitle: {
            display: 'flex',
            color: variable.darkColor,
            'font-weight': 500,
            ...uikit.title,
            'margin-bottom': '16px'
        },

        alertModalText: {
            display: 'flex',
            color: variable.darkColor,
            'font-weight': 400,
            ...uikit.text,
            'text-align': 'center',
            'margin-bottom': '32px'
        },

        alertModalCloseX: {
            position: 'absolute',
            right: '24px',
            top: '24px'
        },

        cursorPointer: { cursor: 'pointer' },

        buttonPadding: { padding: '0 32px' },

        buttonGray: {
            ...uikit.button,
            'background-color': variable.greyExtraLightColor,
            color: variable.darkColor
        },
        buttonGrayHover: { 'background-color': variable.greyColor },
        buttonGrayFocus: { 'background-color': variable.greyLightColor }
    }
};
export default stylesheets;