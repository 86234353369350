// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useCallback, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {
    Div, FieldError, P, svgSprite, Tooltip
} from '../../../../hybrid/wrappers';
import Title from '../Title';
import ConfirmModal from '../../../../widgets/deleteModal/DeleteModal';
import { t } from '../../../../common/components/T'
import { LangContext } from '../../../../common/decorators/Language';

const FormCheckbox = ({
    attribute,
    hint,
    disabled,
    title,
    handlers = {},
    compStyle,
    value,
    setFormData = () => {},
    styles,
    errors,
    reload,
    confirm,
    customStyle,
    htmlInfo
}) => {
    const [val, setVal] = useState(value || '0');
    const [showConfirm, setShowConfirm] = useState(false);
    const { translation } = useContext(LangContext) || {};


    const changeHandler = useCallback(() => {
        if (!disabled) {
            setFormData(attribute, val === '0' ? '1' : '0');
            setVal(val === '0' ? '1' : '0');

            if (handlers?.onChange) handlers.onChange(attribute, val === '0' ? '1' : '0')
        }
        setShowConfirm(false);
    }, [disabled, setFormData, attribute, val, handlers]);


    useEffect(() => {
        setVal(value)
    }, [value]);

    useEffect(() => {
        setVal(value || '0')
    }, [reload, value])

    return (
        <Div styles={{
            ...styles?.formCheckboxWrapper, ...compStyle?.wrapper, ...customStyle?.wrapper
        }}>
            <Div
                data-uitest='584_uitest' onClick={val === '0' && confirm && !disabled ? () => { setShowConfirm(true) } : changeHandler}
                styles={{
                    ...styles.formCheckboxBlock, ...disabled ? styles.formCheckboxBlockDisabled : {}, ...compStyle?.label
                }}>
                <Div styles={
                    val === '1'
                        ? {
                            ...styles.formCheckbox,
                            ...styles.formCheckboxActive,
                            ...compStyle?.checkBox
                        }
                        : { ...styles.formCheckbox, ...compStyle?.checkBox }}>
                    {
                        val === '1'
                            && svgSprite('check', { fill: styles.variable.whiteColor, style: styles.formCheckboxSvg })

                    }
                </Div>
                {
                    !!title && <Title
                        Comp={P} body={title.body}
                        links={title.links}

                    />
                }
                {hint && <Tooltip
                    text={hint}
                    styles={styles.formInfoTooltip}
                    className={title.hintClassName || ''}
                >
                    {svgSprite('info-circle', { style: styles.formInfoIcon })}
                </Tooltip>}


            </Div>
            {
                htmlInfo ? <Div styles={styles.backgroundhtmlInfo}>
                    {htmlInfo}
                </Div> : null
            }
            {
                errors && errors.length ? errors.map((err, idx) => <><FieldError styles={{
                    ...styles, ...compStyle?.error || {}, ...customStyle?.error
                }} key={`${attribute}${idx}`}>{err}</FieldError></>) : null
            }

            {
                confirm &&
                  <ConfirmModal
                      customStyles={{
                          singleButton: { 'flex-grow': 1 },
                          modalWrapper: { 'width': '576px' },
                          modalBodyText: { 'max-width': 'none' }
                      }}
                      showModal={showConfirm}
                      setShowModal={setShowConfirm}
                      typeModal={false}
                      title={t('confirmAction', 'Подтвердите действие', 'all', translation)}
                      text={confirm}
                      modalFooterShow
                      buttons={[
                          {
                              type: 'cancel',
                              buttonText: t('cancel', 'Отмена', 'all', translation),
                              clickFunc: () => setShowConfirm(false)
                          },
                          {
                              type: 'ok',
                              buttonText: t('confirm', 'Подтвердить', 'all', translation),
                              clickFunc: changeHandler
                          }
                      ]}
                  />

            }

        </Div>
    );
};

FormCheckbox.propTypes = {
    attribute: PropTypes.string,
    hint: PropTypes.node,
    disabled: PropTypes.bool,
    title: PropTypes.object,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.string,
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    errors: PropTypes.array,
    reload: PropTypes.bool,
    confirm: PropTypes.node
};

export default Styles(FormCheckbox, getStyles);