import { media } from '../../../common/utils/utils';
import officeStyles from './office/modal-styles'
import mainStyles from './main/modal-styles'
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,

    userModalInfoContainerFixed: media(width, height, 'w', {
        576: {
            'max-height': `${height}px`,
            'max-width': '100%',
            position: 'fixed',
            top: 0,
            right: 0,
            'padding-left': '25px',
            'z-index': 9999
        },
        0: {
            'max-height': `${height}px`,
            'max-width': '100%',
            position: 'fixed',
            'z-index': 9999
        }
    }),
    userModalInfoContainer: media(width, height, 'w', {
        576: {
            'max-height': `${height}px`,
            'max-width': '100%',
            position: 'relative',
            'padding-left': '25px'
        },
        0: {
            'max-height': `${height}px`,
            'max-width': '100%',
            position: 'relative'
        }
    }),
    hr: media(width, height, 'w', {
        576: {
            opacity: '0.12',
            'border-style': 'solid',
            'border-bottom-width': '1px',
            'border-color': variable.blueDarkColor,
            margin: '15px -28px'
        },
        0: {
            opacity: '0.12',
            'border-style': 'solid',
            'border-bottom-width': '1px',
            'border-color': variable.blueDarkColor,
            margin: '15px -12px'
        }
    }),
    hrNative: {
        opacity: '0.12',
        'border-bottom-width': '1px',
        'border-color': variable.blueDarkColor,
        'border-style': 'solid',
        margin: '15px -28px'
    },
    userModalInfo: {
        height: '100%'
        // overflow: 'auto',
    },
    userModalInfoWrap: {
        // position: 'relative',
        // 'z-index': 99,
        // right: 0,
        // top: 0,
        // bottom:0
        // 'max-height': '100%'
        height: `${height}px`
    },
    userModalInfoWrapNative: {
        // position: 'relative',
        // 'z-index': 99,
        // right: 0,
        // top: 0,
        // bottom:0
        'min-height': '100%'
    },
    userModalInfoBlock: media(width, height, 'w', {
        576: {
            width: '444px',
            'max-width': `${width - 25}px`,
            height: `${height}px`,
            padding: '30px 32px',
            'background-color': variable.whiteColor,
            'border-radius': `${'30px'} 0px 0px ${'30px'}`
        },
        0: {
            width: '100%',
            'max-width': '100%',
            padding: '12px',
            height: `${height}px`,
            'background-color': variable.whiteColor,
            'border-radius': '20px 20px 0 0'
        }
    }),
    userPhotoWrapper: { padding: '10px' },
    userAvatarStyle: {
        width: '105px',
        height: '105px',
        'border-radius': '100%'
    },
    userModalInfoBlockNative: {
        display: 'flex',
        padding: '30px 32px 30px 32px',
        'background-color': variable.whiteColor,
        'border-radius': `${'30px'} 0px 0px ${'30px'}`
    },

    userModalInfoBg: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        'z-index': 99,
        background: variable.darkColor,
        opacity: 0.4
    },
    userModalHeader: {
        background: variable.greyExtraDarkTransparentColor,
        height: '40px',
        'border-radius': '14px',
        'text-align': 'center',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'font-weight': 500,
        'margin-bottom': '20px',
        color: variable.darkColor
    },
    userModalHeaderNative: {
        background: variable.greyExtraDarkTransparentColor,
        height: '40px',
        'border-radius': '14px',
        'text-align': 'center',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'margin-bottom': '20px',
        color: variable.darkColor
    },
    userModalHeaderTitleNative: {
        ...uikit.text,
        'font-weight': 500
    },
    userModalClose: {
        position: 'absolute',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        width: '48px',
        left: '0',
        top: '24px',
        height: '48px',
        background: variable.whiteColor,
        'box-shadow': `0px 6px 10px ${variable.blackExtraTransparentColor}`,
        'border-radius': '100%',
        cursor: 'pointer',
        'z-index': '1',
        fill: variable.darkColor
    },
    userModalCloseAngleRight: {
        width: '8px',
        height: '14px'
    },
    userModalCloseAngleRightNative: {
        fill: variable.darkColor,
        width: '20px',
        height: '14px'
    },
    userModalCloseHover: { fill: variable.blueColor },
    userModalCloseNative: {
        position: 'absolute',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        width: '48px',
        left: '-24px',
        top: '24px',
        height: '48px',
        background: variable.whiteColor,
        'box-shadow': `0px 6px 10px ${variable.blackExtraTransparentColor}`,
        'border-radius': '100px',
        'z-index': '1'
    },

    userModalTopWrap: { display: 'block' },

    userModalTopWrapNative: { display: 'flex' },
    userModalTop: {
        display: 'flex',
        'margin-bottom': '16px'
    },
    userModalTopInfo: {
        'margin-top': '10px',
        flex: 1,
        flexDirection: 'column'
    },
    userModalTopInfoNative: {
        'margin-top': '10px',
        marginLeft: 19,
        flex: 1,
        flexDirection: 'column'
    },
    faceBlock: {
        width: '122px',
        height: '122px',
        position: 'relative',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'margin-right': '16px'
    },

    userModalTopUsername: {
        display: 'flex',
        flexDirection: 'row',
        'align-items': 'center',
        'margin-bottom': '6px',
        fontSize: '14px'
    },
    userModalTopName: {
        'font-weight': 500,
        ...uikit.subtitle,
        'margin-bottom': '6px'
    },
    userModalTopUsernameSvg: {
        width: '16px',
        height: '16px',
        fill: variable.greenColor,
        marginRight: 8
    },
    userModalTopUsernameSvgRed: {
        width: '16px',
        height: '16px',
        fill: variable.redColor,
        marginRight: 8
    },
    userModalTopTitleNative: {
        ...uikit.small,
        'font-weight': 400
    },
    userModalTopNameNative: { 'margin-bottom': '8px' },
    userModalTopNameTextNative: {
        'font-weight': 500,
        ...uikit.subtitle
    },
    userModalTopStatus: {
        display: 'flex',
        ...uikit.small
    },
    userModalTopStatusNative: { flexDirection: 'row' },
    userModalTopStatusText: {
        color: variable.darkHalfTransparentColor,
        'margin-right': '4px'
    },
    userModalTopStatusTextType: { color: variable.darkColor },
    progressDefault: {
        position: 'absolute',
        width: '100%',
        height: 'auto',
        top: '-3px',
        transform: 'rotateZ(-130deg)'
    },
    progressBar: {
        position: 'absolute',
        width: '100%',
        height: 'auto',
        top: '-3px',
        transform: 'rotateZ(-130deg)'
    },
    progressDefaultNative: {
        position: 'absolute',
        width: '100%',
        height: 'auto',
        top: '0',
        justifyContent: 'center',
        alignItems: 'center'
    },
    progressBarNative: {
        position: 'absolute',
        width: '100%',
        height: 'auto',
        top: '0',
        justifyContent: 'center',
        alignItems: 'center'
    },

    userImgWrap: {
        width: '100px',
        height: '100px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'border-radius': '100%',
        'align-self': 'center'
    },
    userImgWrapNative: {
        top: 10,
        width: '100px',
        height: '100px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'border-radius': '100px'
    },
    userPositionNative: {
        background: variable.purpleColor,
        'border-radius': '16px',
        padding: '4px 7px',
        justifyContent: 'center',
        alignItems: 'center'
    },
    userPositionNativetext: {
        color: variable.whiteColor,
        ...uikit.small,
        'font-weight': 'bold'
    },
    userAvatarImg: {},
    userPosition: {
        background: variable.purpleGradient,
        'box-shadow': `0px 6px 30px ${variable.blackTransparentColor}`,
        'border-radius': '16px',
        'text-align': 'center',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        color: variable.whiteColor,
        ...uikit.small,
        'line-height': '1.14',
        padding: '4px 7px',
        position: 'absolute',
        'font-weight': 'bold',
        bottom: '0'
    },

    userModalChildrenBlock: {
        display: 'flex',
        margin: '0 -5px',
        'flex-wrap': 'wrap'
    },
    userModalChildrenBlockNative: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    userModalChildrenItem: {
        width: '50%',
        padding: '0 5px',
        'margin-bottom': '8px'
        // flex:1,
    },
    userModalChildrenItemBlock: {
        'border-radius': '14px',
        display: 'flex',
        'align-items': 'center',
        padding: '10px 12px'
    },
    userModalChildrenItemBlockNative: {
        'border-radius': '14px',
        padding: '10px 12px',
        flexDirection: 'row'
    },
    userModalChildrenNumberBlockNative: {
        'border-radius': '14px',
        padding: '10px 12px',
        flexDirection: 'column'
    },
    userModalChildrenNew: { background: variable.greenTransparentColor },
    userModalChildrenActive: { background: variable.blueTransparentColor },
    userModalChildrenAll: { background: variable.purpleTransparentColor },
    userModalChildrenIcon: {
        'margin-right': '10px',
        justifyContent: 'center'
    },
    userModalChildrenTitle: {
        'font-weight': 500,
        ...uikit.subtitle
    },
    userModalChildrenText: { 'font-size': '0.625rem' },
    userModalChildrenTextLarge: {
        ...uikit.tiny,
        color: variable.greySuperDarkColor
    },
    userModalRangItemNative: {
        display: 'flex',
        'align-items': 'center',
        flexDirection: 'row'
    },
    userModalRangBlockNative: {
        'align-items': 'center',
        'justify-content': 'center',
        flex: 1,
        flexDirection: 'row',
        background: variable.greyExtraDarkTransparentColor,
        'border-radius': '14px',
        padding: '12px 6px',
        'margin-bottom': '6px'
    },
    userModalRangBlock: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        background: variable.greyExtraDarkTransparentColor,
        'border-radius': '14px',
        padding: '12px 6px',
        'margin-bottom': '6px'
    },
    userModalRangItem: {
        display: 'flex',
        'align-items': 'center'
    },
    userModalRangIcon: {
        display: 'flex',
        'margin-right': '10px'
    },
    userModalRangValue: {
        'font-weight': 500,
        ...uikit.small
    },
    userModalRangArrow: { padding: '0 16px' },
    userModalRangImg: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        width: '34px',
        height: '34px',
        overflow: 'hidden',
        'border-radius': '50%'
    },

    // ----  Start SpeedProgressComponent  -----

    wrapSpeedProgress: media(width, height, 'w', {
        480: {
            display: 'flex',
            'justify-content': 'space-around',
            'flex-direction': 'row',
            'flex-wrap': 'wrap',
            'margin-bottom': '16px'
        },
        0: {
            display: 'flex',
            'justify-content': 'space-around'
        // 'flex-direction': 'column',
        }
    }),
    lineProgressTitleNative: {
        fontSize: 14,
        color: variable.blueDarkColor
    },
    lineProgressMinNative: {
        fontSize: 16,
        'font-weight': 500
    },
    lineProgressMaxNative: {
        fontSize: 16,
        'font-weight': 500
    },
    speedProgressComponent: {
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
        marginBottom: '20px'
    },
    officeWrapSpeedProgress: {
        width: '148px',
        height: '125px',
        position: 'relative',
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
        'margin-bottom': '16px'
    },
    officeSpeedProgressSvg: { position: 'absolute' },
    officeSpeedProgressPathSvg: {
        position: 'absolute'
        // 'top': '30px'
    },
    officeSpeedProgressPathSvgDone: {
        // 'top': '30px',

        flex: 1
    },
    officeSpeedProgressCircle: {
        position: 'absolute',
        top: '24px',
        width: '110px',
        height: '110px',
        'border-radius': '110px',
        transform: 'rotateZ(147deg)'
    },
    officeSpeedProgressCircleNative: {
        position: 'absolute',
        top: '30px',
        width: '140px',
        height: '140px',
        'border-radius': '140px'
    },
    transformWrap: {
        position: 'relative',
        display: 'flex',
        'border-color': variable.greenDeepColor,
        'border-width': '1px',
        'border-style': 'solid',
        height: '150px',
        'align-items': 'center',
        'justify-content': 'center'
    },
    transformRot: {
        position: 'absolute',
        'border-color': variable.redDeepColor,
        'border-width': '1px',
        'border-style': 'solid',
        width: 100,
        height: 100
    },
    markerSpeedProgress: {
        position: 'absolute',
        width: '20px',
        height: '20px'
    },
    arrowSpeedProgress: {
        position: 'absolute',
        width: '24px',
        height: '6px',
        left: '-70%'

    },
    markerSpeedProgressNative: {
        'border-color': 'transparent',
        'border-width': '2px',
        'border-style': 'solid',
        position: 'absolute',
        width: '20px',
        height: '20px'
    },
    arrowSpeedProgressNative: {
        position: 'absolute',
        width: '24px',
        height: '6px',
        left: '-105%'
        // 'background-color': variable.blackColor,

    },
    markerSpeedProgressArrowWrap: { position: 'relative' },
    startProgress: {
        position: 'absolute',
        bottom: '-10px',
        left: '9px',
        transform: 'rotateZ(-125deg)'
    },
    startProgressText: {
        color: variable.darkColor,
        'font-weight': 'bold',
        'font-size': '0.625rem'
    },
    endProgress: {
        position: 'absolute',
        bottom: '-10px',
        right: '-7px',
        transform: 'rotateZ(125deg)'

    },
    endProgressText: {
        color: variable.darkColor,
        'font-weight': 'bold',
        'font-size': '0.625rem'
    },
    infoSpeedProgress: {
        position: 'absolute',
        top: '57px',
        'text-align': 'center'
    },
    grayTitle: {
        opacity: '0.6',
        ...uikit.subtitle,
        'line-height': '1',
        'margin-bottom': '4px'
    },
    infoSpeedProgressValue: {
        ...uikit.subtitle,
        'font-weight': 500
    },
    infoSpeedProgressValueModal: {
        'font-size': '1.375rem',
        'font-weight': 500
    },
    infoSpeedProgressValueDone: { color: variable.greenColor },
    infoSpeedProgressTitleWrap: {
        display: 'flex',
        'flex-direction': 'row',
        'justify-content': 'center'
    },
    infoSpeedProgressTooltipIcon: {
        width: '12px',
        height: '12px',
        fill: variable.greyExtraDarkColor
    },
    infoSpeedProgressTitle: {
        display: 'flex',
        position: 'relative'
    },
    infoSpeedProgressBonusDone: { color: variable.greenColor },
    infoSpeedProgressPercent: {
        'border-radius': '12px',
        'background-color': variable.greyTransparentColor,
        padding: '6px 8px',
        ...uikit.tiny,
        'font-weight': 500
    },
    infoSpeedProgressPercentWrap: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'center'
    },
    quantitySpeedProgress: {
        color: variable.darkColor,
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center'
    },
    quantitySpeedProgressText: { opacity: '0.6' },
    speedProgressDone: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        color: variable.greenColor
    },
    speedProgressDoneSvg: {
        'margin-left': '6px',
        width: '20px',
        height: '20px',
        fill: variable.greenColor
    },
    // ----  End SpeedProgressComponent  -----

    //= ========================================================================================================
    //= ========================================================================================================
    //= ========================================================================================================
    // Start Line progressBar
    progressLineItem: {
        width: '50%',
        border: `2px solid ${variable.greyExtraDarkColorTransparent}`,
        'border-radius': '14px',
        padding: '16px',
        ...uikit.small
    },
    progressLineItemBody: {
        display: 'flex',
        'align-items': 'center',
        'padding-top': '12px'
    },
    progressLineItemBodyBar: { flex: 1 },
    progressLineItemBodySvg: { 'margin-right': '8px' },
    progressLineItemHeadTitle: {
        ...uikit.text,
        'font-weight': 500
    },
    progressLineItemHeadSubtitle: { color: variable.greySuperDarkColor },
    lineProgressWrap: {
        'border': `2px solid ${variable.greyExtraDarkTransparentColor}`,
        'box-sizing': 'border-box',
        'border-radius': '14px',
        padding: '12px'
    },
    lineProgressTitle: {
        ...uikit.small,
        'font-weight': '400',
        'margin-right': '8px'
    },
    rangIcon: {
        width: '28px',
        height: '28px',
        'border-radius': '100%',
        'background-color': variable.redDarkColor,
        'margin-right': '6px',
        'overflow': 'hidden'
    },
    progressBarPath: {
        height: '8px',
        'border-radius': '6px',
        'background-color': variable.blueTransparentColor,
        overflow: 'hidden'
    },
    progressBarPathDone: {
        'background-color': variable.blueColor,
        height: '8px'
    },
    progressBarCount: {
        display: 'flex',
        'flex-direction': 'row',
        'justify-content': 'space-between'
    },

    // End Line progressBar
    //= ========================================================================================================
    //= ========================================================================================================
    //= ========================================================================================================

    userModalVolumeItem: {
        display: 'flex',
        'align-items': 'center',
        'margin-bottom': '16px',
        flexDirection: 'row'
    },
    userModalVolumValueTitleNative: {
        'font-weight': 500,
        ...uikit.subtitle
    },
    userModalInfoIcon: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        width: '48px',
        height: '48px',
        'margin-right': '12px',
        'border-radius': '14px'
    },
    userModalVolumeValue: {
        'font-weight': 500,
        ...uikit.subtitle,
        'margin-bottom': '3px'
    },

    userModalVolumeTitle: {
        ...uikit.small,
        color: variable.darkColor,
        opacity: 0.6
    },
    package_null: {
        'background': variable.greyColor,
        color: variable.greyExtraDarkColor
    },
    package_1: { 'background': 'linear-gradient(90deg, #4C3C36 0%, #A9856F 100%)' },
    package_2: { 'background': 'linear-gradient(90deg, #353A43 0%, #8796B0 100%)' },
    package_3: { 'background': 'linear-gradient(90deg, #2D4439 0%, #5BA184 100%)' },
    package_4: { 'background': 'linear-gradient(90deg, #7A5931 0%, #E1A345 100%)' },
    package_5: { 'background': variable.blueSkyColor }
});
export default stylesheets;