import { Div } from '../../../../hybrid/wrappers';
import uikit from '../../../../project/uikit/styles';

const RenderElemet = ({
    element, styles, itemsShiners, darkMode
}) => {
    const type = typeof element === 'string' ? element : element?.type;
    const properties = typeof element === 'string' ? {} : element;

    const margins = properties?.gap || properties?.gap === 0 ? ['t', 'r', 'b', 'l'].reduce((acc, key, i) => {
        return {
            ...acc,
            ...properties?.gap === 0 ? uikit[`m${key}0`] : properties?.gap[i] ? uikit[`m${key}${properties?.gap[i]}`] : {}
        }
    }, {}) : {}
    const width = properties?.width ? { width: `${properties?.width}%` } : {}
    const align = properties?.align === 'center' ? { 'margin-left': 'auto', 'margin-right': 'auto' } : properties?.align === 'right' ? { 'margin-left': 'auto' } : {};
    const diameter = properties?.diameter ? { 'width': `${properties?.diameter}rem`, 'height': `${properties?.diameter}rem` } : {}

    if (type === 'table'){
        const colsTypes = [
            <Div key={'keyrgdfg'} styles={{ ...uikit.pr2, ...uikit.pl2 }}>
                <Div styles={{
                    ...darkMode ? styles.skeleton_dark_bg : {},
                    ...styles.skeleton_text, width: '178px', ...uikit.mb1
                }}/>
                <Div styles={{
                    ...darkMode ? styles.skeleton_dark_bg : {},
                    ...styles.skeleton_text, width: '107px', ...uikit.mb0
                }}/>
            </Div>,
            <Div key={'key2sdf'} styles={{
                ...darkMode ? styles.skeleton_dark_bg : {},
                ...styles.skeleton_text, width: '140px', ...uikit.mr2, ...uikit.ml2
            }}/>,
            <Div key={'key2sef'} styles={{
                ...darkMode ? styles.skeleton_dark_bg : {},
                ...styles.skeleton_text, width: '63px', ...uikit.mr2, ...uikit.ml2
            }}/>,
            <Div key={'key2sdfcdx'} styles={{
                ...darkMode ? styles.skeleton_dark_bg : {},
                ...styles.skeleton_text, width: '250px', ...uikit.mr2, ...uikit.ml2
            }}/>
        ]
        const getCols = (size) => {
            let array = []
            for (let index = 0;index < size;index++) {
                array.push(<>{colsTypes[index] || colsTypes[colsTypes?.length % index]}</>)
            }
            return array
        }
        const getRows = (size) => {
            let array = []
            for (let index = 0;index < size;index++) {
                array.push(<>
                    <Div key={index} styles={{
                        ...uikit.flexRow, ...uikit.alignItemsCenter, ...uikit.justifyContentBetween
                    }}>
                        {
                            getCols(properties?.size?.columns).map(item => item)
                        }
                    </Div>
                    <Div styles={{
                        ...darkMode ? styles.skeleton_dark_bg : {},
                        ...styles.skeleton_line,
                        width: 'calc(100% - 1rem)',
                        ...uikit.mb3, ...uikit.mt3, ...uikit.mr2, ...uikit.ml2
                    }}/>
                </>)
            }
            return array
        }
        return <Div styles={{
            'overflow': 'hidden',
            width: width?.width || '100%',
            ...align,
            ...margins
        }}>
            <Div styles={{
                ...styles['skeleton_table_wrapper'],
                ...uikit.mr2_minus, ...uikit.ml2_minus
            }}>
                <Div styles={{
                    ...styles['skeleton_table_header'], ...uikit.mr2, ...uikit.ml2
                }}/>
                {
                    getRows(properties?.size?.rows).map(item => item)
                }
            </Div>
        </Div>
    }
    if (type === 'chart'){
        const getLine = (size) => {
            let array = []
            for (let index = 0;index < size;index++) {
                array.push(<>
                    {index === 0 ?
                        <Div key={index} styles={{ ...uikit.flexColumn, ...uikit.justifyContentBetween }}>
                            <Div styles={{
                                ...darkMode ? styles.skeleton_dark_bg : {},
                                ...styles.skeleton_small, width: '1rem', height: '1rem'
                            }}/>
                            <Div styles={{
                                ...darkMode ? styles.skeleton_dark_bg : {},
                                ...styles.skeleton_small, width: '1rem', height: '1rem'
                            }}/>
                            <Div styles={{
                                ...darkMode ? styles.skeleton_dark_bg : {},
                                ...styles.skeleton_small, width: '1rem', height: '1rem'
                            }}/>
                            <Div styles={{
                                ...darkMode ? styles.skeleton_dark_bg : {},
                                ...styles.skeleton_small, width: '1rem', height: '1rem'
                            }}/>
                            <Div styles={{
                                ...darkMode ? styles.skeleton_dark_bg : {},
                                ...styles.skeleton_small, width: '1rem', height: '1rem', ...uikit.mr3
                            }}/>
                        </Div> :
                        <Div styles={{
                            ...darkMode ? styles.skeleton_dark_bg : {},
                            ...styles.skeleton_small, width: '1rem', 'min-width': '1rem', height: 'auto', ...uikit.mr3
                        }}/>
                    }
                </>)
            }
            return array
        }

        return <Div styles={{
            ...uikit.flexRow,
            ...width,
            ...align,
            ...margins,
            ...diameter,
            'height': '200px',
            overflow: 'hidden'
        }}><Div key={'gybhjk'} styles={{ ...uikit.flexRow, ...uikit.justifyContentBetween }}>{getLine(40).map(item => item)}</Div></Div>
    }
    if (type === 'list'){
        const getRows = (size, widthArray = []) => {
            let array = []
            for (let index = 0;index < size;index++) {
                const width = widthArray[index] ? { width: `${widthArray[index]}%` } : {}
                array.push(<>
                    <Div key={index} styles={{
                        ...uikit.flexRow, ...uikit.alignItemsCenter, ...uikit.justifyContentBetween,
                        ...width
                    }}>
                        <Div styles={{
                            ...darkMode ? styles.skeleton_dark_bg : {},
                            ...styles.skeleton_icon, width: '1rem', height: '1rem', ...uikit.mr1
                        }}/>
                        <Div styles={{
                            ...darkMode ? styles.skeleton_dark_bg : {},
                            ...styles.skeleton_small, ...uikit.flexGrow1
                        }}/>
                    </Div>
                </>)
            }
            return array
        }

        return <Div styles={{
            ...width,
            ...align,
            ...margins,
            ...diameter
        }}>{getRows(properties?.size?.rows, properties?.size?.width).map(item => item)}</Div>
    }
    return <Div styles={{
        ...styles[`skeleton_${type}`],
        ...darkMode ? styles.skeleton_dark_bg : {},
        ...width,
        ...margins,
        ...align,
        ...diameter,
        ...properties?.mode ? styles[`skeleton_mode_${properties?.mode}`] : {},
        ...itemsShiners ? { ...uikit.positionRelative, overflow: 'hidden' } : {}
    }}>
        {itemsShiners ? <Div className='shine'/> : null}
    </Div>
}
export default RenderElemet