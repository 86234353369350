// @generated: @expo/next-adapter@2.1.5
import React, { useState } from 'react';
import YearBlock from './YearBlock';
import {
    Button, Div, P
} from '../../../../../hybrid/wrappers';

const HeaderYear = ({
    styles,
    activeYear,
    yearSetter,
    years
}) => {
    const [openYear, setOpenYear] = useState(false);

    return (
        <>
            <Div styles={styles.dpHeader__year}>
                <P styles={{ ...styles.dpHeader__date, ...styles.dpHeader__date_click }} data-uitest='28_uitest' onClick={() => setOpenYear((pre) => !pre)}>{activeYear}</P>
                <Button
                    type='button'
                    data-uitest='29_uitest' onClick={() => setOpenYear((pre) => !pre)}
                    styles={openYear ? { ...styles.dpHeader__yearSwith, ...styles.dpHeader__yearSwith_open } : styles.dpHeader__yearSwith}
                />
            </Div>
            {
                !!openYear
                    &&
                        <YearBlock
                            years={years}
                            yearSetter={yearSetter}
                            activeYear={activeYear}
                            setOpenYear={setOpenYear}
                            styles={styles}
                        />

            }
        </>
    );
};

export default HeaderYear;