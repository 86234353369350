import css from './NotificationsQuick.module.scss';
import { svgSprite } from '../../../hybrid/wrappers';
import React, {
    useEffect, useRef, useMemo
} from 'react';
import { getNotificationsConfig } from '../helpers';
const NotificationsQuick = ({ notification }) => {
    const fullConfig=useMemo(() => getNotificationsConfig(notification?.message?.[0]), [notification?.message])
    const config = fullConfig?.[notification?.type?.substring(notification?.type?.lastIndexOf('\\') + 1)] || fullConfig?.default_config || {}
    const ref = useRef(null);
    useEffect(() => {
        if (ref?.current) {
            setTimeout(() => {
                ref.current?.remove();
            }, 15000);
        }
    }, []);

    return (
        <div ref={ref} className={css.notificationsQuick}>
            <div className={css.notificationsIconWrapper} style={{ background: config?.bg }}>
                {svgSprite(config?.icon, {
                    width: config?.width || '30px',
                    height: config?.height || '30px',
                    fill: config?.fill
                })}
            </div>
            {/*{getIcon(notification?.type?.substring(notification?.type?.lastIndexOf('\\') + 1),*/}
            {/*    css,*/}
            {/*    notification?.subject,*/}
            {/*    'quick')}*/}
            <div style={{ flex: 1, marginRight: '16px' }}>
                <p className={css.notificationsQuickTitle}>{notification?.subject || ''}</p>
                {/*<p className={css.notificationsQuickSubTitle}>{notification?.message || ''}</p>*/}
                <p className={css.notificationsQuickSubTitle}>
                    {notification?.message && <p dangerouslySetInnerHTML={{ __html: notification?.message }}/>}
                </p>
            </div>
            <div className={css.notificationsQuickLoader}>
                <div
                    style={{ background: config?.bg }}
                    className={css.notificationsQuickLoaderProgress}
                ></div>
            </div>
            <div
                onClick={() => {
                    ref?.current?.remove();
                }}
                className={css.notificationsQuickModalClose}
            >
                {svgSprite('cross', {
                    width: '10px',
                    height: '10px',
                    fill: 'var(--greyExtraDarkColor)'
                })}
            </div>
        </div>
    );
};

export default NotificationsQuick;