import React from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { Div } from '../wrappers';
import {
    duration, defaultStyle, transitionStyles
} from './TransitionGroupAnimations';

const AnimatedElement = ({
    children,
    inProp,
    styles
}) =>
    <Transition in={inProp} timeout={duration} mountOnEnter unmountOnExit>
        {(state) =>
            <Div
                styles={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                    ...styles
                }}
            >
                {children}
            </Div>
        }
    </Transition>;
AnimatedElement.propTypes = {
    inProp: PropTypes.bool,
    styles: PropTypes.object
};

export default AnimatedElement;