// @generated: @expo/next-adapter@2.1.5
import React, { useCallback } from 'react';
import { Button, Div } from '../../../../../hybrid/wrappers';

const YearBlock = ({
    styles,
    activeYear,
    yearSetter,
    setOpenYear,
    years
}) => {
    const selectYear = useCallback((year) => {
        yearSetter(year);
        setOpenYear((pre) => !pre);
    }, [setOpenYear, yearSetter]);

    return (
        <Div className='dpHeader__yearData'>
            {
                years.map((year) =>
                    <Button
                        key={`datepicker${year}${Date.now()}`}
                        type='button'
                        data-uitest='27_uitest' onClick={() => selectYear(year)}
                        styles={activeYear === year ? { ...styles.dpHeader__yearData__item, ...styles.dpHeader__yearData__item_seleted } : styles.dpHeader__yearData__item}
                        effects={{ hover: styles.dpHeader__yearData__item_hover }}
                    >
                        {year}
                    </Button>)
            }
        </Div>
    );
};

export default YearBlock;