// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P, Div } from '../../../hybrid/wrappers';

const DegreePlus = ({
    getHandlers, gridStyles, name, val, styles
}) => {
    const isValueNegative = val.startsWith('-');
    const plus = {
        background: styles.variable.greenSuperExtraTransparentColor,
        color: styles.variable.greenColor,
        padding: '4px 6px',
        'border-radius': '4px'
    }
    const degree = {
        background: styles.variable.redDarkExtraTransparent,
        color: styles.variable.redDarkColor,
        padding: '4px 6px',
        'border-radius': '4px'
    }
    return (
        <Div
            {...getHandlers(false)}
            styles={gridStyles[name]?.wrapper?.styles || {}}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            <P
                styles={gridStyles[name]?.p?.styles || { color: styles.variable.darkColor }}
            >
                <P styles={isValueNegative ? degree : plus}>
                    {isValueNegative || val === '0' ? val : `+${val}`}
                </P>
            </P>
        </Div>
    )
};

DegreePlus.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    name: PropTypes.string,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ])
};

export default DegreePlus;