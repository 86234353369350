import { media } from '../../../common/utils/utils';

const stylesheets = (width, height, variable = {}) => ({
    //General
    flexRow: {
        'display': 'flex',
        'align-items': 'center'
    },
    cartFilterWrapper: media(width, height, 'w', {
        768: {
            padding: '24px',
            'border-radius': '20px',
            'background': variable.whiteColor
        },
        0: {
            padding: '12px',
            'border-radius': '20px',
            'background': variable.whiteColor
        }
    }),
    wrapperSearch: media(width, height, 'w', {
        768: {
            width: '100%',
            'margin': 0
        },
        0: {
            'max-width': '100%',
            width: '100%',
            // 'padding': '0 12px',
            'margin': 0
        }
    }),
    inputSearch: media(width, height, 'w', {
        768: {
            width: '100%',
            'margin': 0,
            'padding-left': '50px',
            'height': '44px',
            'border-radius': '12px',
            border: `1px solid ${variable.greyExtraDarkHalfTransparentColor}`
        },
        0: {
        // 'max-width': '400px',
            width: '100%',
            'margin': 0,
            'height': '44px',
            'padding-left': '50px',
            'border-radius': '8px',
            border: `1px solid ${variable.greyExtraDarkHalfTransparentColor}`

        }
    }),
    iconSearch: {
        position: 'absolute',
        top: '22px',
        left: '18px',
        transform: 'translateY(-50%)'
    },
    filterSearchWrapper: { position: 'relative' },
    resultSearch: {
        position: 'absolute',
        top: '52px',
        left: 0,
        right: 0,
        padding: '16px',
        background: variable.whiteColor,
        'box-shadow': `0px 10px 100px ${variable.blackTransparentColor}`,
        'border-radius': '14px',
        height: '300px',
        'overflow-y': 'auto',
        'z-index': '999'
    },
    searchItem: {
        padding: '12px 0',
        'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`,
        cursor: 'pointer'
    },
    searchItemTitle: { 'margin-bottom': '12px' },
    searchBody: {
        display: 'flex',
        'justify-content': 'space-between'
    },
    searchPrices: {
        display: 'flex',
        'align-items': 'center'
    },
    mark_price: {
        'font-weight': 700,
        color: variable.blueColor,
        'border-radius': '4px',
        background: variable.blueBackgroundLight
    },
    searchArticle: {
        background: variable.greyExtraDarkColorTransparent,
        'border-radius': '6px',
        padding: '6px 10px',
        display: 'flex',
        'align-items': 'center'
    },
    searchArticleTitle: {
        color: variable.greySuperDarkColor,
        'margin-right': '5px'
    }
});
export default stylesheets;