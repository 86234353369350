const mongoose = require('mongoose');

const Menu = new mongoose.Schema({
    location: { type: String },
    name: { type: String },
    title: { type: Object },
    link: { type: String },
    linkNative: { type: String },
    parent: { type: String },
    order: { type: Number },
    type: { type: String },
    page: { type: String },
    activity: { type: Boolean },
    target: { type: Boolean },
    showAll: { type: Boolean },
    icon: { type: String },
    roles: { type: Array }
});
module.exports = mongoose.models?.menus || mongoose.model('menus', Menu);