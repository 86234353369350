import { Div, Portal } from '../../hybrid/wrappers';
import {
    useEffect, useState, useRef, useContext, useMemo
} from 'react';
import Styles from '../../common/decorators/Styles';
import getStyles from '../../project/styles/widget-styles/maps-styles';
import InfoMarker from './InfoMarker';
import ReactDOMServer from 'react-dom/server';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import UserContext from '../../common/utils/getContext';
import { useWindowDimensions } from '../../common/utils/windowDimensions';
import PropTypes from 'prop-types';

const TTMaps = ({
    onClick,
    styles,
    contactData,
    bounds,
    center
}) => {
    const mapElement = useRef();

    const [map, setMap] = useState(false)
    const [tt, setTT] = useState(false)
    const [markers, setMarkers] = useState([])
    const [showModal, setShowModal] = useState(false);

    const { lang } = useContext(UserContext) ? useContext(UserContext) : {};

    const isMobile = useWindowDimensions() < 768;

    const locales = useMemo(() => ({
        fr: 'fr-FR',
        bg: 'bg-BG',
        zh: 'zh-CN',
        cs: 'cs-CZ',
        da: 'da-DK',
        nl: 'nl-NL',
        en: 'en-US',
        fi: 'fi-FI',
        ar: 'ar',
        de: 'de-DE',
        el: 'el-GR',
        hu: 'hu-HU',
        id: 'id-ID',
        it: 'it-TT',
        ko: 'ko-KR',
        lt: 'lt-LT',
        ms: 'ms-MY',
        nb: 'nb-NO',
        pl: 'pl-PL',
        pt: 'pt-PT',
        ru: 'ru-RU',
        sk: 'sk-SK',
        sl: 'sl-SI',
        es: 'es-ES',
        sv: 'sv-SE',
        th: 'th-TH',
        tr: 'tr-TR'
    }), [])

    useEffect(() => {
        (async() => {
            try {
                const tt = await import('@tomtom-international/web-sdk-maps');
                setTT(tt)
            } catch (e) {
                console.warn(e)
            }
        })();
    }, []);

    useEffect(() => {
        if (tt && mapElement.current) {
            let zoom =4;
            if (bounds?.length){
                switch (true) {
                case bounds[0]>200:
                    zoom=1
                    center[0]+=85
                    break;
                case bounds[0]>100:
                    zoom=2
                    center[0]+=45
                    break;
                case bounds[0]>30:
                    zoom=3
                    center[0]+=22
                    break;
                }
            }
            let map = tt.map({
                key: '1piAH4WaagiEcMf1xzhLm7LgynYAUo8C',
                center: center || [50, 50],
                zoom: zoom,
                container: mapElement.current,
                language: locales[lang] || 'en-US'
            });
            if (onClick){
                map.on('click', onClick)
            }
            map.addControl(new tt.NavigationControl(), 'bottom-left');
            map.addControl(new tt.FullscreenControl(), 'top-left');
            setMap(map)
            return () => map.remove();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang, locales, onClick, tt]);

    useEffect(() => {
        for (const item of markers) {
            item.remove()
        }
        if (tt && map && contactData){
            contactData.map(item => {
                const {
                    latitude, longitude, active
                } = item;
                if (active){
                    let element = document.createElement('div');
                    element.className = 'marker';
                    element.innerHTML = `
                        <svg width="27" height="43" viewBox="0 0 27 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="13.5" cy="39.5" rx="4.5" ry="2.5" fill="black" fill-opacity="0.12"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M26 13.5C26 16.3253 25.0627 18.9316 23.4821 21.025C22.9203 21.8066 22.1609 22.6384 21.3202 23.5591C18.4001 26.7571 14.5 31.0285 14.5 38C14.5 38.5523 14.0523 39 13.5 39C12.9477 39 12.5 38.5523 12.5 38C12.5 31.0285 8.59989 26.7571 5.67984 23.5591C4.83915 22.6384 4.07969 21.8066 3.51793 21.025C1.93731 18.9316 1 16.3253 1 13.5C1 6.59644 6.59644 1 13.5 1C20.4036 1 26 6.59644 26 13.5Z"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M26 13.5C26 16.3253 25.0627 18.9316 23.4821 21.025C22.9203 21.8066 22.1609 22.6384 21.3202 23.5591C18.4001 26.7571 14.5 31.0285 14.5 38C14.5 38.5523 14.0523 39 13.5 39C12.9477 39 12.5 38.5523 12.5 38C12.5 31.0285 8.59989 26.7571 5.67984 23.5591C4.83915 22.6384 4.07969 21.8066 3.51793 21.025C1.93731 18.9316 1 16.3253 1 13.5C1 6.59644 6.59644 1 13.5 1C20.4036 1 26 6.59644 26 13.5Z" fill="#D94763"/>
                            <path d="M23.4821 21.025L22.684 20.4224L22.6769 20.4318L22.67 20.4414L23.4821 21.025ZM21.3202 23.5591L22.0586 24.2334L21.3202 23.5591ZM5.67984 23.5591L6.41831 22.8848L6.41831 22.8848L5.67984 23.5591ZM3.51793 21.025L4.32995 20.4414L4.32309 20.4318L4.316 20.4225L3.51793 21.025ZM24.2801 21.6276C25.9873 19.3666 27 16.5501 27 13.5H25C25 16.1004 24.1381 18.4966 22.684 20.4224L24.2801 21.6276ZM22.0586 24.2334C22.8872 23.326 23.6911 22.4477 24.2941 21.6086L22.67 20.4414C22.1496 21.1656 21.4345 21.9508 20.5817 22.8848L22.0586 24.2334ZM15.5 38C15.5 31.4506 19.1345 27.4359 22.0586 24.2334L20.5817 22.8848C17.6658 26.0783 13.5 30.6065 13.5 38H15.5ZM13.5 40C14.6046 40 15.5 39.1046 15.5 38H13.5V40ZM11.5 38C11.5 39.1046 12.3954 40 13.5 40V38H11.5ZM4.94136 24.2334C7.86553 27.4359 11.5 31.4506 11.5 38H13.5C13.5 30.6065 9.33425 26.0783 6.41831 22.8848L4.94136 24.2334ZM2.70591 21.6087C3.30894 22.4477 4.11284 23.326 4.94136 24.2334L6.41831 22.8848C5.56546 21.9508 4.85045 21.1656 4.32995 20.4414L2.70591 21.6087ZM0 13.5C0 16.5501 1.01274 19.3666 2.71987 21.6276L4.316 20.4225C2.86188 18.4966 2 16.1004 2 13.5H0ZM13.5 0C6.04416 0 0 6.04416 0 13.5H2C2 7.14873 7.14873 2 13.5 2V0ZM27 13.5C27 6.04416 20.9558 0 13.5 0V2C19.8513 2 25 7.14873 25 13.5H27Z"/>
                            <circle cx="13.5" cy="13.5" r="4.5" fill="black" fill-opacity="0.4"/>
                        </svg>
                    `
                    let marker = new tt.Marker({ element: element }).setLngLat([longitude, latitude])
                        .addTo(map);
                    if (isMobile) {
                        marker.getElement().addEventListener('click', () => {
                            setShowModal(item)
                        })
                    }
                    setMarkers(pre => [...pre, marker])
                    const htmlString = ReactDOMServer.renderToStaticMarkup(<InfoMarker
                        styles={styles}
                        contactData={item}
                    />);
                    if (!isMobile) {
                        const markerHeight = 57, markerRadius = 10, linearOffset = 25;
                        marker.setPopup(new tt.Popup({
                            offset: {
                                'top': [0, 0],
                                'top-left': [0, 0],
                                'top-right': [0, 0],
                                'bottom': [0, -markerHeight],
                                'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
                                'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
                                'left': [markerRadius, (markerHeight - markerRadius) * -1],
                                'right': [-markerRadius, (markerHeight - markerRadius) * -1]
                            }
                        }).setHTML(htmlString));
                    }
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactData, map, styles, tt, isMobile]);

    return (
        <>
            <Div
                id='map'
                styles={{ ...styles.googleMapWrapper, ...onClick ? { cursor: 'pointer' } : {} }}
                childrenRef={mapElement}
            />
            {
                showModal &&
                    <Portal closeHandler={() => setShowModal(false)}>
                        <Div styles={styles.mainInfoContainerModal}>
                            <InfoMarker
                                styles={styles}
                                contactData={showModal}
                                setShowModal={setShowModal}
                            />
                        </Div>
                    </Portal>
            }
        </>
    )
}
TTMaps.propTypes = {
    onClick: PropTypes.func,
    styles: PropTypes.object,
    contactData: PropTypes.array,
    bounds: PropTypes.array,
    center: PropTypes.array
};

export default Styles(TTMaps, getStyles);