import { media } from '../../../common/utils/utils';
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    // authBlockWeb: media(width, height, 'w', {
    //     768: {
    //     },
    //     0: {
    //     },
    // }),
    orderStatusPayButtonIcon: {
        flex: '0 0 12px',
        'max-width': '12px',
        height: '12px',
        'margin-right': '19px'
    },
    iconGreenSvg: { fill: variable.greenColor },
    iconRedSvg: { fill: variable.redDarkColor },
    conditionBlock: {
        'background-color': variable.greySuperExtraDarkTransparentColor,
        'border-radius': '8px',
        padding: '10px 16px 10px 19px',
        'min-height': '43px',
        ...uikit.flexRow,
        ...uikit.alignItemsCenter,
        ...uikit.justifyContentBetween,
        'margin-top': '8px',
        'margin-bottom': '8px'
    },
    conditionBlockSuccess: { 'background-color': variable.greenDeepTransparentColor },
    conditionBlockText: {
        color: variable.darkColor,
        'line-height': '1.1875'
    },
    statusDone: {
        'background': variable.whiteColor,
        'border': `2px solid ${variable.greyExtraDarkColorTransparent}`,
        'border-radius': '8px',
        padding: '4px 10px 4px 10px',
        ...uikit.flexRow,
        ...uikit.alignItemsCenter,
        ...uikit.justifyContentCenter,
        'min-width': '68px'
    },
    speedHeaderTitleTooltipSvg: media(width, height, 'w', {
        768: {
            fill: variable.greySuperDarkColor,
            width: '16px',
            height: '16px'
        },
        0: {
            fill: variable.greySuperDarkColor,
            width: '14px',
            height: '14px'
        }
    })
});
export default stylesheets;