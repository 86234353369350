import connect from './connect';
import { API_SERVER_URL, SITE_URL } from './utils';
const Code = require('../../common/schema/CodeSchema')
const Langs = require('../../common/schema/LangSchema')
const Menu = require('../../common/schema/MenuSchema')
const Settings = require('../../common/schema/SettingsSchema')
const Page = require('../../common/schema/PageSchema')
const Trans = require('../../common/schema/TransSchema')

// Проверка доспупов админа
export const checkForAuth = async (headers, res) => {
    const authorization = headers?.authorization;

    const getData = await fetch(`${API_SERVER_URL}/api/v1/admin/profile`, {
        headers: {
            Authorization: authorization,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });
    const getDataJson = await getData.json();
    if (getDataJson.data) {
        return false;
    } else {
        return () => {
            res.status(402).json({ success: false, ...getDataJson });
        };
    }
}


// Получение из Mongo данных
export const getLangLocaleConnect = async (getFilters, lang, res) => {
    connect();
    const result = (data, status) => {
        if (!data) {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data: {} })) : {};
        } else {
            const resultData = data.reduce((accum, val) => {
                accum[val.textName] = val.trans && val.trans[lang] && val.trans[lang].data ? val.trans[lang].data : null;
                return accum;
            }, {})
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data: resultData })) : JSON.parse(JSON.stringify({ data: resultData }));
        }
    }
    try {
        const data = await Trans.aggregate([
            { $match: getFilters },
            {
                $project: {
                    _id: 0, textName: '$data.textName', trans: '$data.trans'
                }
            }
        ])
        return result(data, 200)
    } catch (e) {
        return result()
    }
};
export const getLangsConnect = async (searchParams, res) => {
    connect()
    const result = (data, status) => {
        if (!data) {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data: [] })) : {};
        } else {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data })) : JSON.parse(JSON.stringify({ data }));
        }
    }

    try {
        const data = await Langs.find(searchParams);
        return result(data, 200)
    } catch (e) {
        return result({}, 404)
    }
}
export const getSettingsConnect = async (alias, res) => {
    connect();
    const result = (data, status) => {
        if (!data) {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data: {} })) : {};
        } else {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data })) : JSON.parse(JSON.stringify({ data }));
        }
    }

    try {
        const data = await Settings.findOne({ alias: alias }, 'data');
        return result(data, 200)
    } catch (e) {
        return result({}, 404)
    }
}

export const getCodesConnect = async (res) => {
    connect();
    const result = (data, status) => {
        if (!data) {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data: {} })) : {};
        } else {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data })) : JSON.parse(JSON.stringify({ data }));
        }
    }

    try {
        const data = await Code.find({ name: { $exists: true } })
        return result(data, 200)
    } catch (e) {
        return result({}, 404)
    }
}
export const getPagesConnect = async (searchParams, res) => {
    connect();

    const result = (data, status) => {
        if (!data) {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data: [] })) : {};
        } else {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data })) : JSON.parse(JSON.stringify({ data }));
        }
    }

    try {
        const data = await Page.find(searchParams);
        return result(data, 200)
    } catch (e) {
        return result({}, 404)
    }
}
export const getPageConnect = async (id, res) => {
    connect();
    const result = (data, status) => {
        if (!data) {
            return res ? res.status(404).end(JSON.stringify({ success: false })) : {};
        } else {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data })) : JSON.parse(JSON.stringify({ data }));
        }
    }

    try {
        if (id.length === 24) {
            const data = await Page.findOne({ _id: id });
            return result(data, 200)
        } else {
            const data = await Page.findOne({ url: id });
            return result(data, 200)
        }
    } catch (e) {
        return result({}, 404)
    }
}
export const getMenuConnect = async (query, res) => {
    connect();
    const result = (data, status) => {
        if (!data) {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data: [] })) : {};
        } else {
            return res ? res.status(status || 404).end(JSON.stringify({ success: status === 200, data })) : JSON.parse(JSON.stringify({ data }));
        }
    }

    try {
        const data = await Menu.find(query);

        return result(data, 200)
    } catch (e) {
        return result({}, 404)
    }
}
export const secureTypes = ['jpeg', 'jpg', 'png', 'txt', 'docx', 'xlsx', 'pdf', 'ppt', 'pptx', 'djvu'];
export const checkIsfile = (path, types) => {
    const resolvedTypes = types || [...secureTypes, 'xml', 'html', 'heic'];
    let trigger = false;
    if (!path || typeof path !== 'string') return false;
    resolvedTypes.forEach(type => {
        if (path?.slice(-(type.length+1)).toLowerCase() === `.${type}`.toLowerCase()){
            trigger = type;
        }
    })
    return trigger
}
export const getFile = async (query) => {
    if (query?.extra?.length){
        const extension = checkIsfile(query.extra[query.extra.length-1])
        if (extension){
            const name = query.extra[query.extra.length-1];
            const path = query.extra.length > 1 ? `/${query.extra.slice(0, -1).reduce((ac, i) => ac+i, '')}` : false;

            const request = await fetch(`${SITE_URL}/api/extras/files?name=${name}${path ? `&path=${path}` : ''}`);
            const response = await request?.json();

            if (!response || !response?.data || !response?.data?.length) {
                return { notFound: true }
            }

            return {
                props: {
                    file: response.data.reduce((accum, item) => {
                        return accum + item.text
                    }, ''),
                    extension: extension === 'txt' ? 'plain' : extension
                }
            };
        }
        return false;
    }
    return false;
}