// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import {
    Div, P, Img
} from '../../../hybrid/wrappers';

const ImgText = ({
    getHandlers, gridStyles, styles, name, val
}) => {
    return (
        <Div
            {...getHandlers(false)}
            styles={{ ...styles.imageTextCell, ...gridStyles[name]?.wrapper?.styles || {} }}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            {
                val.image ? <Img styles={styles.imageTextCellImg} src={val.image}/> : <Div styles={styles.imageTextCellImg}/>
            }
            <P>{`${val.name}`}</P>
        </Div>
    )
};

ImgText.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    styles: PropTypes.object,
    name: PropTypes.string,
    link: PropTypes.string,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ])
};

export default ImgText;