// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useCallback, useMemo, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {
    Div, FieldError, FieldInfo, Label
} from '../../../../hybrid/wrappers';
import { initFormHandlers } from '../../formUtils';
import FromInputTitle from '../FormFieldTitle';
import { getConfig } from './helpers';
import UserContext, { UserProfileContext } from '../../../../common/utils/getContext';
import InputSwitcher from './components/Elements';
import { getDefaultVariation, getIsCoupleVariation } from '../helpers';

const Wrapper = (props) => {
    const { noWrapper, children } = props;
    if (noWrapper) {
        return <>{children}</>
    } else {
        return <Div {...props}>{children}</Div>
    }
}
const FormInput = (props) => {
    const {
        noWrapper,
        attribute,
        hint,
        required,
        readonly,
        disabled,
        title,
        handlers = {},
        compStyle = {},
        value,
        type = 'input',
        setFormData = () => {},
        styles,
        info,
        sender = () => {},
        errors,
        typeField,
        step,
        maxLength = 150,
        search,
        variation,
        buttons = {},
        sorting = ['search', 'social', 'input', 'password', 'copy'],
        minValue = -Infinity
    } = props;
    const { localization, lang } = useContext(UserContext);
    const contextData = useContext(UserProfileContext)

    const sorted = [...sorting, ...Object.keys(buttons).reduce((ac, k) => {
        if (sorting.includes(k)) return ac;
        return [...ac, k]
    }, [])]
    const variationStyle = variation || getDefaultVariation(contextData, title)
    const [val, setVal] = useState(value || '');
    const [focused, setFocused] = useState(false);
    const [showPass, setShowPass] = useState(type === 'password');

    const changeHandler = useCallback((v) => {
        if (!disabled) {
            if (type === 'phone-widget'){
                setFormData(attribute, v ? `+${v}` : '');
                // setFormData(`${attribute}-code`, {
                //     dialCode: phone.dialCode,
                //     format: phone?.format || '',
                //     value: v ? `+${v}` : ''
                // })
                setVal(v);
            } else {
                const newValue = isNaN(parseInt(v)) ? 0 : v

                const valCor = step === 8 && v ? v.replace(/[^\w\s,.\/а-яА-ЯёЁғқңәіүұһ]/g, '') : step === 7 && v ? v.replace(/[^a-zA-Zа-яА-Я]/g, '') : step === 6 && v ? v.replace(/[^a-zA-Z0-9]/g, '') : step === 5 && v ? v.replace(/[^\d]/g, '') : step === 4 && v ? v.replace(/[^\d,\.]/g, '') : step === 3 ? v.replace(/[^\w\s,.\/а-яА-ЯёЁ]/g, '') : step === 2 && v ? parseInt(`${newValue}`.replace(/\D/g, '')) : step === 1 && v && !isNaN(parseInt(`${v}`))
                    ? parseInt(`${v}`.replace(/[^0-9]/g, '')) : step === 1 && v ? ''
                        : v.length > maxLength
                            ? v.substr(0, maxLength)
                            : v;
                if (search?.type === 'onInput' && handlers?.onChange) {
                    handlers.onChange(valCor);
                } else {
                    setFormData(attribute, valCor);
                }
                setVal(valCor);
            }
        }
    }, [disabled, type, setFormData, attribute, step, maxLength, search?.type, handlers]);

    const pressHandler = useCallback((e) => {
        if (search?.type === 'onInput' && e.key === 'Enter') {
            handlers?.onKeyPress && handlers?.onKeyPress(e)
            e.preventDefault();
        } else if (e.key === 'Enter') {
            sender(e);
        }
    }, [handlers, search?.type, sender]);

    useEffect(() => {
        setVal(value || '');
    }, [setVal, value]);

    const initHandlers = useMemo(() => initFormHandlers(
        handlers, attribute, val, type, errors, setFocused
    ),
    [handlers, attribute, val, type, errors]);

    const [defaultCountry, setDefaultCountry] = useState(localization?.country?.code)
    useEffect(() => {
        if (localization?.country?.code && type === 'phone-widget') {
            setDefaultCountry(localization?.country?.code?.toLowerCase())
        }
    }, [lang, localization, type, localization?.country?.code]);

    const componentConfig = getConfig(
        attribute, required, typeField, val, maxLength, step, minValue, readonly, initHandlers, type, defaultCountry, changeHandler, pressHandler, showPass, variationStyle, styles, compStyle, errors, focused, disabled
    );

    return (
        <Wrapper noWrapper={noWrapper} styles={{ ...styles.formGroup, ...compStyle.wrapper }}>
            {
                !!title && !getIsCoupleVariation(variationStyle) ?
                    <FromInputTitle
                        id={`input_${attribute}`}
                        styles={styles}
                        errors={errors}
                        title={title}

                        compStyle={compStyle}
                        hint={hint}
                        required={required}
                        variationStyle={variationStyle}
                        focused={focused}
                    /> : null

            }
            <Label
                onClick={initHandlers?.onClick}
                HtmlFor={`input_${attribute}`}
                styles={
                    {
                        ...styles[`formControl__${variationStyle}`],
                        ...compStyle?.input,
                        ...errors && errors.length
                            ? {
                                ...styles[`formControlError__${variationStyle}`],
                                ...compStyle?.inputError
                            } : {
                                ...focused
                                    ? {
                                        ...styles[`formControlFocus__${variationStyle}`],
                                        ...compStyle?.inputFocus
                                    } : {},
                                ...disabled
                                    ? {
                                        ...styles['formControlDisabled'],
                                        ...compStyle?.inputDisabled
                                    } : {}
                            }
                    }
                }>
                <Div styles={{ ...styles.formControlContent, ...compStyle.content }}>
                    {
                        sorted.map((keyword) =>
                            <InputSwitcher
                                {...props}
                                key={`${attribute}_${keyword}`}
                                keyword={keyword}
                                changeHandler={changeHandler}
                                variationStyle={variationStyle}
                                val={val}
                                focused={focused}
                                componentConfig={componentConfig}
                                setShowPass={setShowPass}
                                showPass={showPass}
                                compStyle={compStyle}
                            />)
                    }
                </Div>

            </Label>

            {
                !!info
                    && <FieldInfo compStyle={compStyle} styles={{ ...styles, ...compStyle?.info ? compStyle?.info : {} }}>{info}</FieldInfo>
            }

            {
                errors && errors.length ? errors.map((err, idx) => <FieldError styles={{ ...styles, ...compStyle?.error || {} }} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
        </Wrapper>
    );
};


FormInput.propTypes = {
    noWrapper: PropTypes.bool,
    attribute: PropTypes.string,
    hint: PropTypes.node,
    placeholder: PropTypes.node,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.object,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    info: PropTypes.node,
    sender: PropTypes.func,
    errors: PropTypes.array,
    message: PropTypes.object,
    typeField: PropTypes.string,
    step: PropTypes.number,
    maxLength: PropTypes.number,
    search: PropTypes.object,
    addon: PropTypes.node,
    copy: PropTypes.object,
    tooltip: PropTypes.node,
    variation: PropTypes.string,
    customData: PropTypes.object,
    buttons: PropTypes.object,
    sorting: PropTypes.array,
    minValue: PropTypes.number
};

export default Styles(FormInput, getStyles);