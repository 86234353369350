// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useCallback, useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import FormInput from './FormInput';

const ToggleFormInput = (props) => {
    const {
        title,
        buttonsAttribute,
        options,
        defaultValue,
        disabled,
        compStyle,
        setFormData,
        styles
    } = props;

    const [btnValue, setBtnValue] = useState(defaultValue || null);
    const inputPlaceholder = useMemo(() => options.find((item) => item.id === btnValue) ? options.find((item) => item.id === btnValue).placeholder : '', [btnValue, options]);
    const inputTitle = useMemo(() => options.find((item) => item.id === btnValue) ? options.find((item) => item.id === btnValue).name : '', [btnValue, options]);
    const inputType = useMemo(() => options.find((item) => item.id === btnValue) ? options.find((item) => item.id === btnValue).type : '', [btnValue, options]);
    const isSocial = useMemo(() => options.find((item) => item.id === btnValue) ? options.find((item) => item.id === btnValue).isSocial : '', [btnValue, options]);

    const changeBtnHandler = useCallback((v) => {
        if (!disabled) {
            setFormData(buttonsAttribute, v);
            setBtnValue(v);
        }
    }, [disabled, setFormData, buttonsAttribute]);

    useEffect(() => {
        setBtnValue(defaultValue)
    }, [defaultValue])

    return (
        <>
            <FormInput
                {...props}
                buttons={options.reduce((acc, item) => ({
                    ...acc,
                    [item.id]: {
                        handler: () => { changeBtnHandler(item.id) },
                        icon: item.icon
                    }
                }), {})}
                type={inputType || 'input'}
                placeholder={inputPlaceholder || ''}
                title={inputTitle ? { body: inputTitle } : title}
                customData={{
                    ...props.customData,
                    ...isSocial ? { isSocial } : {}
                }}
                compStyle={{
                    ...compStyle,
                    content: { ...styles.toggleInputContentMobile, ...compStyle?.content },
                    inner: { ...styles.toggleInputInnerMobile, ...compStyle?.inner },
                    ...options.reduce((acc, item) => ({
                        ...acc,
                        [item.id]: {
                            button: {
                                ...styles.toggleInputButton,
                                ...btnValue === item.id ? styles?.toggleInputButtonActive : {},
                                ...compStyle?.button,
                                ...btnValue === item.id ? compStyle?.buttonActive : {}
                            },
                            icon: {
                                ...styles?.toggleInputButtonIcon,
                                ...btnValue === item.id ? styles?.toggleInputButtonIconActive : {},
                                ...compStyle?.icon,
                                ...btnValue === item.id ? compStyle?.iconActive : {}
                            }
                        }
                    }), {})
                }}
            />
        </>

    );
};

ToggleFormInput.propTypes = {
    title: PropTypes.object,
    buttonsAttribute: PropTypes.string,
    options: PropTypes.array,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    compStyle: PropTypes.object,
    setFormData: PropTypes.func,
    styles: PropTypes.object
};

export default Styles(ToggleFormInput, getStyles);