import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    socialBlock: {
        position: 'relative',
        'z-index': 1,
        background: variable.greyExtraDarkTransparentColor,
        'border-radius': '14px',
        padding: '4px 24px',
        margin: '16px 0 0'
    },
    socialWrap: {
        display: 'flex',
        'align-items': 'center',
        'flex-wrap': 'wrap',
        'justify-content': 'space-around',
        flex: 1
    },
    socialItem: { padding: '4px 7px' },
    contactIcon: {
        width: '24px',
        height: '24px'
    },
    buttonArrow: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        'z-index': '999',
        cursor: 'pointer'
    },
    buttonArrowNext: { right: '-12px' },
    buttonArrowPrev: { left: '-12px' },
    socialBlockDisabled: {
        position: 'absolute',
        'z-index': 2,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        'border-radius': '14px',
        background: variable.blackColor,
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'text-align': 'center',
        color: variable.whiteColor,
        ...uikit.small,
        opacity: '.4'
    }
});
export default stylesheets;