import React from 'react';
import PropTypes from 'prop-types';
import { Div, svgSprite } from '../wrappers';
import uikit from '../../project/uikit/styles';

const FieldInfoElement = ({
    children,
    styles,
    compStyle
}) => {
    const innerStyles = {
        errorWrapper: {
            display: 'flex',
            'align-items': 'flex-start',
            'margin-top': '8px'
        },
        errorIcon: {
            width: '12px',
            height: '16px',
            marginRight: '6px',
            fill: styles.variable.darkHalfTransparentColor,
            flexShrink: 0,
            padding: '2px 0'
        },
        errorText: {
            ...uikit.tiny,
            'flex-grow': '1',
            color: styles.variable.darkHalfTransparentColor
        }
    };
    return (
        <Div styles={{
            ...innerStyles.errorWrapper, ...styles?.wrapper, ...compStyle?.errorWrapper || {}
        }}>
            {svgSprite('warning-circle', {
                style: {
                    ...innerStyles.errorIcon, ...styles?.icon, ...compStyle?.icon || {}
                }
            })}
            <Div styles={{
                ...innerStyles.errorText, ...styles?.text, ...compStyle?.text || {}
            }}>
                {children}
            </Div>
        </Div>
    );
};

FieldInfoElement.propTypes = {
    styles: PropTypes.object,
    compStyle: PropTypes.object
};

export default FieldInfoElement;