import { components } from 'react-select';
import { svgSprite } from '../../../../../hybrid/wrappers';

const getDropdownIndicator = (styles, compStyle) => {
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                {svgSprite('angle-down', {
                    style: {
                        fill: styles.variable.darkColor,
                        width: '10px',
                        height: '5px',
                        ...compStyle?.arrowSvg
                    }
                })}
            </components.DropdownIndicator>
        );
    };

    return DropdownIndicator
}
export default getDropdownIndicator;