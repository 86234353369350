// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P, Div } from '../../../hybrid/wrappers';
import { t } from '../../../common/components/T';

const Status = ({
    getHandlers, gridStyles, styles, name, val, translation
}) => {
    const getStatusText = (value, male, variant) => {
        switch (value) {
        case 'inprocessing':
        case 'waiting':
            return t(
                'grid-inprocessing', 'В обработке', 'widget', translation, 'grid'
            );
        case 'active':
        case 'open':
            return male ? t(
                'grid-Men-Active', 'Активный', 'widget', translation, 'grid'
            ) : t(
                'grid-Woman-Acrive', 'Активна', 'widget', translation, 'grid'
            );
        case 'hidden':
            if (variant) return t(
                'grid-NotActive', 'Не активна', 'widget', translation, 'grid'
            )
            return male ? t(
                'grid-Men-Hidden', 'Скрытый', 'widget', translation, 'grid'
            ) : t(
                'grid-Woman-Hidden', 'Скрытая', 'widget', translation, 'grid'
            );
        case 'deleted':
            return male ? t(
                'grid-Men-Delete', 'Удален', 'widget', translation, 'grid'
            ) : t(
                'grid-Woman-Delete', 'Удалена', 'widget', translation, 'grid'
            )
        case 'new':
            return t(
                'grid-New', 'Новый', 'widget', translation, 'grid'
            );
        case 'send':
        case 'delivered':
            return t(
                'grid-Sended', 'Отправлено', 'widget', translation, 'grid'
            )
        case 'rejected':
        case 'canceled':
            return t(
                'grid-Rejected', 'Отклонено', 'widget', translation, 'grid'
            );
        case 'processed':
            return t(
                'grid-InProcess', 'В процессe', 'widget', translation, 'grid'
            );
        case 'preorder':
            return t(
                'grid-preorder', 'Предзаказ', 'widget', translation, 'grid'
            );
        default:
            return ''
        }
    }

    let formattedDate 

    if(val?.value && val?.value === 'preorder' && val?.data) {
        const formDate = new Date(val.data)

        const year = formDate.getFullYear();
        const month = String(formDate.getMonth() + 1).padStart(2, '0');
        const day = String(formDate.getDate()).padStart(2, '0');
        
        formattedDate = `${year}-${month}-${day}`;
    }



    return (
        <Div
            {...getHandlers(false)}
            styles={gridStyles[name]?.wrapper?.styles || {}}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            <P
                styles={{
                    ...gridStyles[name] && gridStyles[name][val?.alias || val?.value || val]?.styles || styles.orderStatus,
                    ...styles[`status_${val?.alias || val?.value || val}`] || styles.status_default
                }}
                effects={gridStyles[name] && gridStyles[name][val?.alias || val?.value || val]?.effects || {}}
            >
                <P styles={{ padding: '3px' }}>{val?.text || getStatusText(val?.alias || val?.value || val, val?.sex === 'male', typeof val === 'string')}</P>
            </P>
            {(val?.alias === 'preorder' || val?.value === 'preorder') && formattedDate && <div style={{'text-align': 'center', 'margin-top': '6px', 'font-size': '16px', 'font-weight': 500}}>{formattedDate}</div>}
               
        </Div>
    )
}


Status.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ]),
    styles: PropTypes.object,
    name: PropTypes.string
};

export default Status;