import { media } from '../../../common/utils/utils';
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    buttonWrapper: media(width, height, 'w', {
        768: {
            ...uikit.flexRow,
            ...uikit.alignItemsCenter,
            width: 'fit-content',
            padding: '5px 12px',
            'margin-bottom': '8px',
            border: `1px solid ${variable.darkExtraTransparentColor}`,
            'box-sizing': 'border-box',
            'border-radius': '8px',
            color: variable.blueColor,
            'font-weight': 500,
            'font-size': '14px',
            'line-height': '20px',
            cursor: 'pointer'
        },
        0: {
            ...uikit.flexRow,
            ...uikit.alignItemsCenter,
            width: 'fit-content',
            padding: '5px 12px',
            'margin-bottom': '8px',
            border: `1px solid ${variable.darkExtraTransparentColor}`,
            'box-sizing': 'border-box',
            'border-radius': '8px',
            color: variable.blueColor,
            'font-weight': 500,
            'font-size': '14px',
            'line-height': '20px',
            cursor: 'pointer'
        }
    }),
    svgWrapper: {
        height: '20px',
        width: '20px'
    },
    mainInfoContainerModal: {
        position: 'absolute',
        top: '50%',
        left: '12px',
        right: '12px',
        'width': 'auto',
        padding: '16px',
        transform: 'translateY(-50%)',
        'border-radius': '14px',
        'background-color': variable.whiteColor
    },
    containerTitle: {
        color: variable.darkColor,
        'font-weight': '500',
        ...uikit.subtitle,
        'white-space': 'normal'
    },
    infoType: {
        background: variable.greyExtraDarkTransparentColor,
        'border-radius': '8px',
        padding: '6px 8px',
        margin: '5px 0',
        color: variable.blueTotalGray,
        ...uikit.small,
        width: 'max-content'
    },
    closeIcon: {
        position: 'absolute',
        top: '19px',
        right: '19px'
    },
    leftSide: {
        position: 'absolute',
        'background-color': variable.whiteColor,
        width: '15px',
        height: '15px',
        left: '-3px',
        transform: 'rotate(45deg)'
    },
    rightSide: {
        position: 'absolute',
        'background-color': variable.whiteColor,
        width: '30px',
        height: '30px',
        right: '-3px',
        transform: 'rotate(-45deg)'
    },
    contactBlock: {
        ...uikit.flexRow,
        ...uikit.justifyContentStart,
        ...uikit.alignItemsStart,
        padding: '16px 0'
    },
    contactSvg: { ...uikit.flexRow },
    BlockContainer: {
        'text-align': 'left',
        'margin-left': '2px'
    },
    blockfirstText: {
        margin: '0px 10px 5px',
        color: variable.blueColor,
        ...uikit.text
    },
    blockfirstLink: { color: variable.blueColor },
    blockSecondText: {
        margin: '0px 10px',
        color: variable.greySuperDarkColor,
        ...uikit.tiny
    },
    borderLine: media(width, height, 'w', {
        768: {
            'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`,
            'margin-left': '-24px',
            'margin-right': '-24px'
        },
        0: {
            'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`,
            'margin-left': '-16px',
            'margin-right': '-16px'
        }
    }),
    blockfirstAdrres: {
        margin: '0px 10px 5px',
        color: variable.darkColor,
        ...uikit.text
    },
    blockfirstAdrresList: {
        margin: '0px 10px 5px',
        color: variable.darkColor,
        ...uikit.text,
        'font-weight': '400'
    },
    socialTitle: {
        margin: '10px 0',
        color: variable.darkColor,
        ...uikit.text,
        'text-align': 'left'
    },
    iconMargin: {
        'margin-right': '10px',
        cursor: 'pointer'
    },
    googleMapWrapper: {
        width: '100%',
        height: '100%',
        'border-radius': '20px'
    }
});
export default stylesheets;