const mongoose = require('mongoose');

const Code = new mongoose.Schema({
    position: { type: Number },
    name: {
        type: String,
        required: [true, 'Необходимо заполнить поле'],
        unique: true
    },
    status: { type: Boolean },
    text: { type: String, required: [true, 'Необходимо заполнить поле'] }
});
module.exports = mongoose.models?.codes || mongoose.model('codes', Code);