import Loader from '../../Loader'
import RenderElemet from './RenderElemet'
import RenderRow from './RenderRow'

const RenderElemets = ({
    elements, styles, itemsShiners, darkMode
}) => {
    if (!elements?.length) return null
    return elements?.map((el, index) => {
        if (el === 'loader'){
            return <Loader key={`RenderElemets_${index}`}/>
        }
        if (el?.row){
            return <RenderRow key={`RenderElemets_${index}`} darkMode={darkMode} itemsShiners={itemsShiners} styles={styles} row={el}/>
        }
        if (el?.content){
            return el?.content?.map((item, i) => {
                if (item?.row){
                    return <RenderRow darkMode={darkMode} itemsShiners={itemsShiners} styles={styles} row={item} key={`RenderElemets_${index}_${i}`}/>
                }
                return <RenderElemet darkMode={darkMode} itemsShiners={itemsShiners} styles={styles} element={item} key={`RenderElemets_${index}_${i}`}/>
            })
        }
        return <RenderElemet darkMode={darkMode} itemsShiners={itemsShiners} element={el} styles={styles} key={`RenderElemets_${index}`}/>
    })
}
export default RenderElemets