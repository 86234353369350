// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P, Tooltip } from '../../../hybrid/wrappers';

const PartnerStats = ({
    getHandlers, gridStyles, val, name
}) =>
    <P
        {...getHandlers(false)}
        styles={gridStyles[name]?.wrapper?.styles || { display: 'flex' }}
        effects={gridStyles[name]?.wrapper?.effects || {}}
    >
        {
            Object.keys(val)?.filter(it => !it?.includes('Hint'))
                ?.map((item, idx) => {
                    return (
                        <Tooltip key={`statspartner${idx}`} text={`${val[`${item}Hint`]}` === 'undefined' ? '' : `${val[`${item}Hint`]}`}>
                            <P>{Object.keys(val)?.filter(it => !it?.includes('Hint'))[idx + 1] ? `${val[item]}/` : `${val[item]}`}</P>
                        </Tooltip>
                    )
                })
        }
    </P>
PartnerStats.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string
};

export default PartnerStats;