import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Div, P, svgSprite, Tooltip
} from '../wrappers';
import getStyles from '../../project/styles/views-styles/condition-block-styles';
import Styles from '../../common/decorators/Styles';
import uikit from '../../project/uikit/styles';
import { t } from '../../common/components/T';
import { LangContext } from '../../common/decorators/Language';

const OfficeConditionBlock = ({
    data, styles, key
}) => {
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    return (
        <Div key={key}>
            {
                !!data?.description&&
            <Div styles={{ ...uikit.flexRow }} >
                <P styles={{ ...styles.smallOuput, ...styles.smallColor }}>{data?.description}  </P>
                {
                    !!data.tooltipContent &&
                    <Div styles={uikit.ml2}>
                        <Tooltip
                            text={data.tooltipContent}
                            place='top'
                        >
                            {svgSprite('info-circle', { style: styles.speedHeaderTitleTooltipSvg })}
                        </Tooltip>
                    </Div>
                }
            </Div>
            }
            {data?.items?.map((el, i) =>
                <Div key={i} styles={{ ...styles.conditionBlock, ...data.status && styles.conditionBlockSuccess }}>

                    <Div styles={{ ...uikit.flexRow, ...uikit.alignItemsCenter }}>
                        {svgSprite(el.status ? 'check' : 'cross',
                            { style: { ...styles.orderStatusPayButtonIcon, ...el.status ? styles.iconGreenSvg : styles.iconRedSvg } })}
                        <P styles={styles.conditionBlockText}>{el?.text}</P>
                    </Div>
                    {
                        !!data?.statusDone&&
                    <Div styles={styles.statusDone}>
                        <P styles={{ color: styles.variable.darkColor }}>
                            {
                                data?.statusDone.has + ' ' + t('of', 'из', 'all', translation) + ' ' + data?.statusDone.necessary
                            }
                        </P>
                    </Div>
                    }
                </Div>)}
        </Div>
    )
};
OfficeConditionBlock.propTypes = {
    styles: PropTypes.object,
    key: PropTypes.string,
    data: PropTypes.object
};

export default Styles(OfficeConditionBlock, getStyles);