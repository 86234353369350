// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useCallback, useMemo, useContext, useEffect
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { Div, svgSprite } from '../../../../hybrid/wrappers';
import SearchWindow from '../../../searchWindow/SearchWindow';
import UserInfoBlock from './UserInfoBlock';
import { API_SERVER_URL, getWidgetTranslates } from '../../../../common/utils/utils';

import UserContext, { UserProfileContext } from '../../../../common/utils/getContext';
import FromInput from '../form-input/FormInput';
import { useWindowDimensions } from '../../../../common/utils/windowDimensions';
import { t } from '../../../../common/components/T'
import { getData } from '../../../../common/utils/router';
import CircleLoader from '../../../plug/CircleLoader';
import uikit from '../../../../project/uikit/styles';
import { LangContext } from '../../../../common/decorators/Language';
import { getDefaultVariation } from '../helpers';

const Wrapper = (props) => {
    if (props?.helpButtons) return <Div {...props}>{props.children}</Div>
    return <>{props.children}</>
}
const AdminFormSponsorWidget = (props) => {
    const {
        attribute,
        handlers = {},
        compStyle,
        value,
        setFormData,
        styles,
        customData = {},
        variation,
        title,
        placeholder
    } = props;
    const {
        userToken, setAlertData, lang
    } = useContext(UserContext);
    const profileContext = useContext(UserProfileContext);

    const variationStyle = variation || getDefaultVariation(profileContext, title)

    const [val, setVal] = useState(value);
    const [showSponsor, setShowSponsor] = useState(false);
    const widthDimensions = useWindowDimensions();
    const screenWidth = useMemo(() => widthDimensions < 768, [widthDimensions]);
    const [transl, setTranslates] = useState(null)
    const getUserData = useCallback(async (data) => {
        setAlertData(false);
        if (customData?.transformResultData) {
            setFormData(attribute, customData?.transformResultData(data));
        } else {
            setFormData(attribute, data);
        }
        if (!customData?.url && !customData?.hideUserInfo){
            setVal({ id: data?.id });
        } else {
            setVal(customData?.transformResultData ? customData?.transformResultData(data) : data);
        }
        if (handlers?.onBlur) {
            handlers.onBlur(attribute, data);
        }
        setShowSponsor(false);
    }, [setAlertData, customData, handlers, setFormData, attribute]);

    useEffect(() => {
        if (value?.id && !customData?.url) {
            (async () => {
                const userInfo = await getData(`/api/v1/user/partners/${value.id}`, userToken, lang)
                if (userInfo && userInfo?.data) {
                    setVal(userInfo.data);
                }
            })();
        }
    }, [customData?.url, lang, userToken, value?.id])
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);

    return (
        <>
            <Div styles={{
                ...styles.formGroup, ...styles[`formSelectDataWrap__${variationStyle}`], ...compStyle.wrapper
            }}>
                <Wrapper helpButtons={customData?.helpButtons} styles={styles.formSelectDataButtonsInputWrap}>
                    <FromInput
                        {...props}
                        placeholder={customData?.loading ? <CircleLoader/> : placeholder}
                        handlers={{
                            ...props.handlers || {},
                            onClick: () => setShowSponsor(true)
                        }}
                        noWrapper={true}
                        buttons={{
                            icon: { icon: customData?.icon || 'user' },
                            ...props.buttons || {}
                        }}
                        value={val && val[customData.attrToInputName] ? val[customData.attrToInputName] : val?.username || ''}
                        readonly={true}
                        compStyle={{
                            ...compStyle,
                            input: { ...compStyle?.input, ...customData?.helpButtons ? styles.formSelectDataInput : {} }

                        }}
                    />
                    {
                        customData?.helpButtons ?
                            <Div styles={styles.formSelectDataButtonsWrap}>
                                {
                                    val ?
                                        <>
                                            <Div
                                                styles={{ ...styles.formSelectDataHelpBtn, ...compStyle?.helpBtn || {} }}>
                                                {
                                                    svgSprite('pen-field', {
                                                        width: '18px',
                                                        height: '18px',
                                                        style: { ...styles.formSelectDataHelpBtnSvg }
                                                    })
                                                }
                                            </Div>
                                            <Div
                                                data-uitest='609_uitest' onClick={(event) => {
                                                    event.stopPropagation();
                                                    setVal(null);
                                                    setFormData(attribute, null);
                                                }}
                                                styles={{ ...styles.formSelectDataHelpBtn, ...compStyle?.helpBtn || {} }}>
                                                {
                                                    svgSprite('trash', {
                                                        width: '24px',
                                                        height: '24px',
                                                        style: { ...styles.formSelectDataHelpBtnSvg, ...styles.formSelectDataHelpBtnSvgDanger }
                                                    })
                                                }
                                            </Div>
                                        </>
                                        : null
                                }
                            </Div>
                            : null
                    }
                </Wrapper>
                {
                    !!val && !customData?.hideUserInfo
                        ?
                        <UserInfoBlock
                            data={val}
                            styles={styles}
                            translation={transl}
                        />
                        : null
                }
            </Div>


            {
                showSponsor
                    &&
                    <SearchWindow
                        input={customData?.modalInput || {}}
                        transformSearchData={customData?.transformSearchData || null}
                        thead={customData?.thead || null}
                        portalClass={!screenWidth ? 'modal' : 'modalBottom'}

                        closeModal={setShowSponsor}
                        sendData={{ url: customData?.url || `${API_SERVER_URL}/api/v1/admin/user-management/sponsors` }}
                        title={customData?.modalTitle || t(
                            'Form-ChooseSponsor', 'Выбрать спонсора', 'widget', transl, 'form'
                        )}
                        gridConfig={{ td: { styles: { ...uikit.pt0, ...uikit.pb0 } } }}
                        customData={{ ...customData, buttonNotShow: true }}
                        gridHandlers={{
                            tr: {
                                onClick: (rowData) => {
                                    getUserData(rowData);
                                }
                            }
                        }}
                        fixedWidth={!screenWidth ? { width: '1184px' } : {}}
                        customStyles={compStyle}
                    />

            }
        </>
    );
};

AdminFormSponsorWidget.propTypes = {
    attribute: PropTypes.string,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.object,
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    customData: PropTypes.object,
    variation: PropTypes.string,
    title: PropTypes.object,
    placeholder: PropTypes.node
};

export default Styles(AdminFormSponsorWidget, getStyles);