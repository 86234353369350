export const getDateWebConfig = (
    attribute,
    changeHandler,
    disabled,
    val,
    maxDate,
    minDate,
    showTimeSelect,
    openToDate
) => ({
    ...showTimeSelect ? { onChange: changeHandler } : { onSelect: changeHandler },
    id: attribute,
    selected: val,
    disabled: Boolean(disabled),
    value: val,
    maxDate,
    minDate,
    startDate: maxDate,
    showTimeSelect: showTimeSelect,
    openToDate: openToDate
});