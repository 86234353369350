// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useCallback, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {
    Div, P, FieldError, svgSprite, Tooltip
} from '../../../../hybrid/wrappers';

const FormRadio = ({
    attribute,
    hint,
    disabled,
    title,
    compStyle,
    value,
    setFormData,
    styles,
    errors,
    options
}) => {
    const [val, setVal] = useState(value || value === 0 ? value : null);
    const changeHandler = useCallback((v) => {
        if (!disabled) {
            setFormData(attribute, v);
            setVal(v);
        }
    }, [disabled, setFormData, attribute]);


    useEffect(() => {
        setVal(value || value === 0 ? value : null);
    }, [value])

    const optionsArray = useMemo(() => {
        if (options.length || options.length === 0) return options;
        return [...Object.keys(options).filter((o) => `${o}` !== '0'), ...Object.keys(options).filter((o) => `${o}` === '0')]
    }, [options])

    if (!options) return null;
    return (
        <Div styles={{ ...styles.formGroup, ...compStyle?.wrapper || {} }}>
            <Div styles={{ ...styles.formRadioSwitcher, ...compStyle?.content }}>
                {
                    !!title
                    &&
                    <Div styles={{ ...styles.formRadioSwitcherTitle, ...compStyle?.title }}>
                        {title?.body}
                    </Div>
                }
                <Div
                    styles={{
                        ...styles.formRadioSwitcherToggler,
                        ...compStyle?.togglerBackground
                    }}
                >
                    {
                        optionsArray.map((name, idx) =>
                            <React.Fragment key={`${attribute}option${idx}`}>
                                <Div
                                    onClick={() => changeHandler(options.length ? idx : parseInt(name) || 0)}
                                    styles={{
                                        ...styles.formRadioSwitcherTogglerElement,
                                        ...options.length && (val === idx || +val === idx) || `${val}` === `${name}` ? styles.radioSwitcherTogglerElementActive : {},
                                        ...compStyle?.togglerElement
                                    }}
                                >
                                    <P
                                        styles={{
                                            ...options.length && (val === idx || +val === idx) || `${val}` === `${name}`
                                                ? { ...styles.formRadioSwitcherTogglerText, ...styles.formRadioSwitcherTogglerTextActive }
                                                : { ...styles.formRadioSwitcherTogglerText },
                                            ...compStyle?.togglerElementText
                                        }}
                                    >
                                        {options[name] || name}
                                    </P>
                                </Div>
                            </React.Fragment>)
                    }
                </Div>
                {
                    !!hint
                    &&
                    <Tooltip
                        text={hint}
                        styles={styles.formInfoTooltip}
                    >
                        {svgSprite('info-circle', { style: styles.formInfoIcon })}
                    </Tooltip>

                }

            </Div>

            {
                errors && errors.length ? errors.map((err, idx) => <FieldError styles={styles} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
        </Div>
    );
};

FormRadio.propTypes = {
    attribute: PropTypes.string,
    hint: PropTypes.node,
    disabled: PropTypes.bool,
    title: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    errors: PropTypes.array,
    options: PropTypes.array
};

export default Styles(FormRadio, getStyles);