import { Circle } from 'styled-spinkit'
import getStyles from '../../project/styles/widget-styles/plug-styles';
import Styles from '../../common/decorators/Styles';
import PropTypes from 'prop-types';

const CircleLoader = ({
    size, color, styles
}) => {
    return (
        <Circle
            size={size || '18'}
            color={color || styles.variable.greyExtraDarkColor}
        />
    )
}
CircleLoader.propTypes = {
    styles: PropTypes.object,
    size: PropTypes.string,
    color: PropTypes.string
};

export default Styles(CircleLoader, getStyles);