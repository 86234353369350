import {
    Div, Svg, P
} from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/plug-styles';
import Styles from '../../common/decorators/Styles';
import PropTypes from 'prop-types';

const Loader = ({
    styles, compStyles = {}, type = 'panel'
}) => {
    return (
        <Div styles={{
            ...styles?.mainBlock, ...type === 'panel' ? styles?.mainBlockPanel : {}, ...compStyles?.loader
        }}>
            <Div className='loader-wrapper' styles={styles.wrapper}>
                <Div className='loader-index' styles={styles.index}><P tagName={'span'}/></Div>
                <Svg>
                    <filter id='goo'>
                        <feGaussianBlur in='SourceGraphic' stdDeviation='11' result='blur'/>
                        <feColorMatrix in='blur' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9' result='goo'> </feColorMatrix>
                    </filter>
                </Svg>
            </Div>
        </Div>
    )
}
Loader.propTypes = {
    styles: PropTypes.object,
    compStyles: PropTypes.object,
    type: PropTypes.oneOf(['panel'])
};

export default Styles(Loader, getStyles);