import { useMemo } from 'react';
import { t } from '../components/T';
import { useWidgetTranslates } from './utils';

export const requiredWidgetsList = ['SiteSettings', 'share'];

export const useMonths = () => {
    const trans = useWidgetTranslates('month');

    const months = useMemo(() => [
        t(
            'months1', 'Январь', 'widget', trans, 'month'
        ),
        t(
            'months2', 'Февраль', 'widget', trans, 'month'
        ),
        t(
            'months3', 'Март', 'widget', trans, 'month'
        ),
        t(
            'months4', 'Апрель', 'widget', trans, 'month'
        ),
        t(
            'months5', 'Май', 'widget', trans, 'month'
        ),
        t(
            'months6', 'Июнь', 'widget', trans, 'month'
        ),
        t(
            'months7', 'Июль', 'widget', trans, 'month'
        ),
        t(
            'months8', 'Август', 'widget', trans, 'month'
        ),
        t(
            'months9', 'Сентябрь', 'widget', trans, 'month'
        ),
        t(
            'months10', 'Октябрь', 'widget', trans, 'month'
        ),
        t(
            'months11', 'Ноябрь', 'widget', trans, 'month'
        ),
        t(
            'months12', 'Декабрь', 'widget', trans, 'month'
        )
    ],
    [trans]);
    const shortMonths = useMemo(() => [
        t(
            'months1-short', 'Янв', 'widget', trans, 'month'
        ),
        t(
            'months2-short', 'Фев', 'widget', trans, 'month'
        ),
        t(
            'months3-short', 'Мар', 'widget', trans, 'month'
        ),
        t(
            'months4-short', 'Апр', 'widget', trans, 'month'
        ),
        t(
            'months5', 'Май', 'widget', trans, 'month'
        ),
        t(
            'months6-short', 'Июн', 'widget', trans, 'month'
        ),
        t(
            'months7-short', 'Июл', 'widget', trans, 'month'
        ),
        t(
            'months8-short', 'Авг', 'widget', trans, 'month'
        ),
        t(
            'months9-short', 'Сен', 'widget', trans, 'month'
        ),
        t(
            'months10-short', 'Окт', 'widget', trans, 'month'
        ),
        t(
            'months11-short', 'Ноя', 'widget', trans, 'month'
        ),
        t(
            'months12-short', 'Дек', 'widget', trans, 'month'
        )
    ],
    [trans]);
    return {
        months,
        shortMonths
    };
};

export const countriesPhoneLength = {
    7: ['NU', 'TK'],
    8: ['CK', 'FK', 'KI', 'MM', 'SB', 'TO', 'TV', 'VU'],
    9: ['AD', 'BA', 'FO', 'PF', 'GL', 'ID', 'NC', 'NF', 'KP', 'PH', 'WS', 'SR', 'WF', 'EH'],
    10: [
        'AW',
        'BZ',
        'BT',
        'IO',
        'BN',
        'CV',
        'BQ',
        'KM',
        'CU',
        'CW',
        'DK',
        'ER',
        'EE',
        'FJ',
        'GA',
        'GM',
        'GW',
        'GY',
        'IS',
        'LB',
        'MV',
        'MH',
        'MU',
        'MX',
        'FM',
        'NR',
        'NG',
        'NO',
        'PW',
        'PA',
        'ST',
        'SC',
        'SG',
        'SO',
        'TH',
        'TL',
        'YE',
        'ZW'
    ],
    11: [
        'AF',
        'AS',
        'AI',
        'AG',
        'AM',
        'AU',
        'BS',
        'BH',
        'BD',
        'BB',
        'BE',
        'BJ',
        'BM',
        'BO',
        'BW',
        'VG',
        'BF',
        'BI',
        'KH',
        'CM',
        'CA',
        'KY',
        'CF',
        'TD',
        'CL',
        'CR',
        'CI',
        'HR',
        'CY',
        'DJ',
        'DM',
        'DO',
        'EC',
        'SV',
        'FR',
        'GI',
        'GD',
        'GU',
        'GT',
        'GN',
        'HT',
        'HN',
        'HK',
        'HU',
        'IL',
        'IT',
        'JM',
        'JP',
        'KZ',
        'KW',
        'LA',
        'LV',
        'LS',
        'LR',
        'LY',
        'LT',
        'MO',
        'MK',
        'ML',
        'MT',
        'MR',
        'MD',
        'MC',
        'MN',
        'ME',
        'MS',
        'MZ',
        'NP',
        'NI',
        'NE',
        'MP',
        'OM',
        'PG',
        'PE',
        'PL',
        'PR',
        'QA',
        'RO',
        'RU',
        'KN',
        'LC',
        'VC',
        'SA',
        'SL',
        'SX',
        'SI',
        'ZA',
        'KR',
        'ES',
        'LK',
        'SZ',
        'SE',
        'CH',
        'TW',
        'TG',
        'TT',
        'TN',
        'TM',
        'TC',
        'VI',
        'AE',
        'US',
        'UY',
        'VN'
    ],
    12: [
        'AL',
        'DZ',
        'AO',
        'AR',
        'AT',
        'AZ',
        'BY',
        'BR',
        'BG',
        'CN',
        'CO',
        'CD',
        'CG',
        'CZ',
        'EG',
        'GQ',
        'ET',
        'GF',
        'GE',
        'DE',
        'GH',
        'GR',
        'IN',
        'IR',
        'IE',
        'JO',
        'KE',
        'KG',
        'LU',
        'MG',
        'MW',
        'MY',
        'MQ',
        'MA',
        'NA',
        'NL',
        'NZ',
        'PK',
        'PS',
        'PY',
        'PT',
        'RE',
        'RW',
        'SN',
        'RS',
        'SK',
        'SS',
        'SD',
        'SY',
        'TJ',
        'TZ',
        'TR',
        'UG',
        'UA',
        'GB',
        'UZ',
        'VA',
        'VE',
        'ZM'
    ],
    13: ['FI', 'IQ', 'LI', 'SM']
};

export const packVariantList = [
    {
        id: 1,
        color: '#AC8873',
        leftBg: 'rgba(169, 133, 111, 0.20)',
        gradient: 'linear-gradient(180deg, #926348 0%, #C9A896 100%)',
        blockBg: 'rgba(82, 61, 50, 0.70)',
        image: 'url(../../../../../src/img/office/packages/bg1.jpg)',
        icon: 'one_star',
        bg: 'linear-gradient(180deg, #4C3C36 0%, #A8846E 100%)',
        shadow: '0px 20px 20px -10px rgba(168, 132, 110, 0.50)'
    },
    {
        id: 2,
        color: 'rgba(135, 150, 176, 1)',
        leftBg: 'rgba(135, 150, 176, 0.20)',
        gradient: 'linear-gradient(180deg, #3C4665 0%, #9EA4B9 100%)',
        blockBg: 'rgba(50, 53, 67, 0.70)',
        image: 'url(../../../../../src/img/office/packages/bg2.jpg)',
        icon: 'two_star',
        bg: 'linear-gradient(180deg, #353A43 0%, #8796B0 100%)',
        shadow: '0px 20px 20px -10px rgba(135, 150, 176, 0.50)'
    },
    {
        id: 3,
        color: 'rgba(91, 161, 132, 1)',
        leftBg: 'rgba(91, 161, 132, 0.20)',
        gradient: 'linear-gradient(180deg, #796022 0%, #D5C384 100%)',
        blockBg: 'rgba(31, 45, 39, 0.70)',
        image: 'url(../../../../../src/img/office/packages/bg3.jpg)',
        icon: 'three_star',
        bg: 'linear-gradient(180deg, #2D4439 0%, #5BA184 100%)',
        shadow: '0px 20px 20px -10px rgba(91, 161, 132, 0.50)'
    },
    {
        id: 4,
        color: 'rgba(225, 163, 69, 1)',
        leftBg: 'rgba(225, 163, 69, 0.20)',
        gradient: 'linear-gradient(180deg, #E8900C 0%, #F3D04C 100%)',
        blockBg: 'rgba(58, 36, 7, 0.70)',
        image: 'url(../../../../../src/img/office/packages/bg4.jpg)',
        icon: 'crown',
        bg: 'linear-gradient(180deg, #7A5931 0%, #E1A345 100%)',
        shadow: '0px 20px 20px -10px rgba(225, 163, 69, 0.50)'
    }
];