import React, {
    useState, useEffect, useRef, useMemo, useContext, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../../hybrid/wrappers';
import Styles from '../../../../common/decorators/Styles';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import CustomDatePicker from '../../../../widgets/form/components/form-date/components/CustomDatePicker';
import FormInput from '../form-input/FormInput';
import { t } from '../../../../common/components/T';
import UserContext from '../../../../common/utils/getContext';
import moment from 'moment';
import { LangContext } from '../../../../common/decorators/Language';
import { getWidgetTranslates } from '../../../../common/utils/utils';

const DatePickerRange = (props) => {
    const {
        attribute,
        handlers = {},
        setFormData,
        value,
        styles,
        compStyle = {},
        placeholder,
        mobileRangeDateCustom = false,
        config
    } = props;
    const pickerElement = useRef(null);
    const [val, setVal] = useState(value);
    const [show, setShow] = useState(false);
    const [focused, setFocused] = useState(false)

    const { settings, lang } = useContext(UserContext);

    const [transl, setTranslates] = useState(null)
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);

    const dateFormat = useCallback((dating) => {
        switch (settings?.date_format){
        case '0':
            let string = dating.locale(lang).format('MMMM DD, YYYY')
            return string.charAt(0).toUpperCase() + string.slice(1);
        case '1':
            return dating.format('YYYY-MM-DD');
        case '2':
            return dating.format('MM/DD/YYYY');
        case '3':
            return dating.format('DD.MM.YYYY');
        default:
            return dating.format('DD.MM.YYYY')
        }
    }, [lang, settings])

    const placeholderText = useMemo(() => {
        if (placeholder) return placeholder;
        const double = (text) => text + ' - ' + text
        switch (settings?.date_format){
        case '0':
            return double(t(
                'FormDate-Date1', 'М дд, год', 'widget', transl, 'form'
            ));
        case '1':
            return double(t(
                'FormDate-Date2', 'гггг-мм-дд', 'widget', transl, 'form'
            ));
        case '2':
            return double(t(
                'FormDate-Date3', 'мм/дд/гггг', 'widget', transl, 'form'
            ));
        case '3':
            return double(t(
                'FormDate-Date4', 'дд.мм.гггг', 'widget', transl, 'form'
            ));
        default:
            return double(t(
                'FormDate-Date4', 'дд.мм.гггг', 'widget', transl, 'form'
            ))
        }
    }, [placeholder, settings, transl])

    const handleClose = (dateRangeObj) => {
        setShow(false);
        handlers?.onBlur && handlers?.onBlur(attribute, val);
        if (handlers?.onChange && dateRangeObj) {
            handlers?.onChange(dateRangeObj)
        }
    };

    const handleShow = () => {
        setShow(true);
        handlers?.onFocus && handlers?.onFocus(attribute);
    };

    const getInputValue = useMemo(() => {
        if (!val) return null;
        if (val?.hasOwnProperty('start') && val?.hasOwnProperty('end') && !val?.start && !val?.end) {
            return t(
                'calendar_all_time', 'За все время', 'widget', transl, 'form'
            )
        }
        const start = val.start && moment(val.start, 'yyyy-MM-DD').isValid() ? dateFormat(moment(val.start, 'yyyy-MM-DD')) : val.start
        const end = val.end && moment(val.end, 'yyyy-MM-DD').isValid() ? dateFormat(moment(val.end, 'yyyy-MM-DD')) : val.end

        return `${start || ''}${start && end ? ' - ' : ''}${end || ''}`
    }, [dateFormat, transl, val]);

    useEffect(() => {
        setFormData(attribute, val);
    }, [val, attribute, setFormData]);

    useEffect(() => {
        setVal(value);
    }, [value]);

    return (
        <>
            <Div styles={{ ...styles.formGroup, ...compStyle.wrapper }} data-uitest='22_uitest' onClick={handleShow} onMouseOver={() => { setFocused(true) }} onMouseLeave={() => { setFocused(false) }} childrenRef={pickerElement}>
                <FormInput
                    {...props}
                    noWrapper={true}
                    readonly={true}
                    placeholder={placeholderText}
                    value={getInputValue}
                    compStyle={{
                        ...compStyle,
                        calendar: {
                            button: {
                                width: '24px',
                                height: '24px'
                            },
                            icon: {
                                width: '24px',
                                height: '24px',
                                fill: focused || show ? styles.variable.blueColor : styles.variable.greyExtraDarkColor
                            }
                        }
                    }}
                    hint={false}
                    buttons={{
                        ...props.buttons,
                        calendar: {
                            icon: 'calendar',
                            handler: handleShow,
                            hint: props.hint
                        }
                    }}
                />
            </Div>
            {
                !!show
                    &&
                        <CustomDatePicker
                            handleClose={handleClose}
                            setVal={setVal}
                            pickerElement={pickerElement}
                            mobileRangeDateCustom={mobileRangeDateCustom}
                            config={config}
                        />

            }
        </>
    );
};

DatePickerRange.propTypes = {
    attribute: PropTypes.string,
    handlers: PropTypes.object,
    setFormData: PropTypes.func,
    value: PropTypes.string,
    styles: PropTypes.object,
    compStyle: PropTypes.object,
    placeholder: PropTypes.node
};

export default Styles(DatePickerRange, getStyles);