import {
    svgSprite, Div, FieldError
} from '../../hybrid/wrappers';
import Styles from '../../common/decorators/Styles';
import getStyles from '../../project/styles/widget-styles/contact-styles';
import { useState } from 'react';
import PropTypes from 'prop-types';

const Contact = ({
    attribute,
    options,
    title,
    setFormData,
    compStyle,
    styles,
    errors,
    manyContacts
}) => {
    const [isSelected, setSelected] = useState(false)

    const addContact = (name) => {
        setFormData(attribute, name)
    }

    return (
        <Div styles={{ ...styles.wrapper, ...compStyle?.wrapper }}>
            {title.body &&
                <Div styles={{ ...styles.title, ...compStyle?.title }}>{title.body}</Div>
            }
            {!manyContacts ?
                <Div styles={{ ...styles.mainContainer, ...compStyle?.container }}>
                    {!!options?.length &&
                        options.map(el =>
                            <Div
                                key={`option_${el?.name}`}
                                styles={isSelected === el?.name ?
                                    {
                                        ...styles.defaultContactContainer, ...styles.telegramContainer, ...compStyle?.contact
                                    } : { ...styles.defaultContactContainer, ...compStyle?.contact }}
                                onClick={() => {
                                    addContact(el.name)
                                    if (isSelected === el?.name) setSelected(null)
                                    else setSelected(el.name)
                                }}
                            >
                                {
                                    svgSprite(el.icon, {
                                        width: '20px',
                                        height: '20px',
                                        fill: isSelected === el?.name ? styles.variable.whiteColor : styles.variable.blueColor
                                    })
                                }
                            </Div>)
                    }
                </Div>
                :
                <Div styles={{ ...styles.mainContainer, ...compStyle?.container }}>
                    {!!options?.length &&
                        options.map(el =>
                            <Div
                                key={`option_${el?.name}`}
                                styles={el?.active ?
                                    {
                                        ...styles.defaultContactContainer, ...styles.telegramContainer, ...compStyle?.contact
                                    } : { ...styles.defaultContactContainer, ...compStyle?.contact }}
                                onClick={() => {
                                    addContact(el.name)
                                }}
                            >
                                {
                                    svgSprite(el.icon, {
                                        width: '20px',
                                        height: '20px',
                                        fill: el?.active ? styles.variable.whiteColor : styles.variable.blueColor
                                    })
                                }
                            </Div>)
                    }
                </Div>
            }
            {
                errors && errors.length ? errors.map((err, idx) => <FieldError styles={{ ...styles, ...compStyle?.error || {} }} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
        </Div>
    )
}
Contact.propTypes = {
    attribute: PropTypes.string,
    options: PropTypes.array,
    title: PropTypes.object,
    setFormData: PropTypes.func,
    compStyle: PropTypes.object,
    styles: PropTypes.object,
    errors: PropTypes.array,
    manyContacts: PropTypes.bool
};

export default Styles(Contact, getStyles);