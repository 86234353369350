import React from 'react';
import {
    Svg, Path, G, LinearGradient, Stop
} from '../../hybrid/wrappers';


const SvgSpriteCore = ({
    name, dataStyles, strokeDasharray, strokeDashoffset
}) => {
    let floor;
    switch (name) {
    case 'challenge-progress':
        return (
            <Svg {...dataStyles} fill='none' viewBox='-2770 501.9 129 130'>
                <Path
                    strokeWidth='2.5'
                    strokeDasharray='394'
                    strokeDashoffset='0'
                    d='M-2705.5,503.9c34.5,0,62.5,28.2,62.5,63 c0,9.9-2.2,19.2-6.3,27.5l-1.8,3.5c-10.7,19.1-31.1,32-54.4,32c-21.5,0-40.4-10.9-51.6-27.5l-2.8-4.5c-5.1-9.2-8.1-19.7-8.1-31 c0-32.7,24.7-59.5,56.2-62.7c0.3,0,0.5-0.1,0.8-0.1L-2705.5,503.9z' />
            </Svg>
        );
    case 'challenge-progress-value':
        return (
            <Svg {...dataStyles} fill='none' viewBox='-2770 501.9 129 130'>
                <Path
                    strokeWidth='2.5'
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={strokeDashoffset}
                    d='M-2705.5,503.9c34.5,0,62.5,28.2,62.5,63 c0,9.9-2.2,19.2-6.3,27.5l-1.8,3.5c-10.7,19.1-31.1,32-54.4,32c-21.5,0-40.4-10.9-51.6-27.5l-2.8-4.5c-5.1-9.2-8.1-19.7-8.1-31 c0-32.7,24.7-59.5,56.2-62.7c0.3,0,0.5-0.1,0.8-0.1L-2705.5,503.9z' />
            </Svg>
        );
    case 'speed-progress-colored':
        return (
            <Svg {...dataStyles} viewBox='0 0 177 145' fill='none'>
                <Path d='M25.6289 140C13.9915 125.947 7 107.927 7 88.2778C7 64.4994 17.2389 43.106 33.5597 28.243' stroke={dataStyles.color1 || '#D94763'} strokeWidth='14' />
                <Path d='M33.5597 28.2431C48.0491 15.048 67.3321 7 88.5 7C120.356 7 147.944 25.2275 161.348 51.7951' stroke={dataStyles.color2 || '#FAB031'} strokeWidth='14' />
                <Path d='M151.371 140C163.008 125.947 170 107.927 170 88.2778C170 75.1589 166.883 62.766 161.348 51.7952' stroke={dataStyles.color3 || '#2AB974'} strokeWidth='14' />
            </Svg>
        );
    case 'speed-progress-done':
        floor = `${Math.floor(Math.random() * 1000)}`
        return (
            <Svg {...dataStyles} viewBox='0 0 217 185' fill='none'>
                <G filter={`url(#selfProgressGreen_filter_${floor})`}>
                    <Path d='M45.6289 152C33.9915 137.947 27 119.927 27 100.278C27 76.4994 37.2389 55.106 53.5597 40.2431C68.0492 27.048 87.3322 19 108.5 19C140.356 19 167.944 37.2275 181.348 63.7951C186.883 74.7659 190 87.1589 190 100.278C190 119.927 183.009 137.947 171.371 152' stroke={`url(#selfProgressGreen_gradient_${floor})`} strokeWidth='14' />
                </G>
                <filter id={`selfProgressGreen_filter_${floor}`} x='0' y='0' width='217' height='184.465' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
                    <feOffset dy='8' />
                    <feGaussianBlur stdDeviation='10' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.164706 0 0 0 0 0.72549 0 0 0 0 0.454902 0 0 0 0.6 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
                </filter>
                <LinearGradient id={`selfProgressGreen_gradient_${floor}`} x1='44' y1='152' x2='180' y2='148.5' gradientUnits='userSpaceOnUse'>
                    <Stop stopColor={dataStyles.color1 || '#2AB974'} />
                    <Stop offset='1' stopColor={dataStyles.color2 || '#74DDAA'} />
                </LinearGradient>
            </Svg>
        );
    case 'speed-progress-path':
        return (
            <Svg {...dataStyles} viewBox='0 0 140 115'>
                <Path opacity='0.08' fillRule='evenodd' clipRule='evenodd' d='M135 70C135 85.9124 129.282 100.489 119.789 111.788L123.777 114.814C133.906 102.672 140 87.0479 140 70C140 31.3401 108.66 0 70 0C31.3401 0 0 31.3401 0 70C0 86.9014 5.98993 102.404 15.9627 114.5L19.7708 111.259C10.5414 100.035 5 85.6649 5 70C5 34.1015 34.1015 5 70 5C105.899 5 135 34.1015 135 70Z' />
            </Svg>
        );
    case 'speed-progress-value':
        return (
            <Svg {...dataStyles} viewBox='0 0 140 115' fill='none'>
                <Path strokeDasharray={strokeDasharray} strokeDashoffset={strokeDashoffset} d='M18.9107 113C8.98383 101.357 3 86.3071 3 69.8729C3 32.94 33.2208 3 70.5 3C107.779 3 138 32.94 138 69.8729C138 86.2426 132.063 101.239 122.206 112.863' strokeWidth='4' />
            </Svg>
        );
    case 'speed-progress-arrow':
        floor = `${Math.floor(Math.random() * 1000)}`
        return (
            <Svg {...dataStyles} fill='none' viewBox='0 0 24 3'>
                <Path d='M24 0L24 3L-9.23071e-08 2.11174L0.0213317 1.35028L24 0Z' fill={`url(#arrowSpeedProgress_${floor})`} />
                <LinearGradient id={`arrowSpeedProgress_${floor}`} x1='24' y1='1.5' x2='0.0271403' y2='1.5' gradientUnits='userSpaceOnUse'>
                    <Stop stopColor={dataStyles.fill || '#C4C4C8'} />
                    <Stop offset='1' stopColor={dataStyles.fill || '#C4C4C8'} stopOpacity='0' />
                </LinearGradient>
            </Svg>
        );
    case 'rounded-progress-path':
        return (
            <Svg {...dataStyles} viewBox='-2770 501.9 129 130' fill='none'>
                <Path
                    opacity='0.15'
                    strokeWidth='4'
                    strokeDasharray='394'
                    strokeDashoffset='109'
                    d='M-2705.5,503.9c34.5,0,62.5,28.2,62.5,63 c0,9.9-2.2,19.2-6.3,27.5l-1.8,3.5c-10.7,19.1-31.1,32-54.4,32c-21.5,0-40.4-10.9-51.6-27.5l-2.8-4.5c-5.1-9.2-8.1-19.7-8.1-31 c0-32.7,24.7-59.5,56.2-62.7c0.3,0,0.5-0.1,0.8-0.1L-2705.5,503.9z' />
            </Svg>
        );
    case 'rounded-progress-value':
        return (
            <Svg {...dataStyles} stroke={dataStyles.stroke || '#a93de6'} viewBox='-2770 501.9 129 130' fill='none'>
                <Path
                    strokeWidth='4'
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={strokeDashoffset}
                    d='M-2705.5,503.9c34.5,0,62.5,28.2,62.5,63 c0,9.9-2.2,19.2-6.3,27.5l-1.8,3.5c-10.7,19.1-31.1,32-54.4,32c-21.5,0-40.4-10.9-51.6-27.5l-2.8-4.5c-5.1-9.2-8.1-19.7-8.1-31 c0-32.7,24.7-59.5,56.2-62.7c0.3,0,0.5-0.1,0.8-0.1L-2705.5,503.9z' />
            </Svg>
        );
    case 'progressBarNode':
        return (
            <Svg {...dataStyles} viewBox='0 0 122 97' fill='none'>
                <Path strokeDasharray='394' strokeDashoffset='109' opacity='0.15' d='M109.574 94.5C116.149 84.9842 120 73.4418 120 61C120 28.4152 93.5848 2 61 2C28.4152 2 2 28.4152 2 61C2 73.4418 5.85117 84.9842 12.4265 94.5' stroke={dataStyles.fill || '#282359'} strokeWidth='4' strokeLinecap='round'/>
                <Path strokeDasharray={strokeDasharray} strokeDashoffset={strokeDashoffset} d='M109.574 94.5C116.149 84.9842 120 73.4418 120 61C120 28.4152 93.5848 2 61 2C28.4152 2 2 28.4152 2 61C2 73.4418 5.85117 84.9842 12.4265 94.5' stroke={dataStyles.stroke || '#a93de6'} strokeWidth='4' strokeLinecap='round'/>
            </Svg>
        );

    default:
        return null;
    }
};

export default SvgSpriteCore;