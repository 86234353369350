export const contactImg = (val) => {
    switch (val) {
    case 'email':
    case 'mail':
        return 'email-colored';
    case 'phone':
        return 'phone-colored';
    case 'facebook':
        return 'facebook-colored';
    case 'vk':
        return 'vkontakte-colored';
    case 'telegram':
        return 'telegram-colored';
    case 'whatsapp':
        return 'whatsapp-colored';
    case 'instagram':
        return 'instagram-colored';
    case 'twitter':
        return 'twitter-colored';
    case 'viber':
        return 'viber-colored';
    default:
        return 'phone';
    }
};
export const getSocial = (data) => {
    return [
        {
            name: 'mail',
            link: data?.email ? `mailto:${data?.email}`:''
        },
        {
            name: 'phone',
            link: data?.phone ? `tel:${data?.phone}`:''
        },
        {
            name: 'facebook',
            link: data?.facebook ? `https://www.facebook.com/${data.facebook}`:''
        },
        {
            name: 'instagram',
            link: data?.instagram ? `https://instagram.com/${data.instagram}`:''
        },
        {
            name: 'vk',
            link: data?.vk ? `https://www.vk.com/${data.vk}`:''
        },
        {
            name: 'telegram',
            link: data?.telegram ? `https://t.me/${data.telegram}`:''
        },
        {
            name: 'whatsapp',
            link: data?.whatsapp ? `https://api.whatsapp.com/send?phone=${data.whatsapp}`:''
        },
        {
            name: 'twitter',
            link: data?.twitter ? `https://twitter.com/${data.twitter}`:''
        },
        {
            name: 'viber',
            link: data?.viber ?`viber://chat?number=${data.viber}`:''
        }

    ]
}