import React, { useContext } from 'react'
import { Div } from '../../../hybrid/wrappers'
import UserContext from '../../../common/utils/getContext'
import moment from 'moment'

const TimePass = ({ val }) => {
    const { time, lang } = useContext(UserContext)

    const time2 = typeof val == 'number' ? val : moment(val, 'DD.MM.YYYY HH:mm', false).unix()

    return (
        <Div>{moment.duration(-((time().unix() - time2) * 1000), 'milliseconds').locale(lang)
            .humanize(true)}</Div>
    )
}
export default TimePass