const mongoose = require('mongoose');

const LinksSchema = new mongoose.Schema({
    destroy: { type: String },
    update: { type: String },
    copy: { type: String },
    view: { type: String }
});
const SeoSchema = new mongoose.Schema({
    metaTitle: { type: String },
    metaKeywords: { type: String },
    metaDescription: { type: String }
})
const PageSchema = new mongoose.Schema({
    id: { type: Number },
    name: { type: String },
    url: {
        type: String,
        required: [true, 'Необходимо заполнить поле'],
        unique: true
    },
    date: {
        type: Date,
        required: [true, 'Необходимо заполнить поле']
    },
    lastUpdate: { type: Date },
    status: { type: Boolean },
    copy: { type: Boolean },
    visibility: { type: Object },
    template: { type: String },
    links: { type: LinksSchema },
    title: {
        type: Object,
        required: [true, 'Необходимо заполнить поле']
    },
    pageContent: { type: Object },
    seo: { type: SeoSchema },
    createdLang: { type: String }
});
module.exports = mongoose.models?.createdpages || mongoose.model('createdpages', PageSchema);