// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useCallback, useMemo, useEffect
} from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { getDateWebConfig } from './initData';
import FormDateCalendar from './components/FormDateCalendar';
import FormDateTimeCalendar from './components/FormDateTimeCalendar';

const FormDate = (props) => {
    const {
        attribute,
        disabled,
        value,
        setFormData,
        options,
        openToDate,
        showTimeSelect = false
    } = props;
    const [val, setVal] = useState(value
        ? moment(new Date(value), showTimeSelect ? 'yyyy-MM-DD HH:mm' : 'yyyy-MM-DD').isValid() ? moment(new Date(value), showTimeSelect ? 'yyyy-MM-DD HH:mm' : 'yyyy-MM-DD').toDate() : null
        : null);

    const changeHandler = useCallback((v) => {
        if (!disabled) {
            setFormData(attribute, v ? moment(v, showTimeSelect ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY').format(showTimeSelect ? 'yyyy-MM-DD HH:mm' : 'yyyy-MM-DD') : '');
            setVal(v);
        }
    }, [disabled, setFormData, attribute, showTimeSelect]);

    const componentConfig = useMemo(() =>
        getDateWebConfig(
            attribute,
            changeHandler,
            disabled,
            val,
            options?.max_date ? moment(options?.max_date, 'yyyy-MM-DD').toDate() : '',
            options?.min_date ? moment(options?.min_date, 'yyyy-MM-DD').toDate() : '',
            showTimeSelect,
            openToDate
        )
    , [attribute, changeHandler, disabled, val, options, showTimeSelect, openToDate]);
    const componentConfigTime = useMemo(() =>
        getDateWebConfig(
            `${attribute}_time`,
            changeHandler,
            disabled,
            val,
            options?.max_date ? moment(options?.max_date, 'yyyy-MM-DD').toDate() : '',
            options?.min_date ? moment(options?.min_date, 'yyyy-MM-DD').toDate() : '',
            showTimeSelect
        )
    , [attribute, changeHandler, disabled, val, options, showTimeSelect]);

    useEffect(() => {
        setVal(value
            ? moment(new Date(value), showTimeSelect ? 'yyyy-MM-DD HH:mm' : 'yyyy-MM-DD').isValid() ? moment(new Date(value), showTimeSelect ? 'yyyy-MM-DD HH:mm' : 'yyyy-MM-DD').toDate() : null
            : null)
    }, [value, showTimeSelect]);

    if (showTimeSelect){
        return (
            <FormDateTimeCalendar
                {...props}
                value={val}
                componentConfig={componentConfig}
                componentConfigTime={componentConfigTime}
            />
        )
    }
    return (
        <FormDateCalendar
            {...props}
            value={val}
            componentConfig={componentConfig}
        />
    )
};

FormDate.propTypes = {
    attribute: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    setFormData: PropTypes.func,
    options: PropTypes.object,
    openToDate: PropTypes.string,
    showTimeSelect: PropTypes.bool
};

export default Styles(FormDate, getStyles);