import { useState } from 'react';
import {
    Div, P, Portal, svgSprite
} from '../../../../hybrid/wrappers'
import TTMaps from '../../../ttmaps/TTMaps'
import Styles from '../../../../common/decorators/Styles';
import getStyles from '../../../../project/styles/widget-styles/maps-styles';
import PropTypes from 'prop-types';

const FormMap = ({
    styles, setDisabledClick, attribute, handlers, textButton, setFormData, compStyle
}) => {
    const [active, setActive] = useState(false);

    return <><Div
        styles={{ ...styles?.buttonWrapper, ...compStyle?.button }}
        onClick={() => {
            setDisabledClick(true)
            handlers?.onFocus && handlers?.onFocus(attribute)
            setActive(!active)
        }}
    >
        <Div styles={styles.svgWrapper}>
            {
                svgSprite('pin', {
                    width: '20px',
                    height: '20px',
                    fill: styles.variable.blueColor
                })
            }
        </Div>
        <P styles={{ margin: '0 6px', 'line-height': '20px' }}>{textButton}</P>
    </Div>
    {active ?
        <Portal closeHandler={() => {
            setActive(false);
            setDisabledClick(false)
        }}>
            <Div styles={{
                height: '90%',
                width: '90%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                'z-index': 100,
                overflow: 'hidden'
            }}>
                <TTMaps
                    onClick={(e) => {
                        const { lng, lat } = e?.lngLat;
                        setActive(false)

                        setFormData(attribute, {
                            id: e?.point?.x,
                            lat, lng
                        })
                        handlers?.onBlur && handlers?.onBlur(attribute);
                        setTimeout(() => {
                            setDisabledClick(false)
                        }, 200)
                    }}
                />
            </Div>
        </Portal> : null

    }
    </>
}
FormMap.propTypes = {
    styles: PropTypes.object,
    setDisabledClick: PropTypes.func,
    attribute: PropTypes.string,
    handlers: PropTypes.object,
    textButton: PropTypes.node,
    setFormData: PropTypes.func,
    compStyle: PropTypes.object
};


export default Styles(FormMap, getStyles);