// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useContext, useEffect
} from 'react';
import {
    Div, FieldError, FieldInfo
} from '../../../../../hybrid/wrappers';
import { t } from '../../../../../common/components/T'
import UserContext from '../../../../../common/utils/getContext';
import DatePicker from './DatePicker'
import { LangContext } from '../../../../../common/decorators/Language';
import { getWidgetTranslates } from '../../../../../common/utils/utils';

const FormDateCalendar = (props) => {
    const {
        title, styles, compStyle, attribute, componentConfigTime, variation, info, errors
    } = props;
    const { lang } = useContext(UserContext);
    const [transl, setTranslates] = useState(null)
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);

    const fieldStyle = {
        ...props.compStyle,
        wrapper: { 'margin-bottom': '0', ...props.compStyle?.fieldWrapper },
        info: { wrapper: { display: 'none' } },
        error: { wrapper: { display: 'none' } }
    }
    return (
        <Div styles={{ ...styles.formGroup, ...compStyle?.wrapper || {} }}>
            <Div styles={{ ...styles.formDateTimeWrapper, ...compStyle?.fields }}>
                <Div styles={{ ...styles.formDateTimeD, ...compStyle?.date }}>
                    <DatePicker {...props} compStyle={fieldStyle} variation={variation || 'separated_title'}/>
                </Div>
                <Div styles={{ ...styles.formDateTimeT, ...compStyle?.time }}>
                    <DatePicker
                        {...props}
                        title={title?.time || variation ? title : false}
                        isTime={true}
                        compStyle={fieldStyle}
                        placeholder={null}
                        componentConfig={{
                            ...componentConfigTime,
                            ...{
                                timeCaption: t(
                                    'FormDate-time', 'Время', 'widget', transl, 'form'
                                ),
                                id: `${attribute}_time`
                            }
                        }}
                    />
                </Div>
            </Div>
            {
                !!info
                    && <FieldInfo styles={{ ...styles, ...compStyle?.info ? compStyle?.info : {} }}>{info}</FieldInfo>
            }
            {
                errors && errors.length ? errors.map((err, idx) => <FieldError styles={{ ...styles, ...compStyle?.error || {} }} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
        </Div>
    );
};

export default FormDateCalendar;