import React from 'react';
import {
    svgSprite, Portal, Div, P
} from '../../hybrid/wrappers';
import QRCode from 'react-qr-code';
import getStyles from '../../project/styles/widget-styles/qr-styles';
import Styles from '../../common/decorators/Styles';
import { useWindowDimensions } from '../../common/utils/windowDimensions';
import PropTypes from 'prop-types';

const QrCode = ({
    styles, title, closeHandler, val, subtitle, customStyles, size
}) => {
    const widthDimensions = useWindowDimensions();

    return (
        <Portal customClass={widthDimensions < 768 ? 'modalBottom' : 'modal'} fullWidth={widthDimensions < 768} closeHandler={closeHandler}>
            <Div styles={styles.modalQrWrapper}>
                <Div styles={styles.modalQrHeader}>
                    {
                        widthDimensions < 768 ?
                            <Div data-uitest='669_uitest' onClick={() => { closeHandler(false) }} styles={styles.qrCodDecor}/>
                            :
                            <Div
                                data-uitest='670_uitest' onClick={() => closeHandler(false)}
                                styles={styles.modalQrClose}
                            >
                                {svgSprite('cross', {
                                    style: styles.modalQrCloseSvg,
                                    fill: styles.variable.greyExtraDarkColor
                                })}
                            </Div>

                    }
                    <P styles={styles.modalQrTitle}>
                        {title}
                    </P>
                </Div>
                <Div styles={styles.modalQrBody}>
                    {subtitle && <P styles={styles.modalQrSub}>{subtitle}</P>}
                    <Div styles={{ ...styles.qrCod, ...customStyles?.qrCod || {} }}>
                        <QRCode size={size || 256} bgColor={styles.variable.greyExtraDarkColorTransparent} value={val} />
                    </Div>
                </Div>
            </Div>
        </Portal>
    )
};

QrCode.propTypes = {
    styles: PropTypes.object,
    title: PropTypes.node,
    closeHandler: PropTypes.func,
    val: PropTypes.string,
    subtitle: PropTypes.node,
    customStyles: PropTypes.object,
    size: PropTypes.number
}
export default Styles(QrCode, getStyles);