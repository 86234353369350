import React from 'react'
import {
    Div, Img, svgSprite
} from '../../hybrid/wrappers'

const CartItemWidget = ({
    styles,
    item
}) => {
    return (
        <Div styles={styles?.cartItemWidgetImage}>
            {
                item?.product?.image !== null ? <Img styles={styles?.cartItemWidgetImageInner} src={item?.product?.image?.links?.thumb} alt='image'/>
                    : svgSprite('plug-grid-image', { width: '48px', height: '48px' })
            }
        </Div>
    )
}
export default CartItemWidget;