import { Div } from '../../../../hybrid/wrappers';
import uikit from '../../../../project/uikit/styles';
import RenderElemet from './RenderElemet';

const RenderRow = ({
    row, styles, itemsShiners, darkMode
}) => {
    return <Div styles={{
        ...uikit.flexRow, ...row?.vertical !== 'unset' ? uikit.alignItemsCenter : {},
        ...row?.align !== 'left'
            ? row?.align === 'center' ? uikit.justifyContentCenter : uikit.justifyContentBetween
            : {}, ...uikit.flexWrap,
        ...row?.gap ? { ...uikit[`ml${row?.gap}_minus`], ...uikit[`mr${row?.gap}_minus`] } : {},
        ...row?.indent ? { ...uikit[`pl${row?.indent}`], ...uikit[`pr${row?.indent}`] } : {}
    }}>
        {
            row?.row.map((width, index) => {
                const item = row?.items?.[index] || row?.items?.[0];

                return <Div key={`RenderRow_${index}`} styles={{
                    width: width === 'auto' ? width : `${width}%`,
                    ...row?.gap ? { ...uikit[`pl${row?.gap}`], ...uikit[`pr${row?.gap}`] } : {},
                    ...item !== 'empty' ? row?.itemsStyles : {},
                    ...itemsShiners ? { ...uikit.positionRelative, overflow: 'hidden' } : {}
                }}>
                    <Div key={`RenderRow_${index}`} styles={itemsShiners ? { ...uikit.positionRelative, overflow: 'hidden' } : {}}>
                        {itemsShiners ? <Div styles={{ ...styles.shiner, ...darkMode ? styles.shiner_dark : {} }} className='shine'/> : null}
                        {
                            item?.row
                                ? <RenderRow darkMode={darkMode} styles={styles} row={item}/>
                                : item?.content
                                    ? item?.content?.map((contentItem, i) => {
                                        if (contentItem?.row){
                                            return <RenderRow darkMode={darkMode} styles={styles} row={contentItem} key={`RenderElemets_${index}_${i}`}/>
                                        }
                                        return <RenderElemet darkMode={darkMode} styles={styles} element={contentItem} key={`RenderElemets_${index}_${i}`}/>
                                    })
                                    : <RenderElemet darkMode={darkMode} styles={styles} element={item}/>
                        }
                    </Div>
                </Div>
            })
        }
    </Div>
}
export default RenderRow;