// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import {
    Div, svgSprite, Img
} from '../../../hybrid/wrappers';

const Image = ({ styles, val }) => {
    return (
        <Div styles={styles.imageGrid}>
            {
                !val?.url && !val?.links?.thumb && !val?.links?.preview ? svgSprite('plug-product-image', { width: '48px', height: '48px' }) : <Img styles={styles.imageGridItem} src={val?.url || val?.links?.thumb || val?.links?.preview} alt='image'/>
            }
        </Div>
    )
};

Image.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    styles: PropTypes.object,
    name: PropTypes.string,
    link: PropTypes.string,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ])
};

export default Image;