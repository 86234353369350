// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../hybrid/wrappers';
import SingleButton from './SingleButton';
import Styles from '../../../common/decorators/Styles';
import getStyles from '../../../project/styles/widget-styles/form-styles';
import T from '../../../common/components/T';

const FormButtons = ({
    formName,
    buttons,
    sendFormData,
    styles,
    customFormButton,
    buttonText,
    tabsData
}) =>
    <Div styles={{
        ...styles.formBottomButtonGroup,
        ...customFormButton?.isFullWidth ? styles.formBottomButtonGroupLarge : {},
        ...customFormButton?.wrapper ? customFormButton?.wrapper : {}
    }}
    >
        <Div styles={{ ...styles.formGroupButtonItemWrap, ...customFormButton?.flex || {} }}>
            {
                !(buttons && Array.isArray(buttons) && buttons.length) ?
                    <SingleButton
                        key={`${formName}buttonOk`}
                        customStyles={styles}
                        type='ok'
                        clickFunc={sendFormData}
                        buttonText={buttonText || <T textName='send' defaultStr='Отправить' page='all'/>}
                        customButton={customFormButton?.ok}
                    />
                    :
                    buttons.map((item, idx) =>
                        <SingleButton
                            key={`${formName}button${idx}`}
                            customStyles={styles}
                            type={item.type}
                            clickFunc={
                                item.type === 'ok' ?
                                    sendFormData
                                    : tabsData && item.type === 'next'
                                        ? () => {
                                            if (tabsData.nextAlias !== 'end') {
                                                tabsData.setActiveTab(tabsData.nextAlias)
                                                tabsData.setCountNextTab(tabsData.countNextTab + 1)
                                            }
                                        }
                                        : item.clickFunc
                            }
                            buttonText={
                                item.buttonText
                                    ? item.buttonText
                                    : item.type === 'ok'
                                        ? <T textName='send' defaultStr='Отправить' page='all'/>
                                        : <T textName='cancel' defaultStr='Отмена' page='all'/>
                            }
                            customButton={customFormButton ? customFormButton[item.type] : false}
                            icon={item?.icon}
                            arrow={item?.arrow}
                        />)
            }
        </Div>
    </Div>;

FormButtons.propTypes = {
    formName: PropTypes.string,
    buttons: PropTypes.array,
    sendFormData: PropTypes.func,
    styles: PropTypes.object,
    customFormButton: PropTypes.object,
    buttonText: PropTypes.node,
    tabsData: PropTypes.object
};

export default Styles(FormButtons, getStyles);