import { Cell, Row } from 'react-sticky-table';
import { camelizeStyles } from '../../../common/utils/utils';
import {
    TR, Tbody, TD, Thead
} from '../../../hybrid/wrappers';

export const TbodyComponent = ({ sticky, children }) => {
    if (sticky) return <>{children}</>
    return <Tbody>{children}</Tbody>
}
export const TrComponent = (props) => {
    const {
        sticky, children, styles
    } = props;
    if (sticky && !props.colSpan) return <Row {...props} style={camelizeStyles(styles)}>{children}</Row>
    return <TR {...props}>{children}</TR>
}
export const TdComponent = (props) => {
    const {
        sticky, children, styles
    } = props;
    if (sticky && !props.colSpan) return <Cell style={camelizeStyles(styles)} {...props}>{children}</Cell>
    return <TD {...props}>{children}</TD>
}

export const TheadComponent = ({ sticky, children }) => {
    if (sticky) return <>{children}</>
    return <Thead>{children}</Thead>
}
export const TrHeadComponent = ({
    sticky, children, styles, rowRef
}) => {
    if (sticky) return <Row ref={rowRef} style={camelizeStyles(styles)}>{children}</Row>
    return <TR styles={styles}>{children}</TR>
}
export const TdHeadComponent = ({
    sticky, children, styles, key, effects
}) => {
    if (sticky) return <Cell style={camelizeStyles(styles)} key={key} effects={effects}>{children}</Cell>
    return <TD styles={styles} key={key} effects={effects}>{children}</TD>
}