// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P, Div } from '../../../hybrid/wrappers';
import uikit from '../../../project/uikit/styles';

const Sums = ({
    getHandlers, gridStyles, name, val, styles
}) =>
    <Div
        {...getHandlers(false)}
        styles={gridStyles[name]?.wrapper?.styles || { ...styles.flexColumn, ...styles.alignItemsCenter }}
        effects={gridStyles[name]?.wrapper?.effects || {}}
    >
        <P
            styles={{ ...uikit.nowrap, ...gridStyles[name]?.count?.styles || {} }}
            effects={gridStyles[name]?.count?.effects || {}}
        >
            {val.currencySymbol}
            {val.currencySum}
        </P>
        <P
            styles={{ ...styles.dimentions || {}, ...gridStyles[name]?.dimentions?.styles || {} }}
            effects={gridStyles[name]?.dimentions?.effects || {}}
        >
            {val.pvSum}
        </P>
    </Div>;
Sums.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ]),
    styles: PropTypes.object,
    name: PropTypes.string
};

export default Sums;