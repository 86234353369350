import React from 'react';
import { unscrollableBody } from '../../../common/utils/windowDimensions';

const WrapperModalPortal = ({ children, unscrollable = true }) => {
    if (unscrollable){
        unscrollableBody(children)
    }

    return (
        <>
            {children}
        </>
    );
};

export default WrapperModalPortal;