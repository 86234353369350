// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P, Div } from '../../../hybrid/wrappers';

const NewsTitle = ({
    gridStyles, getHandlers, val, name, styles, link, align
}) => {
    if (val === null) return <P>-</P>
    return (
        <Div
            styles={gridStyles[name]?.wrapper?.styles || (align === 'left'
                ? { ...styles.flexColumn, ...styles.alignItemsStart }
                : align === 'right'
                    ? { ...styles.flexColumn, ...styles.alignItemsEnd }
                    : { ...styles.flexColumn, ...styles.alignItemsCenter })}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >

            {
                val.categories &&
                <Div
                    {...getHandlers(false)}
                    styles={gridStyles[name]?.icoWrap1?.styles ? gridStyles[name]?.icoWrap1?.styles :
                        {
                            ...styles.userNameBlock, padding: '4px 6px', 'display': 'flex'
                        } }
                    effects={gridStyles[name]?.icoWrap1?.effects || {
                        hover: !!link && styles.formCursorPointer,
                        focus: !!link && styles.formCursorPointer
                    }
                    }
                >

                    <P styles={{ 'white-space': 'nowrap', ...gridStyles[name]?.textWrap?.styles }}>{`${val.categories.join(', ') || val}`}</P>
                </Div >
            }

            <P
                styles={{
                    ...gridStyles[name]?.fio?.styles, ...styles?.buyerName, ...{ 'margin-top': val?.categories ? '0px' : '0' }
                }}
                effects={gridStyles[name]?.fio?.effects || {}
                }
            >

                <P>
                    {val.title ? val.title : '-'}
                </P>

            </P>
        </Div >
    )
};

NewsTitle.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    styles: PropTypes.object,
    name: PropTypes.string
};

export default NewsTitle;