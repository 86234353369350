import officeStyles from './office/content-alert-styles'
import mainStyles from './main/content-alert-styles'
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,
    marginBottom16: { ...uikit.mb3 },
    textWrapWeb: { ...uikit.text },
    alertErrorIcon: {
        width: 16,
        height: 16,
        fill: variable.redColor,
        marginLeft: 6,
        flexShrink: 0,
        transform: 'translateY(3px)'
    }
});
export default stylesheets;