// @generated: @expo/next-adapter@2.1.5
import React, { useCallback } from 'react';
import {
    Div, P, svgSprite, Tooltip
} from '../../../../hybrid/wrappers';
import T, { t } from '../../../../common/components/T';

const Switcher = ({
    inp, setInp, styles, title, disabled, setFormData, attribute, switchHint, translation
}) => {
    const changeInputHandler = useCallback((v, statusInput) => {
        if (!disabled) {
            const valCor = v.length > 150 ? v.substr(0, 149) : v;
            let value = valCor;
            if (value.length === 0 && statusInput)value = null;
            setFormData(attribute, value);
            setInp({ val: value, showInputPhone: statusInput });
        }
    }, [disabled, setFormData, attribute, setInp]);

    const changeTabHandler = useCallback(() => {
        if (!disabled) { changeInputHandler('', !inp.showInputPhone); }
    }, [disabled, inp, changeInputHandler]);

    return (
        <Div styles={styles.formSponsorBlock}>
            {
                !!title
                &&
                    <Div styles={styles.formSponsorTextBlock}>
                        <P styles={styles.formSponsorBlockTitle} >
                            {title.body}
                        </P>
                        <Div styles={{ position: 'relative' }}>
                            <Tooltip
                                text={(
                                    <P styles={styles.formSponsorTooltip}>
                                        {switchHint || t(
                                            'FormLoginWidget-switchHint', 'Введите ваш номер телефона или ID под которым Вы зарегистрировались на сайте', 'widget', translation, 'form'
                                        )}
                                    </P>
                                )}
                                styles={styles.formSponsorIconWrap}
                                place='top'
                                // className="customPosition"
                            >
                                {svgSprite('info-circle', { style: styles.formInfoIcon })}
                            </Tooltip>
                        </Div>

                    </Div>

            }
            <Div
                data-uitest='900_uitest' onClick={changeTabHandler}
                styles={styles.formSponsorTab}
                activeOpacity={1}
            >
                <Div styles={inp.showInputPhone ? {
                    ...styles.formSponsorTabElement,
                    left: '2px',
                    transition: '.3s'
                } : {
                    ...styles.formSponsorTabElement, left: 'calc(100% - 70px)', transition: '.3s'
                }}
                />
                <Div
                    styles={{ ...styles.formSponsorTabText, 'z-index': '1' }}
                >
                    <P styles={inp.showInputPhone ? styles.formSponsorTabTextActive : styles.formSponsorTabTextNotActive}><T textName='yes' defaultStr='Тел.' page='all'/></P>
                    <P styles={!inp.showInputPhone ? styles.formSponsorTabTextActive : styles.formSponsorTabTextNotActive}><T textName='no' defaultStr='ID' page='all'/></P>
                </Div>
            </Div>
        </Div>
    );
};

export default Switcher;