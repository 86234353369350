import React, { useContext, useCallback } from 'react';
import UserContext, { UserProfileContext } from '../utils/getContext';
import moment from 'moment';
import 'moment/locale/ru';
import PropTypes from 'prop-types';

const Display = ({ type, data }) => {
    const { settings, lang } = useContext(UserContext);
    const { adminPage } = useContext(UserProfileContext) || {};

    const transformFIO = useCallback(function (data) {
        const firstName = `${data.first_name}` !== 'undefined' && data.first_name !== null && data.first_name ? `${data.first_name} ` : '';
        const lastName = `${data.last_name}` !== 'undefined' && data.last_name !== null && data.last_name ? `${data.last_name} ` : '';
        const patronymic = `${data.patronymic}` !== 'undefined' && data.patronymic !== null && data.patronymic ? `${data.patronymic} ` : '';

        if (settings?.name_format && !adminPage){
            switch (settings.name_format){
            case '0':
                return (
                    <>{(lastName + firstName + patronymic).trim()}</>
                );
            case '1':
                return (
                    <>{(firstName + lastName + patronymic).trim()}</>
                );
            case '2':
                return (
                    <>{((firstName ? `${firstName.substring(0, 1)}. ` : '') + (patronymic ? `${patronymic.substring(0, 1)}. `: null) + lastName).trim()}</>
                );
            case '3':
                return (
                    <>{(lastName + (firstName ? `${firstName.trim().substring(0, 1)}. ` : '') + (patronymic ? `${patronymic.trim().substring(0, 1)}. ` : '')).trim()}</>
                );
            case '4':
                return (
                    <>{(firstName + lastName).trim()}</>
                );
            case '5':
                return (
                    <>{(lastName + firstName).trim()}</>
                );
            default:
                return (
                    <>{(lastName + firstName + patronymic).trim()}</>
                )
            }
        } else {
            return <>{(lastName + firstName + patronymic).trim()}</>
        }
    }, [adminPage, settings]);
    const getTimeWithOffset = useCallback((data) => {
        let dating = moment(data.date || data.time, data.format);
        if (settings?.timezone && data.utc){
            let timezone;
            let plus;
            if (settings?.timezone.indexOf('-') !== -1){
                timezone = settings?.timezone.slice(settings?.timezone.indexOf('-') + 1, settings?.timezone.indexOf(')'))
                plus = true;
            }
            if (settings?.timezone.indexOf('+') !== -1){
                timezone = settings?.timezone.slice(settings?.timezone.indexOf('+') + 1, settings?.timezone.indexOf(')'))
            }
            if (!isNaN(+timezone)){
                dating = dating.add(plus ? +timezone : 0 - +timezone, 'hours')
            }
        }
        return dating
    }, [settings])
    const transformDate = useCallback(function (data) {
        if (settings?.date_format && data.date && moment(data.date, data.format).isValid()){
            let dating = getTimeWithOffset(data)
            switch (settings?.date_format){
            case '0':
                let string = dating.locale(lang).format('MMMM DD, YYYY')
                return <>{string.charAt(0).toUpperCase() + string.slice(1)}</>;
            case '1':
                return <>{dating.format('YYYY-MM-DD')}</>;
            case '2':
                return <>{dating.format('MM/DD/YYYY')}</>;
            case '3':
                return <>{dating.format('DD.MM.YYYY')}</>;
            default:
                return <>{dating.format('DD.MM.YYYY')}</>
            }
        } else if (data.date && moment(data.date, data.format).isValid()) {
            return <>{moment(data.date, data.format).format('DD.MM.YYYY')}</>
        } else {
            return <></>
        }
    }, [settings, getTimeWithOffset, lang]);
    const transformTime = useCallback(function (data) {
        if (settings?.time_format && data.time && moment(data.time, data.format).isValid()){
            const time = getTimeWithOffset(data).locale('en');
            switch (settings?.time_format){
            case '0':
                return <>{time.format('h:mm a')}</>;
            case '1':
                return <>{time.format('h:mm A')}</>;
            case '2':
                return <>{time.format('HH:mm')}</>;
            default:
                return <>{time.format('HH:mm')}</>
            }
        } else if (data.time && moment(data.time, 'HH:mm').isValid()) {
            const time = moment(data.time, 'HH:mm').locale('en');
            return <>{time.format('HH:mm')}</>
        } else {
            return <></>
        }
    }, [settings, getTimeWithOffset]);
    const transformNumber = useCallback(function (data) {
        let currencySymbol = data.currency && data.currency.symbol ? data.currency.symbol : '';
        let currencyAbbr = data.currency && data.currency.code ? data.currency.code : '';
        let number = data.value ? data.formatted ? data.value : parseFloat(data.value) : 0;
        if (isNaN(number)){
            return <></>
        }
        if (!data.value || !data.formatted){
            let split = `${number}`.split('.');
            let int = split[0] ? split[0] : '';
            let float = split[1] ? split[1] : '00';

            let separator = ',';

            if (settings?.currency_thousands){
                switch (settings.currency_thousands) {
                case '0':
                    separator = '.';
                    break;
                case '1':
                    separator = ',';
                    break;
                case '2':
                    separator = ' ';
                    break;
                case '3':
                    separator = '';
                }
            }

            int = int.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + separator);

            if (settings?.currency_delimiter && float.length > 0){
                if (data && data?.necessarilyDot){
                    number = `${int}.${float}`
                } else {
                    switch (settings.currency_delimiter) {
                    case '0':
                        number = `${int}.${float}`;
                        break;
                    case '1':
                        number = `${int},${float}`;
                        break;
                    default:
                        number = `${int}.${float}`;
                    }
                }
            } else {
                number = int;
            }
        }

        if (settings?.currency_format && data.currency){
            switch (settings.currency_format) {
            case '0':
                number = `${currencySymbol} ${number}`;
                break;
            case '1':
                number = `${number} ${currencySymbol}`;
                break;
            case '2':
                number = `${currencyAbbr} ${number}`;
                break;
            case '3':
                number = `${number} ${currencyAbbr}`;
                break;
            default:
                number = `${currencySymbol} ${number}`;
            }
        } else if (data.currency) {
            number = `${currencySymbol} ${number}`;
        }
        return number;
    }, [settings]);

    switch (type) {
    case 'fio':
        return transformFIO(data);
    case 'value':
        return transformNumber(data);
    case 'date':
        return transformDate(data);
    case 'time':
        return transformTime(data);
    default:
        return <></>;
    }
};

Display.propTypes = {
    data: PropTypes.object,
    type: PropTypes.oneOf(['fio', 'value', 'date', 'time'])
};

export default Display;