import React, { useContext } from 'react'
import { t } from '../../common/components/T';
import { useTimer } from 'react-timer-hook';
import UserContext from '../../common/utils/getContext';
import { Div } from '../../hybrid/wrappers'

const Timer = ({
    date, styles, translation
}) => {
    const { setAlertData } = useContext(UserContext);

    const { seconds, minutes } = useTimer({
        expiryTimestamp: date,
        onExpire: () => {
            setAlertData({
                type: 'error', cont: t(
                    '2fa_timeOut', 'Время вышло', 'widget', translation, 'modal2FA'
                )
            })
        }
    });

    return <Div styles={styles.blockTime}>{`${minutes} : ${seconds < 10 ? `0${seconds}`: seconds}`}</Div>
}
export default Timer;