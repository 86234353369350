// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {
    Div, FieldError, P, svgSprite, Tooltip
} from '../../../../hybrid/wrappers';

const FormRadioCircles = ({
    title,
    attribute,
    disabled,
    handlers = {},
    compStyle,
    value,
    setFormData,
    styles,
    errors,
    options
}) => {
    const [val, setVal] = useState(value || 0);
    const changeHandler = useCallback((v) => {
        if (!disabled) {
            setFormData(attribute, v);
            setVal(v);
            if (handlers?.onChange) handlers.onChange(attribute, v)
        }
    }, [disabled, setFormData, attribute, handlers]);

    useEffect(() => {
        setVal(value)
    }, [value]);

    const checkIsActive = (item, idx) => item?.value ? item?.value === val : Number(val) === idx && val !== null
    return (
        <Div styles={compStyle?.wrapper || {}}>
            {title?.body && <P styles={{ 'margin-bottom': '10px', ...compStyle?.title }}>{title.body}</P>}
            {title?.info && <Div>{title?.info}</Div>}
            <Div styles={compStyle?.itemWrapper || {}}>
                {
                    options.map((item, idx) =>
                        <React.Fragment key={`${attribute}option${idx}`}>
                            <Div
                                {...{
                                    key: String(Date.now()),
                                    onClick: () => changeHandler(item?.value || idx)
                                }}
                                styles={{ ...styles.formRadio, ...compStyle?.radio }}
                            >
                                <Div styles={checkIsActive(item, idx) ? { ...styles.formRadioCircleWrap, ...styles.formRadioCircleWrapActive } : { ...styles.formRadioCircleWrap }}>
                                    {checkIsActive(item, idx) && <Div styles={styles.formRadioCircle} />}
                                </Div>
                                {item?.title || item}
                                {item?.hint ?
                                    <Tooltip
                                        text={item?.hint}
                                        styles={{
                                            display: 'flex', 'align-items': 'center', 'justify-content': 'center', 'margin-left': '12px'
                                        }}
                                    >
                                        {svgSprite('info-circle', {
                                            style: {
                                                width: '15px', height: '15px', fill: styles.variable.greyExtraDarkColor
                                            }
                                        })}
                                    </Tooltip>
                                    : null}

                            </Div>
                        </React.Fragment>)
                }
            </Div>

            {
                errors && errors.length ? errors.map((err, idx) => <FieldError styles={styles} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
        </Div>
    );
};

FormRadioCircles.propTypes = {
    title: PropTypes.object,
    attribute: PropTypes.string,
    disabled: PropTypes.bool,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    errors: PropTypes.array,
    options: PropTypes.array
};

export default Styles(FormRadioCircles, getStyles);