import { Div, svgSprite } from '../../../../../hybrid/wrappers';
import uikit from '../../../../../project/uikit/styles';
import FormInput from '../../form-input/FormInput';

const EditTitlteOfCheckbox = ({
    setShowInput, setNewTitle, styles, title, saveEditTitle, attribute, newTitle, number
}) => {
    return (
        <Div styles={uikit.flexRow}>
            <FormInput
                attribute='title'
                type='input'
                compStyle={{ wrapper: { 'margin-bottom': '0' } }}
                handlers={{}}
                setFormData={
                    ((key, value) => {
                        setNewTitle(value);
                    })
                }
                value={title}
            />
            <Div styles={{
                ...uikit.flexRow,
                ...uikit.alignItemsCenter,
                'margin-left': '8px'
            }}>
                <Div
                    effects={{ hover: styles.formCheckboxSwitcherEditIconHover }}
                    styles={{ padding: '8px 11px' }}
                    data-uitest='564_uitest' onClick={() => {
                        saveEditTitle(attribute, newTitle || title, number)
                        setShowInput(false);
                    }}
                >
                    {svgSprite('check', { style: styles.formCheckboxSwitcherEditIconCheck })}
                </Div>
                <Div
                    effects={{ hover: styles.formCheckboxSwitcherEditIconHover }}
                    styles={{ padding: '8px 11px' }}
                    data-uitest='565_uitest' onClick={() => {
                        setShowInput(false);
                        setNewTitle(title);
                    }}
                >
                    {svgSprite('cross', { style: styles.formCheckboxSwitcherEditIconClose })}
                </Div>
            </Div>
        </Div>
    )
}

export default EditTitlteOfCheckbox;