// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { P, svgSprite } from '../../../hybrid/wrappers';

const Check = ({
    val, getHandlers, gridStyles, name
}) => {
    return (
        <P
            {...getHandlers(false)}
            styles={gridStyles[name]?.wrapper?.styles || {}}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            {
                svgSprite(Boolean(val) ? 'angle-up' : 'angle-down',
                    gridStyles[name]?.ico?.styles ||
                        {
                            fill: `${gridStyles.thead}${val}`,
                            width: '15px',
                            height: '10px'
                        })
            }
        </P>
    );
};

Check.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    name: PropTypes.string
};

export default Check;