import React from 'react';
import SvgSpriteCore from '../../project/svg/Sprite_Objects'
import SvgSpriteError from '../../project/svg/Sprite_Error'
import SvgSpriteLogo from '../../project/svg/Sprite_Logo'
import SvgSpriteMenu from '../../project/svg/Sprite_Menu'
import SvgSpriteMarketing from '../../project/svg/Sprite_Marketing'
import SvgSpriteProgress from '../../project/svg/Sprite_Progress'
import SvgSpriteSocial from '../../project/svg/Sprite_Social'
import SvgSpriteRank from '../../project/svg/Sprite_Rank';
import SvgSpriteAuth from '../../project/svg/Sprite_Colored';
import SvgSpriteSymbols from '../../project/svg/Sprite_Symbols';
import PropTypes from 'prop-types';

const SvgSpriteWeb = (props) =>
    <>
        <SvgSpriteLogo {...props} />
        <SvgSpriteCore {...props}/>
        <SvgSpriteError {...props}/>
        <SvgSpriteMenu {...props}/>
        <SvgSpriteProgress {...props}/>
        <SvgSpriteMarketing {...props}/>
        <SvgSpriteSocial {...props}/>
        <SvgSpriteRank {...props} />
        <SvgSpriteAuth {...props} />
        <SvgSpriteSymbols {...props}/>
    </>

SvgSpriteWeb.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    dataStyles: PropTypes.object,
    strokeDasharray: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    strokeDashoffset: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};
export default SvgSpriteWeb;