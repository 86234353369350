// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import FormComponent from '../../form/FormComponent';

const Switcher = ({ val, getHandlers }) => {
    return (
        <FormComponent
            funcs={{ switch: getHandlers(false) }}
            data={[
                {
                    title: {},
                    attribute: 'switch',
                    name: 'switch',
                    type: 'checkbox-switcher',
                    value: typeof val === 'object' ? val.val : val,
                    disabled: typeof val === 'object' ? val.disabled : false,
                    pending: typeof val === 'object' ? val.pending : false,
                    hint: typeof val === 'object' ? val.hint : false
                }
            ]}
            formStyles={{ switch: { label: { 'margin-bottom': '0px' } } }}
            buttonNotShow={true}
        />
    )
};

Switcher.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    name: PropTypes.string
};

export default Switcher;