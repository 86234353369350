// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useRef, useMemo, useCallback, useContext, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../../../hybrid/wrappers';
import FormInput from '../../form-input/FormInput';
import CustomPicker from './CustomPicker'
import moment from 'moment';
import UserContext from '../../../../../common/utils/getContext';
import { t } from '../../../../../common/components/T';
import { LangContext } from '../../../../../common/decorators/Language';
import { getWidgetTranslates } from '../../../../../common/utils/utils';

const DatePickerForm = (props) => {
    const {
        compStyle, styles, handlers = {}, attribute, value, showTimeSelect, isTime, placeholder
    } = props;
    const pickerElement = useRef()
    const { settings, lang } = useContext(UserContext);
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    const [transl, setTranslates] = useState(null)

    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);

    const dateFormat = useCallback((dating) => {
        if (isTime){
            const time = dating.locale('en');
            switch (settings?.time_format){
            case '0':
                return time.format('h:mm a');
            case '1':
                return time.format('h:mm A');
            case '2':
                return time.format('HH:mm');
            default:
                return time.format('HH:mm')
            }
        } else {
            switch (settings?.date_format){
            case '0':
                let string = dating.locale(lang).format('MMMM DD, YYYY')
                return string.charAt(0).toUpperCase() + string.slice(1);
            case '1':
                return dating.format('YYYY-MM-DD');
            case '2':
                return dating.format('MM/DD/YYYY');
            case '3':
                return dating.format('DD.MM.YYYY');
            default:
                return dating.format('DD.MM.YYYY')
            }
        }
    }, [isTime, lang, settings])

    const placeholderText = useMemo(() => {
        if (placeholder) return placeholder
        if (isTime){
            switch (settings?.time_format){
            case '0':
                return '00:00 pm';
            case '1':
                return '00:00 PM';
            case '2':
                return '00:00';
            default:
                return '00:00'
            }
        } else {
            switch (settings?.date_format){
            case '0':
                return t(
                    'FormDate-Date1', 'М дд, год', 'widget', transl, 'form'
                );
            case '1':
                return t(
                    'FormDate-Date2', 'гггг-мм-дд', 'widget', transl, 'form'
                );
            case '2':
                return t(
                    'FormDate-Date3', 'мм/дд/гггг', 'widget', transl, 'form'
                );
            case '3':
                return t(
                    'FormDate-Date4', 'дд.мм.гггг', 'widget', transl, 'form'
                );
            default:
                return t(
                    'FormDate-Date4', 'дд.мм.гггг', 'widget', transl, 'form'
                )
            }
        }
    }, [isTime, placeholder, settings, transl])
    const [focused, setFocused] = useState(false)
    const openCalendar = () => {
        setFocused(true);
        !!handlers?.onFocus && handlers?.onFocus(attribute, value ? moment(value, showTimeSelect ? 'DD.MM.yyyy HH:mm' : 'DD.MM.yyyy').format(showTimeSelect ? 'yyyy-MM-DD HH:mm' : 'yyyy-MM-DD') : null)
    }
    const formatValue = useMemo(() => {
        if (!props?.value) return null
        const toMoment = moment(props.value, showTimeSelect ? 'yyyy-MM-DD HH:mm' : 'yyyy-MM-DD');
        return toMoment.isValid() ? dateFormat(toMoment) : null
    }, [dateFormat, props?.value, showTimeSelect])

    return (
        <>
            <Div childrenRef={pickerElement} styles={{ ...styles.formGroup, ...compStyle?.wrapper || {} }}>
                <FormInput
                    {...props}
                    handlers={{
                        ...props.handlers || {},
                        onClick: () => openCalendar()
                    }}
                    value={formatValue}
                    placeholder={placeholderText}
                    noWrapper={true}
                    readonly={true}
                    compStyle={{
                        ...props.compStyle,
                        calendar: {
                            ...props.compStyle?.calendar,
                            icon: {
                                width: '20px',
                                height: '20px',
                                fill: focused ? styles.variable.blueColor : styles.variable.greyExtraDarkColor,
                                ...props.compStyle?.calendar?.icon
                            }
                        }
                    }}
                    buttons={{
                        ...props.buttons,
                        calendar: { icon: isTime ? 'clock' : 'calendar' }
                    }}
                />
            </Div>
            {
                focused ?<CustomPicker {...props} pickerElement={pickerElement} setFocused={setFocused}/>: null
            }
        </>
    );
};

DatePickerForm.propTypes = {
    compStyle: PropTypes.object,
    styles: PropTypes.object,
    handlers: PropTypes.object,
    attribute: PropTypes.string,
    value: PropTypes.string,
    showTimeSelect: PropTypes.bool,
    isTime: PropTypes.bool,
    placeholder: PropTypes.node
};

export default DatePickerForm;