import officeStyles from './office/deletemodal-styles'
import mainStyles from './main/deletemodal-styles'
import { media } from '../../../common/utils/utils';
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,
    //    modalWindow
    modalWrapper: {
        width: '576px',
        'max-width': '100%',
        background: variable.whiteColor,
        'border-radius': '20px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        position: 'relative',
        display: 'flex',
        'flex-direction': 'column'
    },
    modalClose: {
        position: 'absolute',
        top: '24px',
        right: '30px',
        'cursor': 'pointer'
    },
    modalCloseSvg: {
        fill: variable.greyExtraDarkColor,
        width: '14px',
        height: '14px'
    },
    modalTitle: {
        color: variable.darkColor,
        ...uikit.title,
        'text-align': 'center',
        'font-weight': '500',
        'margin-left': 'auto',
        'margin-right': 'auto'
    },
    modalHeader: media(width, height, 'w', {
        768: {
            'padding-top': '32px',
            'padding-left': '32px',
            'padding-right': '32px'
        },
        0: {
            'padding-top': '14px',
            'padding-left': '16px',
            'padding-right': '16px'
        }
    }),
    modalBody: media(width, height, 'w', {
        768: {
            'text-align': 'center',
            'padding-top': '16px',
            'padding-bottom': '32px',
            'padding-left': '32px',
            'padding-right': '32px'
        },
        0: {
            'text-align': 'center',
            'padding-top': '15px',
            'padding-bottom': '12px',
            'padding-left': '12px',
            'padding-right': '12px'
        }
    }),
    modalBodyText: {
        'text-align': 'center',
        'text-align': 'center',
        'margin-left': 'auto',
        'margin-right': 'auto',
        'line-height': '1.5'

    },
    modalFooter: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'padding-left': '32px',
            'padding-right': '32px',
            'padding-bottom': '32px'
        },
        0: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'padding-left': '12px',
            'padding-right': '12px',
            'padding-bottom': '12px'
        }
    }),
    formButtonPrimary: {
        ...uikit.button,
        'background-color': variable.orangeColor,
        'min-width': '140px',
        ...uikit.subtitle,
        'font-weight': '500',
        color: variable.whiteColor,
        cursor: 'pointer'
    },
    buttonGray: {
        ...uikit.button,
        'background-color': variable.greyExtraLightColor,
        color: variable.darkColor,
        cursor: 'pointer',
        'margin-right': '12px',
        'min-width': '140px',
        ...uikit.subtitle,
        'font-weight': '500'
    },
    formGroupWrapper: media(width, height, 'w', {
        768: {
            'margin': '0',
            'position': 'relative',
            'align-self': 'flex-end',
            'flex-grow': 0
        },
        0: {
            'margin': '0',
            'position': 'relative'
        }
    }),
    formGroupWrapperNative: {
        flexDirection: 'column',
        justifyContent: 'center'
    },
    titleInputWeb: {
        left: '26px',
        'flex-grow': '1',
        'max-height': '24px',
        ...uikit.text,
        color: variable.darkColor
    },
    formButtonPrimaryHover: { background: variable.orangeDarkColor },
    buttonGrayHover: { background: variable.greyExtraDarkColorTransparent },
    modalWarningWrapper: media(width, height, 'w', {
        768: {
            'padding': '24px',
            'background': variable.greyExtraLightColor,
            'border-radius': '8px',
            'margin-top': '24px',
            'text-align': 'left'
        },
        0: {
            'padding': '24px',
            'background': variable.greyExtraLightColor,
            'border-radius': '8px',
            'margin-top': '12px',
            'text-align': 'left'
        }
    }),
    modalWarningTitle: {
        'font-weight': '500',
        'line-height': '1.5'
    },
    modalWarningList: { 'list-style': 'none' },
    modalWarningListItem: {
        'margin-top': '15px',
        'display': 'flex'
    },

    modalWarningListIco: {
        'fill': variable.redColor,
        width: '14px',
        height: '14px',
        'margin-right': '19px',
        'margin-left': '5px'
    },

    modalCheckListIco: {
        'fill': variable.greenColor,
        width: '14px',
        height: '14px',
        'margin-right': '19px',
        'margin-left': '5px'
    },

    modalWarningUserIco: {
        width: '20px',
        'margin-right': '8px'
    },

    modalUserNameWrapper: {
        'display': 'flex',
        'justify-content': 'center',
        'margin-top': '4px'

    },

    modalUserName: { 'font-weight': '500' },
    modalFullName: {
        'font-weight': '500',
        'margin-left': '13px'

    },

    formButWrapper: media(width, height, 'w', {
        768: {},
        0: {
            'border-radius': '8px',
            'height': '40px',
            ...uikit.small
        }
    })

//    END modalWindow
});
export default stylesheets;