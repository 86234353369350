// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useEffect, useRef, useContext
} from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/grid-styles';
import Pagination from './components/pagination/Pagination';
import ShowMoreBtn from './components/showMoreBtn/ShowMoreBtn';
import Styles from '../../common/decorators/Styles';
import GridTable from './components/GridTable';
import { getWidgetTranslates } from '../../common/utils/utils';
import UserContext from '../../common/utils/getContext';
import { LangContext } from '../../common/decorators/Language';

const Grid = ({
    gridName,
    setGridData,
    gridStyles = {},
    styles,
    setViewData,
    viewData,
    viewThead,
    cellWidthArray,
    cellThAddButton,
    convertData,
    handlers = {},
    variant = 2,
    openSwitcher,
    setUrl,
    pageSettings = {},
    sticky = false,
    fullScreen = false,
    showThead = true,
    component,
    addBtn,
    token,
    checkAdmin,
    changePosition,
    draggable
}) => {
    const [tableData, setTableData] = useState(viewData);
    const paginationLinks = tableData?.links;
    const paginationMeta = tableData?.meta;
    const refTable = useRef(null);
    const refTableChild = useRef(null);
    const { lang } = useContext(UserContext);
    const [transl, setTranslates] = useState(null)

    useEffect(() => {
        setTableData(viewData);
    }, [viewData]);
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    useEffect(() => {
        getWidgetTranslates(lang, 'grid', translation, setTranslates);
    }, [lang, translation]);

    if (!tableData || !viewData) return null

    const tableProps = {
        styles, gridName, setGridData, cellThAddButton, viewThead, viewData, cellWidthArray, refTable,
        tableData, openSwitcher, handlers, variant, gridStyles, showThead, fullScreen, convertData, refTableChild, checkAdmin, translation: transl, changePosition, draggable
    }

    return (
        <Div id={`${gridName}portalDropdown`} className={sticky && fullScreen ? 'sticky-table-fullscreen' : ''} styles={{ ...styles.gridWrapper, ...gridStyles.gridWrapper }} childrenRef={refTable} >
            <Div
                className='custom-scroll'
                childrenRef={refTableChild}
                conttype={gridStyles?.tableWrapper ? gridStyles?.tableWrapper.conttype : 'scrollHorizontal'}
                styles={{
                    ...gridStyles?.tableWrapper
                        ? { ...styles.gridWrapperBorderRadius, ...gridStyles?.tableWrapper.wrapper }
                        : {
                            ...styles.gridWrapperOverflow, ...styles.gridWrapperBorderRadius,
                            ...sticky
                                ? { overflow: 'auto', ...fullScreen && { 'max-height': `calc(100vh - ${variant === 1 ? '116' : '164'}px)` } }
                                : {}
                        }
                }}
            >
                {
                    pageSettings?.view
                        ?
                        <pageSettings.view
                            data={tableData}

                            gridStyles={gridStyles}
                            params={pageSettings?.params}
                        />
                        : <GridTable sticky={sticky} {...tableProps}/>
                }
                {component ? component : null}
            </Div>

            {
                !!paginationLinks && !!paginationMeta && paginationMeta.links.length > 3 && variant !== 3
                &&
                    <Div styles={styles.marginTop24}>
                        <Pagination
                            smallPagination={pageSettings.smallPagination || false}
                            setViewData={setViewData}
                            links={paginationLinks} // ссылки для пагинации
                            meta={paginationMeta} // значения для пагинатора
                            setTableData={setTableData} // сеттер хука в который будут подставляться новые данные с пагинации
                            thead={tableData?.thead} // передаю заголовок таблицы, ибо с бэка приходит только тело, а в компонент таблицы для рендера нужно передавать и шапку и тело
                            convertData={convertData}
                            setUrl={setUrl}
                            gridStyles={gridStyles}
                            token={token}
                        />
                    </Div>

            }
            {
                variant == 3 && addBtn &&
                    <ShowMoreBtn
                        setViewData={setViewData}
                        convertData={convertData}
                        setTableData={setViewData || setTableData} // сеттер хука в который будут подставляться новые данные с пагинации
                        paginationLinks={paginationLinks}
                        convertEndUrl={addBtn.convertEndUrl}
                        url={addBtn.url}
                        showMoreEndUrl={addBtn.showMoreEndUrl}
                        setShowMoreEndUrl={addBtn.setShowMoreEndUrl}
                        textClose={addBtn.textClose || ''}
                        textOpen={addBtn.textOpen || ''}
                        filterArr={addBtn.filterArr}
                        count={addBtn.count}
                        translation={transl}
                        token={token}
                    />
            }
        </Div>
    );
};

Grid.propTypes = {
    gridName: PropTypes.string,
    setGridData: PropTypes.func,
    gridStyles: PropTypes.object,
    styles: PropTypes.object,
    setViewData: PropTypes.func,
    viewData: PropTypes.object,
    viewThead: PropTypes.object,
    cellWidthArray: PropTypes.array,
    cellThAddButton: PropTypes.array,
    convertData: PropTypes.func,
    handlers: PropTypes.object,
    variant: PropTypes.oneOf(['1', '2', '3', 1, 2, 3]),
    openSwitcher: PropTypes.object,
    setUrl: PropTypes.func,
    pageSettings: PropTypes.object,
    sticky: PropTypes.bool,
    fullScreen: PropTypes.bool,
    showThead: PropTypes.bool,
    addBtn: PropTypes.object,
    token: PropTypes.string
};

export default Styles(Grid, getStyles);