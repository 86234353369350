// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import Title from './Title';
import {
    Div, Label, P, Tooltip, svgSprite
} from '../../../hybrid/wrappers';

const FromInputTitle = ({
    id,
    styles,
    errors,
    title,
    compStyle,
    hint,
    variationStyle,
    focused,
    isFilled,
    required
}) => {
    return (
        <Div
            styles={
                {
                    ...styles[`formTitle__${variationStyle}`],
                    ...compStyle?.title || {},
                    ...errors && errors.length
                        ? { ...styles.formTitleError, ...compStyle?.titleError || {} } : {
                            ...isFilled || focused
                                ? { ...styles.formTitleFocus, ...compStyle?.titleFocus || {} } : {}
                        }
                }
            }
        >
            <Title
                Comp={Label}
                body={title.body}
                links={title.links}

                data={{ htmlFor: id, styles: { ...compStyle?.titleLabel || {} } }}
            />

            { !!required && <P styles={{
                ...styles.formControlRequiredMark, ...compStyle?.requiredMark, ...!errors && focused ? { ...styles.formControlRequiredMarkFocus, ...compStyle?.requiredMarkFocus } : {}
            }}>*</P>}
            {
                !!hint
            &&
                <Tooltip
                    text={hint}
                    styles={styles.formInfoTooltip}
                    className={title.hintClassName || ''}
                    place={title.hintPlace || ''}
                >
                    {svgSprite('info-circle', { style: { ...styles.formInfoTooltipSvg, ...compStyle?.hintIcon } })}
                </Tooltip>

            }
        </Div>
    )
}
FromInputTitle.propTypes = {
    id: PropTypes.string,
    styles: PropTypes.object,
    errors: PropTypes.array,
    title: PropTypes.object,
    compStyle: PropTypes.object,
    hint: PropTypes.node,
    variationStyle: PropTypes.string,
    focused: PropTypes.bool,
    isFilled: PropTypes.bool,
    required: PropTypes.bool
};
export default FromInputTitle;