// @generated: @expo/next-adapter@2.1.5
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Social from './SocialWeb';
import {
    Div, P, svgSprite, Img
} from '../../../hybrid/wrappers';
import T, { t } from '../../../common/components/T';
import { rankColors } from '../../../project/uikit/color-variables';
import { getStatusProgress } from '../../../common/utils/utils';
import Display from '../../../common/components/Display';
import Loader from '../../plug/Loader';

const UserData = ({
    styles,
    data,
    trans,
    loaderStyles,
    isSponsor = false
}) => {
    const rankColor = useMemo(() => {
        if (data.rank) {
            return rankColors[data.rank.sort] || rankColors[0]
        }
        return rankColors[0]
    }, [data]);
    const progress = useMemo(() => getStatusProgress(data.rankData), [data]);

    return <>
        <Div styles={styles.userModalTop}>
            <Div styles={styles.faceBlock}>
                {svgSprite('rounded-progress-path',
                    {
                        style: styles.progressDefault,
                        width: '122',
                        height: '122',
                        stroke: styles.variable.darkColor
                    })}
                {
                    !isSponsor ? svgSprite('rounded-progress-value',
                        {
                            style: { ...styles.progressBar, ...data.package?.sort ? {} : { opacity: '0.15' } },
                            width: '122',
                            height: '122',
                            stroke: rankColor.color
                        },
                        'UserData',
                        [progress.dasharray, progress.dashoffset]) : null
                }
                <Div styles={styles.userPhotoWrapper}>
                    {data?.image?.links?.preview
                        ? <Img styles={styles.userAvatarStyle} src={data?.image?.links?.preview} />
                        :
                        svgSprite('rank0', {
                            width: '100px',
                            height: '100px',
                            ...data.loaded === false ? { style: { filter: 'blur(4px)' } } : {}
                        })

                    }
                </Div>
                {
                    !isSponsor ? data.rank?.name || data.loaded !== false ? <Div styles={{ ...styles.userPosition, background: rankColor.gradient }}>
                        {data.rank?.name ? data.rank.name : t(
                            'user_modal_Noqul', 'Без квалификации', 'widget', trans, 'userModal'
                        )}
                    </Div> : null : null
                }
            </Div>
            <Div styles={styles.userModalTopInfo}>
                {
                    !isSponsor ? <Div styles={styles.userModalTopUsername}>
                        {/* {
                            data.activity ?
                                svgSprite('check-circle-fill', { style: styles.userModalTopUsernameSvg })
                                : data.loaded === false ? null :
                                    svgSprite('cross-circle-fill', { fill: styles.variable.redColor, style: styles.userModalTopUsernameSvgRed })

                        } */}
                        {data.username}
                    </Div> : null
                }
                <Div styles={styles.userModalTopName}>
                    {
                        //data.full_name ? data?.full_name? :
                        <Display type='fio' data={{
                            first_name: data?.first_name || data?.profile?.first_name
                                ? data?.first_name
                                    ? data?.first_name.charAt(0).toUpperCase() + data?.first_name.slice(1)
                                    : data?.profile?.first_name.charAt(0).toUpperCase() + data?.profile?.first_name.slice(1)
                                : '',
                            last_name: data?.last_name || data?.profile?.last_name
                                ? data?.profile?.last_name
                                    ? data?.profile?.last_name.charAt(0).toUpperCase() + data?.profile?.last_name.slice(1)
                                    : data?.last_name.charAt(0).toUpperCase() + data?.last_name.slice(1)
                                : '',
                            patronymic: data?.patronymic || data?.profile?.patronymic
                                ? data?.profile?.patronymic
                                    ? data?.profile?.patronymic.charAt(0).toUpperCase() + data?.profile?.patronymic.slice(1)
                                    : data?.patronymic.charAt(0).toUpperCase() + data?.patronymic.slice(1)
                                : ''
                        }} />
                    }
                </Div>
                {
                    !isSponsor ? data.loaded !== false || data.package?.name ? <Div styles={{ ...styles.userModalTopStatus, 'align-items': 'center' }}>
                        <P styles={styles.userModalTopStatusText}>
                            {t(
                                'user_modal_package', 'Пакет', 'widget', trans, 'userModal'
                            )}:
                        </P>

                        <Div styles={{
                            ...styles.userPosition,
                            ...styles[`package_${data?.package?.sort || null}`],
                            position: 'relative',
                            margin: '0',
                            'box-shadow': 'none',
                            display: 'flex',
                            'justify-content': 'flex-end',
                            'align-items': 'center',
                            padding: '4px 10px'
                        }}>
                            <Div styles={{
                                display: 'flex',
                                'justify-content': 'center',
                                'align-items': 'center',
                                'margin-right': '6px'
                            }}>
                                {svgSprite(`pack_${data?.package?.sort || null}`)}
                            </Div>
                            <Div>{data?.package?.name ? data?.package?.name : <T textName='Absent_pack' defaultStr='Отсутствует' page='all'/>}</Div>
                        </Div>
                    </Div> : null : null
                }
            </Div>
        </Div>
        {data.contacts ?
            <Social
                links={data.contacts}
                can_view_partner={data.can_view_partner || true}
            />
            : data.loaded === false ? <Loader compStyles={loaderStyles} /> : null}
    </>
};
UserData.propTypes = {
    styles: PropTypes.object,
    nowStatus: PropTypes.string,
    username: PropTypes.string,
    fio: PropTypes.string,
    pack: PropTypes.string
};
export default UserData;