// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import {
    Div, FakeLink, svgSprite, Tooltip
} from '../../../hybrid/wrappers';
import Styles from '../../../common/decorators/Styles';
import getStyles from '../../../project/styles/widget-styles/admin-user-modal-social-styles';
import { contactImg } from '../initData';
import Slider from 'react-slick';
import SocialWebButton from './SocialWebButton';

const contactInfo = [
    {
        link: 'mailto:',
        type: 'email'
    },
    {
        link: 'tel:',
        type: 'phone'
    },
    {
        link: 'https://www.facebook.com/',
        type: 'facebook'
    },
    {
        link: 'https://instagram.com/',
        type: 'instagram'
    },
    {
        link: 'https://www.vk.com/',
        type: 'vk'
    },
    {
        link: 'https://t.me/',
        type: 'telegram'
    },
    {
        link: 'https://api.whatsapp.com/send?phone=',
        type: 'whatsapp'
    },
    {
        link: 'https://twitter.com/',
        type: 'twitter'
    },
    {
        link: 'viber://chat?number=',
        type: 'viber'
    }
]
const socialGenerator = (item) => {
    if (!item) return [];
    let arrSocial = []
    contactInfo.map((contact) => {
        if (item[contact.type]) {
            arrSocial.push({
                name: contact.type,
                link: contact.link + item[contact.type],
                text: item[contact.type]
            })
        }
    })
    return arrSocial
}
const Social = ({ links, styles }) => {
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        nextArrow: <SocialWebButton styles={styles} type='next'/>,
        prevArrow: <SocialWebButton styles={styles} type='prev'/>,
        initialSlide: 0
    }
    const items = links && socialGenerator(links)

    if (!items?.length) return null
    return (
        <Div styles={styles.socialBlock}>
            {items.length < 9 ? <Div styles={{ ...styles.socialWrap, ...items.length < 3 ? { 'justify-content': 'flex-start' } : {} }}>
                {items.map((item, idx) => <Div styles={styles.socialItem} key={`socialItem${idx}`}>
                    <Tooltip place='top' text={item.text} className={'zIndex10000'}>
                        <FakeLink
                            href={item.link}
                            styles={{ display: 'flex' }}
                            target={'_blank'}
                            title=''
                        >
                            {svgSprite(contactImg(item.name), { style: styles.contactIcon })}
                        </FakeLink>
                    </Tooltip>
                </Div>)}
            </Div> : <Slider {...settings}>
                {
                    items.map((item, idx) =>
                        <Div styles={styles.socialItem} key={`socialItem${idx}`} >
                            <Tooltip place='top' text={item.text} className={'zIndex10000'}>
                                <FakeLink
                                    href={item.link}
                                    styles={{ display: 'flex' }}
                                    target={'_blank'}
                                    title=''
                                >
                                    {svgSprite(contactImg(item.name), { style: styles.contactIcon })}
                                </FakeLink>
                            </Tooltip>
                        </Div>)
                }
            </Slider>}
        </Div>
    )
};
Social.propTypes = {
    styles: PropTypes.object,
    links: PropTypes.array
};
export default Styles(Social, getStyles);