const mongoose = require('mongoose');

const PageTransSchema = new mongoose.Schema({
    default: { type: String, index: true },
    textName: { type: String, index: true },
    trans: {}
});
const TransSchema = new mongoose.Schema([{
    page: { type: String, index: true },
    group: { type: String, index: true },
    data: { type: PageTransSchema }
}], { autoIndex: false });
module.exports = mongoose.models?.translations || mongoose.model('translations', TransSchema);