// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../project/styles/widget-styles/form-styles';
import Styles from '../../../common/decorators/Styles';
import {
    Div, FieldError, Input
} from '../../../hybrid/wrappers';
import { initFormHandlers } from '../formUtils';
import FromInputTitle from './FormFieldTitle';

const FormInputDisabled = ({
    attribute,
    html,
    hint,
    placeholder,
    required,
    title,
    handlers = {},
    compStyle,
    value,
    type,
    styles,
    errors,
    errorEditor,
    fieldsErr
}) => {
    const [val, setVal] = useState(value || '');

    useEffect(() => {
        setVal(value || '');
    }, [setVal, value]);

    const initHandlers = useMemo(() => initFormHandlers(
        handlers, attribute, val, type, errors
    ),
    [handlers, attribute, val, type, errors]);

    const componentConfig = {
        ...initHandlers,
        type: 'hidden',
        value: val,
        required,
        placeholder: placeholder || '',
        autoComplete: 'new-password',
        maxLength: 150,
        disabled: true
    };

    return (
        <Div styles={{ ...styles.formGroup, ...compStyle.wrapper }}>
            {
                !!title
                    ?
                    <FromInputTitle
                        styles={styles}
                        type={type}
                        errors={errors}
                        title={title}

                        compStyle={compStyle}
                        hint={hint}
                        required={required}
                    />
                    : null
            }

            <Input styles={{ display: 'none' }} {...componentConfig} />
            {
                <Div styles={
                    errorEditor && fieldsErr && fieldsErr[errorEditor.fieldName]
                        ? { color: errorEditor.colorError }
                        : errors?.length ? { ...styles.formTitleError } : {}}>
                    {html ? html : null}
                </Div>
            }
            {
                errors && errors.length ? errors.map((err, idx) => <FieldError
                    styles={{ ...styles, ...compStyle?.error || {} }} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
        </Div>
    );
};

FormInputDisabled.propTypes = {
    attribute: PropTypes.string,
    html: PropTypes.node,
    hint: PropTypes.node,
    placeholder: PropTypes.node,
    required: PropTypes.bool,
    title: PropTypes.object,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
    styles: PropTypes.object,
    errors: PropTypes.array,
    errorEditor: PropTypes.object,
    fieldsErr: PropTypes.object
};

export default Styles(FormInputDisabled, getStyles);