import { components } from 'react-select';

const getMultiValueRemove = (
    attribute, multiple, val, selectedLimit, setVal, setFormData
) => {
    const MultiValueRemove = (props) => {
        if (multiple && val?.length > selectedLimit){
            props.innerProps.onClick = () => {
                setVal([]);
                setFormData(attribute, []);
            }
        }
        return (
            <components.MultiValueRemove {...props} />
        )
    };
    return MultiValueRemove
}
export default getMultiValueRemove